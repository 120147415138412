import NoticeConstants from '../constants/NoticeConstants';

function dismissNotice(noticeId) {
  return {
    type: NoticeConstants.DISMISS_NOTICE,
    noticeId: noticeId.toString()
  };
}

export default {
  dismissNotice
};
