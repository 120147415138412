import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Chevron } from '@bibliocommons/deprecated-base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { StickyToggleButton } from 'app/components/shared/DrawerLayout';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';
import Context from '@bibliocommons/context';

import './BorrowingSidebarToggleButton.scss';

export default function BorrowingSidebarToggleButton({ drawerActions }) {
  const { mobileApp, features } = useContext(Context);
  const hideToggle = features.get(TOGGLZ_FEATURES.HIDE_BORROWING_PAGE_NAVIGATION_ON_APP) && mobileApp;

  return (
    !hideToggle && (
      <StickyToggleButton
        className="cp-borrowing-sidebar-toggle-button"
        drawerActions={drawerActions}
        renderCompact={() => (
          <span>
            <Chevron.DoubleRight />
            <ScreenReaderMessage>
              <FormattedMessage id="section_title_my_borrowing" />
            </ScreenReaderMessage>
          </span>
        )}
        renderFull={() => (
          <span className="full-content">
            <Chevron.DoubleRight />
            <FormattedMessage id="section_title_my_borrowing" />
          </span>
        )}
      />
    )
  );
}

BorrowingSidebarToggleButton.propTypes = {
  drawerActions: PropTypes.objectOf(PropTypes.func)
};
