import formatFilterForQuery from './formatFilterForQuery';

export default function formatFiltersForQuery(activeFilters, separator, prefix) {
  return activeFilters.reduce(
    (filterQueryParams, filtersForField, fieldId) => ({
      ...filterQueryParams,
      ...formatFilterForQuery(fieldId, filtersForField, separator, prefix)
    }),
    {}
  );
}
