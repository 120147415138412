import RelationshipsConstants from 'app/constants/RelationshipsConstants';
import RelationshipsApi from 'app/api/RelationshipsApi';
import composeGlobalFailureMessaging from 'app/helpers/messaging/composeGlobalFailureMessaging';
import composeGlobalSuccessMessaging from 'app/helpers/messaging/composeGlobalSuccessMessaging';
import { selectCurrentUser } from 'app/selectors/AuthSelector';
import { selectUserEntities } from 'app/selectors/EntitiesSelector';

const createFollow = ({ targetUserId }) => {
  return {
    types: [
      RelationshipsConstants.CREATE_FOLLOW_REQUEST,
      RelationshipsConstants.CREATE_FOLLOW_SUCCESS,
      RelationshipsConstants.CREATE_FOLLOW_FAILURE
    ],
    apiFailure: (response, store) => {
      const { error } = response;
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        messaging: composeGlobalFailureMessaging(error, {
          defaultMessage: 'You are already following {username}.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        })
      };
    },
    apiRequest: (state, client) => {
      const ownerUserId = selectCurrentUser(state)?.get('id') ?? null;

      if (!ownerUserId) {
        throw new Error('ownerUserId is required to create a Follow');
      }

      if (!targetUserId) {
        throw new Error('targetUserId is required to create a Follow');
      }

      return RelationshipsApi.createFollow({ ownerUserId, targetUserId }, client);
    },
    apiSuccess: (_response, store) => {
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        messaging: composeGlobalSuccessMessaging('message_successful_follow_user', {
          defaultMessage: 'You are now following {username}.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        })
      };
    },
    middlewareData: {
      authRequired: true
    },
    payload: {
      targetUserId
    }
  };
};

const createIgnore = ({ targetUserId }) => {
  return {
    types: [
      RelationshipsConstants.CREATE_IGNORE_REQUEST,
      RelationshipsConstants.CREATE_IGNORE_SUCCESS,
      RelationshipsConstants.CREATE_IGNORE_FAILURE
    ],
    apiFailure: (response, store) => {
      const { error } = response;
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        messaging: composeGlobalFailureMessaging(error, {
          defaultMessage: 'You are already ignoring {username}.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        })
      };
    },
    apiRequest: (state, client) => {
      const ownerUserId = selectCurrentUser(state)?.get('id') ?? null;

      if (!ownerUserId) {
        throw new Error('ownerUserId is create to delete an Ignore');
      }

      if (!targetUserId) {
        throw new Error('targetUserId is required to create an Ignore');
      }

      return RelationshipsApi.createIgnore({ ownerUserId, targetUserId }, client);
    },
    apiSuccess: (_response, store) => {
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        messaging: composeGlobalSuccessMessaging('message_successful_ignore_user', {
          defaultMessage:
            'You are now ignoring {username}. You will not see contributions from this user in the future.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        })
      };
    },
    middlewareData: {
      authRequired: true
    },
    payload: {
      targetUserId
    }
  };
};

const getRelationships = ({ userId, userIds }) => {
  return {
    types: [
      RelationshipsConstants.FETCH_RELATIONSHIPS_REQUEST,
      RelationshipsConstants.FETCH_RELATIONSHIPS_SUCCESS,
      RelationshipsConstants.FETCH_RELATIONSHIPS_FAILURE
    ],
    apiRequest: (_state, client) => {
      if (!userIds?.toArray()?.length) {
        return {
          user: {
            userId
          },
          followedUsers: {},
          ignoredUsers: {}
        };
      }
      return RelationshipsApi.getRelationships({ userId, userIds }, client);
    }
  };
};

const removeFollow = ({ followId, targetUserId }) => {
  return {
    types: [
      RelationshipsConstants.REMOVE_FOLLOW_REQUEST,
      RelationshipsConstants.REMOVE_FOLLOW_SUCCESS,
      RelationshipsConstants.REMOVE_FOLLOW_FAILURE
    ],
    apiRequest: (state, client) => {
      const ownerUserId = selectCurrentUser(state)?.get('id') ?? null;

      if (!followId) {
        throw new Error('followId is required to delete a Follow');
      }

      if (!ownerUserId) {
        throw new Error('ownerUserId is required to delete a Follow');
      }

      return RelationshipsApi.removeFollow({ followId, ownerUserId }, client);
    },
    apiSuccess: (_response, store) => {
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        deletedEntities: [`followedUsers.${followId}`],
        messaging: composeGlobalSuccessMessaging('message_successful_unfollow_user', {
          defaultMessage: 'You are no longer following {username}.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        }),
        targetUserId
      };
    },
    middlewareData: {
      authRequired: true
    },
    payload: {
      targetUserId
    }
  };
};

const removeIgnore = ({ ignoreId, targetUserId }) => {
  return {
    types: [
      RelationshipsConstants.REMOVE_IGNORE_REQUEST,
      RelationshipsConstants.REMOVE_IGNORE_SUCCESS,
      RelationshipsConstants.REMOVE_IGNORE_FAILURE
    ],
    apiRequest: (state, client) => {
      const ownerUserId = selectCurrentUser(state)?.get('id') ?? null;

      if (!ignoreId) {
        throw new Error('followId is required to delete an Ignore');
      }

      if (!ownerUserId) {
        throw new Error('ownerUserId is required to delete an Ignore');
      }

      return RelationshipsApi.removeIgnore({ ignoreId, ownerUserId }, client);
    },
    apiSuccess: (_response, store) => {
      const targetUser = selectUserEntities(store.getState()).find(userEntity => userEntity.get('id') === targetUserId);

      return {
        deletedEntities: [`ignoredUsers.${ignoreId}`],
        messaging: composeGlobalSuccessMessaging('message_successful_unignore_user', {
          defaultMessage: 'You are no longer ignoring {username}. You will begin to see contributions from this user.',
          values: {
            username: targetUser?.get('name') ?? ''
          }
        }),
        targetUserId
      };
    },
    middlewareData: {
      authRequired: true
    },
    payload: {
      targetUserId
    }
  };
};

export default {
  getRelationships
};

export { createFollow, createIgnore, getRelationships, removeFollow, removeIgnore };
