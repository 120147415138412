import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@bibliocommons/base-buttons';
import { Close, Info } from '@bibliocommons/base-icons';
import { ExpandableHtml } from '@bibliocommons/expandable';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { FormattedMessage } from 'react-intl';

import './SystemMessage.scss';

function renderIcon() {
  return (
    <span className="message-icon">
      <Info />
    </span>
  );
}

export default class SystemMessage extends React.PureComponent {
  dismissMessage = () => {
    this.props.noticeActions.dismissNotice(this.props.message.get('id'));
  };

  renderDismissButton() {
    const { dismissable } = this.props;
    if (!dismissable) {
      return null;
    }

    return (
      <Button className="dismiss-button" handleClick={this.dismissMessage} dataKey="dismiss-button">
        <ScreenReaderMessage>
          <FormattedMessage id="button_close" />
        </ScreenReaderMessage>
        <Close />
      </Button>
    );
  }

  renderContent() {
    const { message } = this.props;

    return (
      <ExpandableHtml
        className="message-body"
        htmlText={message.get('message')}
        expandLinkHref="#system-message-read-more"
        textKey="system_message"
        truncateCount={130}
      />
    );
  }

  render() {
    return (
      <div className="cp-system-message">
        {renderIcon()}
        {this.renderContent()}
        {this.renderDismissButton()}
      </div>
    );
  }
}

SystemMessage.propTypes = {
  message: PropTypes.object.isRequired,
  noticeActions: PropTypes.object.isRequired,
  dismissable: PropTypes.bool.isRequired
};
