import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Attention, CalendarEmpty, CalendarWarning } from '@bibliocommons/deprecated-base-icons';

import { CHECKOUT_STATUS_TYPES } from 'app/constants/CheckoutConstants';

import BorrowingStatusIcon from '../../shared/BorrowingStatusIcon';

import './CheckedOutStatusIcon.scss';

const getIcon = status => {
  switch (status) {
    case CHECKOUT_STATUS_TYPES.OVERDUE:
      return <Attention />;
    case CHECKOUT_STATUS_TYPES.COMING_DUE:
      return <CalendarWarning />;
    default:
      return <CalendarEmpty />;
  }
};

export default function CheckedOutStatusIcon({ status, withBackground, withLabel }) {
  const label = status && <FormattedMessage id={`checkedout_status_${status.toLowerCase()}`} />;

  return (
    <BorrowingStatusIcon
      className="cp-checked-out-status-icon"
      icon={getIcon(status)}
      status={status}
      withBackground={withBackground}
      renderLabel={withLabel && (() => label)}
    />
  );
}

CheckedOutStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  withBackground: PropTypes.bool,
  withLabel: PropTypes.bool
};
