import AvailabilityApi from '../api/AvailabilityApi';
import AvailabilityDetailConstants from '../constants/AvailabilityDetailConstants';

function openAvailability(metadataId) {
  return {
    metadataId,
    type: AvailabilityDetailConstants.OPEN_AVAILABILITY_DETAILS
  };
}

function closeAvailability() {
  return {
    type: AvailabilityDetailConstants.CLOSE_AVAILABILITY_DETAILS
  };
}

function getDigitalAvailability(id) {
  return {
    types: [
      AvailabilityDetailConstants.DIGITAL_AVAILABILITY_REQUEST,
      AvailabilityDetailConstants.DIGITAL_AVAILABILITY_SUCCESS,
      AvailabilityDetailConstants.DIGITAL_AVAILABILITY_ERROR
    ],
    apiRequest: (state, client) => AvailabilityApi.fetchResults(id, client)
  };
}

export default {
  openAvailability,
  closeAvailability,
  getDigitalAvailability
};

export { closeAvailability, getDigitalAvailability, openAvailability };
