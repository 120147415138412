import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import { withRouter } from '@bibliocommons/utils-routing';
import { Layout, MainContent, Sidebar } from 'app/components/shared/DrawerLayout';
import { libraryShape, accountShape, routerShape } from '@bibliocommons/bc-prop-types';
import { setFocusWithinElement } from '@bibliocommons/utils-accessibility';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';
import Context from '@bibliocommons/context';

import DrawerActions from 'app/actions/DrawerActions';
import MessagesActions from 'app/actions/MessagesActions';

import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { selectBorrowingSummary, selectBorrowingHistorySummary } from 'app/selectors/BorrowingSelector';
import { selectCurrentAccount, selectAccountsByLibraryId } from 'app/selectors/AuthSelector';
import { selectILSQuotas } from 'app/selectors/HoldsSelector';
import MyBorrowingSidebar from 'app/components/borrowing/sidebar/MyBorrowingSidebar/MyBorrowingSidebar';
import BorrowingSidebarToggleButton from 'app/components/borrowing/sidebar/BorrowingSidebarToggleButton/BorrowingSidebarToggleButton';
import AccountSwitcherContainer from 'app/components/borrowing/sidebar/AccountSwitcher/AccountSwitcherContainer';

import './BorrowingLayout.scss';

const getActiveSection = path => {
  const ctrl = path.split('/')[2];
  if (['checkedout', 'holds', 'fines', 'recentlyreturned', 'interlibraryloans', 'accountmessages'].includes(ctrl)) {
    return ctrl;
  }

  return 'other';
};

export class BorrowingLayout extends React.PureComponent {
  componentDidMount() {
    const accountId = this.props.account.get('id');
    if (this.props.library.get('accountMessages')) {
      this.props.messagesActions.fetchMessages({ accountId });
    }
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    const pathnameChanged = router.location.pathname !== prevProps.router.location.pathname;

    if (pathnameChanged && this._contentRef) {
      setFocusWithinElement(this._contentRef);
    }
  }

  renderContent() {
    const { drawerActions, children } = this.props;
    return (
      <MainContent mainClass="borrowing-content">
        <BorrowingSidebarToggleButton drawerActions={drawerActions} />
        <span className="top-accout-swticher">
          <AccountSwitcherContainer />
        </span>
        <div ref={el => (this._contentRef = el)}>{children}</div>
      </MainContent>
    );
  }

  render() {
    const {
      account,
      drawerActions,
      checkoutSummary,
      holdsSummary,
      totalFines,
      totalBorrowingHistory,
      messages,
      drawer,
      library,
      ILSQuotas,
      accountsByLibraryId,
      illSummary,
      router,
      accruingFines
    } = this.props;

    const active = getActiveSection(router.location.pathname);
    const { mobileApp, features } = this.context;
    const hideSidebar = features.get(TOGGLZ_FEATURES.HIDE_BORROWING_PAGE_NAVIGATION_ON_APP) && mobileApp;
    const className = cn('cp-borrowing-layout container', {
      'borrowing-layout-app-view': hideSidebar
    });

    return (
      <div className={className}>
        {hideSidebar ? (
          <Layout>{this.renderContent()}</Layout>
        ) : (
          <Layout open={drawer.get('open')}>
            <Sidebar sidebarClass="borrowing-widget-sidebar" drawerActions={drawerActions}>
              <MyBorrowingSidebar
                account={account}
                drawer={drawer}
                activeSection={active}
                libraryName={library.get('fullName', '')}
                linkableLibraries={library.get('linkableLibraries', Immutable.List())}
                holdsSummary={holdsSummary}
                checkoutSummary={checkoutSummary}
                accountsByLibraryId={accountsByLibraryId}
                totalFines={totalFines}
                totalBorrowingHistory={totalBorrowingHistory}
                drawerActions={drawerActions}
                ILSQuotas={ILSQuotas}
                messages={messages}
                isRecentlyReturnedEnabled={library.get('recentlyReturned')}
                isIllRequestsEnabled={library.get('illRequests')}
                illSummary={illSummary}
                isAccountMessagesEnabled={library.get('accountMessages')}
                isRemainingHoldsEnabled={library.get('remainingHolds')}
                showCardExpiryDate={library.get('cardExpiryDate')}
                accruingFines={accruingFines}
              />
            </Sidebar>
            {this.renderContent()}
          </Layout>
        )}
      </div>
    );
  }
}

BorrowingLayout.contextType = Context;

const mapStateToProps = state => {
  const finesSummary = selectBorrowingSummary(state, 'fines');
  return {
    drawer: state.get('drawer'),
    checkoutSummary: selectBorrowingSummary(state, 'checkedout'),
    holdsSummary: selectBorrowingSummary(state, 'holds'),
    totalBorrowingHistory: selectBorrowingHistorySummary(state),
    illSummary: selectBorrowingSummary(state, 'interlibraryLoans'),
    messages: state.getIn(['entities', 'messages']),
    account: selectCurrentAccount(state),
    library: selectCurrentLibrary(state),
    accountsByLibraryId: selectAccountsByLibraryId(state),
    ILSQuotas: selectILSQuotas(state),
    totalFines: finesSummary.get('totalFines', 0),
    accruingFines: finesSummary.get('totalAccrued', 0)
  };
};

const mapDispatchToProps = dispatch => ({
  drawerActions: bindActionCreators(DrawerActions, dispatch),
  messagesActions: bindActionCreators(MessagesActions, dispatch)
});

BorrowingLayout.propTypes = {
  account: accountShape,
  drawer: ImmutablePropTypes.mapContains({
    open: PropTypes.bool
  }).isRequired,
  checkoutSummary: ImmutablePropTypes.mapContains({
    nextDue: ImmutablePropTypes.mapContains({
      date: PropTypes.string,
      status: PropTypes.string
    }),
    status: ImmutablePropTypes.map
  }),
  holdsSummary: ImmutablePropTypes.mapContains({
    status: ImmutablePropTypes.map
  }),
  totalFines: PropTypes.number.isRequired,
  totalBorrowingHistory: PropTypes.number,
  messages: ImmutablePropTypes.map.isRequired,
  ILSQuotas: ImmutablePropTypes.map,
  drawerActions: PropTypes.objectOf(PropTypes.func).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  library: libraryShape,
  router: routerShape,
  accountsByLibraryId: ImmutablePropTypes.mapOf(accountShape),
  messagesActions: PropTypes.objectOf(PropTypes.func),
  accruingFines: PropTypes.number,
  illSummary: ImmutablePropTypes.map
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BorrowingLayout));
