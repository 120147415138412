function addContentAdvisories({ userId, contentAdvisories }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/contentAdvisories`;
  return apiClient.post(route, { userId, contentAdvisories });
}

function updateContentAdvisories({ userId, contentAdvisories }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/contentAdvisories`;
  return apiClient.patch(route, { userId, contentAdvisories });
}

function removeContentAdvisories({ userId, contentAdvisoryIds }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/contentAdvisories`;
  return apiClient.delete(route, { userId, contentAdvisoryIds });
}

function getContentAdvisory({ contentNoticeId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/contentadvisories/${contentNoticeId}`;
  return apiClient.get(route);
}

function getContentAdvisories({ metadataId, page, size }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/contentadvisories`;
  return apiClient.get(route, { query: { metadataId, page, size } });
}

export default {
  addContentAdvisories,
  updateContentAdvisories,
  removeContentAdvisories,
  getContentAdvisory,
  getContentAdvisories
};
