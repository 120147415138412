import Immutable from 'immutable';
import UserConstants from '../constants/UserConstants';

const initialState = Immutable.Map({
  id: undefined,
  isDeletingUser: false,
  isFetching: false
});

const {
  DELETE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR
} = UserConstants;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_ERROR:
      return state.set('isDeletingUser', false);

    case DELETE_USER_REQUEST:
      return state.set('isDeletingUser', true);

    case DELETE_USER_SUCCESS:
      return state.set('isDeletingUser', false);

    case FETCH_USER_ERROR:
      return initialState;

    case FETCH_USER_REQUEST: {
      return state.merge({ isFetching: true });
    }

    case FETCH_USER_SUCCESS: {
      return state.mergeDeep({
        ...action.payload.user,
        isFetching: false
      });
    }

    default:
      return state;
  }
}
