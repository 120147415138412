import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const SHELF_IMPORT_NOTICE_ID = 'shelf_import_notice';
export const SHELF_AVAILABILITY_SEARCH_LIMIT = 175;

export const IMPORT_STATUSES = {
  DONE: 'done',
  SUBMITTED: 'submitted',
  FAILED: 'failed'
};

export const SHELF_NAMES = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  FOR_LATER: 'for_later'
};

export const SUMMARY_ORDER = [SHELF_NAMES.COMPLETED, SHELF_NAMES.IN_PROGRESS, SHELF_NAMES.FOR_LATER];

export const DROPDOWN_ORDER = [SHELF_NAMES.FOR_LATER, SHELF_NAMES.IN_PROGRESS, SHELF_NAMES.COMPLETED];

export const WORKFLOW_VIEWS = createReduxConstants('WORKFLOW_VIEWS', {
  ADDED_TO_COMPLETED_SHELF: null,
  ADDED_TO_IN_PROGRESS_SHELF: null,
  ADDED_TO_FOR_LATER_SHELF: null,
  EXISTS_ON_SHELF: null
});

export default createReduxConstants('SHELF', {
  SHELF_SEARCH_REQUEST: null,
  SHELF_SEARCH_SUCCESS: null,
  SHELF_SEARCH_FAILURE: null,
  FETCH_FILTERS_REQUEST: null,
  FETCH_FILTERS_SUCCESS: null,
  FETCH_FILTERS_FAILURE: null,
  ADD_TO_SHELF_REQUEST: null,
  ADD_TO_SHELF_SUCCESS: null,
  ADD_TO_SHELF_FAILURE: null,
  REMOVE_FROM_SHELF_REQUEST: null,
  REMOVE_FROM_SHELF_SUCCESS: null,
  REMOVE_FROM_SHELF_FAILURE: null,
  UPDATE_SHELF_ITEM_REQUEST: null,
  UPDATE_SHELF_ITEM_SUCCESS: null,
  UPDATE_SHELF_ITEM_FAILURE: null,
  SHOW_POST_ADD_TO_SHELF_VIEW: null,
  IMPORT_ILS_BIBS_REQUEST: null,
  IMPORT_ILS_BIBS_SUCCESS: null,
  IMPORT_ILS_BIBS_FAILURE: null
});
