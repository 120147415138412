import { of } from 'rxjs';
import UserApi from '../api/UserApi';
import UserConstants from '../constants/UserConstants';

function fetchUser(userId) {
  return {
    types: [UserConstants.FETCH_USER_REQUEST, UserConstants.FETCH_USER_SUCCESS, UserConstants.FETCH_USER_ERROR],
    apiRequest: (state, client) => {
      if (!userId) {
        return of({ user: null });
      }

      return UserApi.fetchUser({ userId }, client);
    }
  };
}

const deleteUser = ({ userId, onSuccess }) => {
  return {
    types: [UserConstants.DELETE_USER_REQUEST, UserConstants.DELETE_USER_SUCCESS, UserConstants.DELETE_USER_ERROR],
    apiRequest: (state, client) => {
      if (!userId) {
        return of({ user: null });
      }

      return UserApi.deleteUser({ userId }, client);
    },
    apiSuccess: onSuccess
  };
};

export default { fetchUser };

export { deleteUser, fetchUser };
