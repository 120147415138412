import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import '../styles/LibraryLogo.scss';

class LibraryLogo extends React.PureComponent {
  getLogoUrl() {
    const imageName = this.props.imageName || (this.props.small ? 'mobile_logo' : 'logo');
    return `${this.props.baseUrl}/images/${this.props.siteId}/${imageName}.png`;
  }

  render() {
    const classes = cn(
      'cp-library-logo',
      {
        'logo-small': this.props.small,
        'logo-circled': this.props.circled
      },
      this.props.className
    );

    return <img src={this.getLogoUrl()} className={classes} alt={this.props.alt} loading="lazy" />;
  }
}

LibraryLogo.propTypes = {
  siteId: PropTypes.string.isRequired,
  small: PropTypes.bool,
  circled: PropTypes.bool,
  imageName: PropTypes.string,
  baseUrl: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
};

LibraryLogo.defaultProps = {
  baseUrl: '',
  small: false,
  circled: false,
  alt: '' // Default to blank, assume it's decorative
};

export default LibraryLogo;
