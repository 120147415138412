function getComment({ commentId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/comments/${commentId}`;
  return apiClient.get(route);
}

function getComments({ metadataId, page, size }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/comments`;
  return apiClient.get(route, { query: { metadataId, page, size } });
}

function addComment({ userId, metadataId, comment }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/comments`;
  return apiClient.post(route, { userId, metadataId, comment });
}

function updateComment({ userId, commentId, comment }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/comments/${commentId}`;
  return apiClient.patch(route, { userId, comment });
}

function removeComment({ userId, commentId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/comments/${commentId}`;
  return apiClient.delete(route, { userId });
}

export default {
  getComment,
  getComments,
  addComment,
  updateComment,
  removeComment
};
