import PropTypes from 'prop-types';
import React from 'react';
import { childrenShape } from '@bibliocommons/bc-prop-types';

class MainContent extends React.PureComponent {
  getMainClasses() {
    return `${this.props.mainClass} col-12 col-xs-12 col-sm-12 col-md-12 col-lg-9 cp-layout-main-content`;
  }

  render() {
    return <div className={this.getMainClasses()}>{this.props.children}</div>;
  }
}

MainContent.propTypes = {
  mainClass: PropTypes.string,
  children: childrenShape
};

export default MainContent;
