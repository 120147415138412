import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useRouter } from '@bibliocommons/utils-routing';
import { userIdentityShape, childrenShape } from '@bibliocommons/bc-prop-types';
import connectRoute from 'app/routes/connectRoute';
import RedirectActions from 'app/actions/RedirectActions';
import { selectCurrentUser } from 'app/selectors/AuthSelector';

export function AuthenticatedRoute(props) {
  const { location } = useRouter();

  if (!props.currentUser) {
    props.redirectActions.redirect(
      `/user/login?destination=${encodeURIComponent(`${location.pathname}${location.search}`)}`
    );
    return null;
  }

  return props.children;
}

AuthenticatedRoute.propTypes = {
  currentUser: userIdentityShape,
  children: childrenShape.isRequired,
  redirectActions: PropTypes.objectOf(PropTypes.func).isRequired
};

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  redirectActions: bindActionCreators(RedirectActions, dispatch)
});

export default connectRoute(AuthenticatedRoute, { mapStateToProps, mapDispatchToProps });
