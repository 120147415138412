import Immutable from 'immutable';

import OverlayConstants from 'app/constants/OverlayConstants';

const initialState = Immutable.Map();

const overlayReducer = (state = initialState, action) => {
  switch (action?.type) {
    case OverlayConstants.CLOSE_OVERLAY: {
      const overlayKey = action.payload.overlayKey;
      return state.setIn([overlayKey, 'isOpen'], false);
    }
    case OverlayConstants.OPEN_OVERLAY: {
      const overlayKey = action.payload.overlayKey;
      return state.setIn([overlayKey, 'isOpen'], true);
    }
    default:
      return state;
  }
};

export default overlayReducer;
