import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { branchShape, singleClickHoldsSettingsShape } from '@bibliocommons/bc-prop-types';
import * as AccountActions from 'app/actions/AccountActions';
import { selectPickupBranches } from 'app/selectors/LibrarySelector';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';
import SingleClickHoldsPanel from './SingleClickHoldsPanel';

export class SingleClickHoldsPanelContainer extends React.PureComponent {
  render() {
    return <SingleClickHoldsPanel {...this.props} />;
  }
}

SingleClickHoldsPanelContainer.propTypes = {};

const mapStateToProps = state => {
  const branches = selectPickupBranches(state);
  const account = selectCurrentAccount(state);
  const singleClickHoldsSettings = account && account.get('singleClickHoldsSettings', Immutable.Map());
  return {
    accountId: account.get('id'),
    branches,
    singleClickHoldsSettings
  };
};

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(AccountActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleClickHoldsPanelContainer);

SingleClickHoldsPanelContainer.propTypes = {
  accountId: PropTypes.number,
  branches: ImmutablePropTypes.listOf(branchShape),
  singleClickHoldsSettings: singleClickHoldsSettingsShape,
  accountActions: PropTypes.objectOf(PropTypes.func)
};
