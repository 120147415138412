import CommentsApi from '../api/CommentsApi';
import CommentsConstants from '../constants/CommentsConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';
import composeUgcSuccessMessaging from '../helpers/messaging/composeUgcSuccessMessaging';

function getComment(commentId) {
  return {
    types: [
      CommentsConstants.GET_COMMENT_REQUEST,
      CommentsConstants.GET_COMMENT_SUCCESS,
      CommentsConstants.GET_COMMENT_FAILURE
    ],
    apiRequest: (state, client) => CommentsApi.getComment({ commentId }, client)
  };
}

function getComments({ metadataId, page }) {
  return {
    types: [
      CommentsConstants.GET_COMMENTS_REQUEST,
      CommentsConstants.GET_COMMENTS_SUCCESS,
      CommentsConstants.GET_COMMENTS_FAILURE
    ],
    apiRequest: (state, client) => CommentsApi.getComments({ metadataId, page, size: 25 }, client)
  };
}

function addComment({ metadataId, comment, postSuccess }) {
  return {
    metadataId,
    postSuccess,
    types: [
      CommentsConstants.ADD_COMMENT_REQUEST,
      CommentsConstants.ADD_COMMENT_SUCCESS,
      CommentsConstants.ADD_COMMENT_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      CommentsApi.addComment(
        {
          metadataId,
          comment,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: (response, store) => ({
      messaging: composeUgcSuccessMessaging({
        response,
        store,
        metadataId,
        type: 'comment'
      })
    })
  };
}

function updateComment({ metadataId, commentId, comment, postSuccess }) {
  return {
    metadataId,
    commentId,
    postSuccess,
    types: [
      CommentsConstants.UPDATE_COMMENT_REQUEST,
      CommentsConstants.UPDATE_COMMENT_SUCCESS,
      CommentsConstants.UPDATE_COMMENT_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      CommentsApi.updateComment(
        {
          commentId,
          comment,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeUgcSuccessMessaging({
        type: 'comment',
        metadataId,
        updated: true
      })
    })
  };
}

function removeComment({ metadataId, commentId, postSuccess }) {
  return {
    metadataId,
    commentId,
    postSuccess,
    types: [
      CommentsConstants.REMOVE_COMMENT_REQUEST,
      CommentsConstants.REMOVE_COMMENT_SUCCESS,
      CommentsConstants.REMOVE_COMMENT_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      CommentsApi.removeComment(
        {
          commentId,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('success_ugc_remove_message', { values: { ugcType: 'comment' } }),
      deletedEntities: [`ugc.comments.${commentId}`]
    })
  };
}

export default {
  getComment,
  getComments,
  addComment,
  updateComment,
  removeComment
};
