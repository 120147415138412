import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Portal from '@bibliocommons/base-portal';
import ProgressBar from '@bibliocommons/shared-progress-bar';

const mapStateToProps = state => ({
  active: state.get('ui').get('progressBar')
});

const propTypes = {
  active: PropTypes.bool.isRequired
};

const GlobalProgressBarContainer = ({ active }) => (
  <Portal>
    <ProgressBar active={active} />
  </Portal>
);

GlobalProgressBarContainer.propTypes = propTypes;

export default connect(mapStateToProps)(GlobalProgressBarContainer);
