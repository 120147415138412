import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import ShortFormattedDate from '../../../shared/ShortFormattedDate';

import './AccountExpiryWarning.scss';

const accountExpiresSoon = expiryDate => {
  const sixtyDaysFromNow = moment().add(60, 'days');
  return moment(expiryDate).isBefore(sixtyDaysFromNow);
};

export default function AccountExpiryWarning({ expiryDate }) {
  return (
    (expiryDate && accountExpiresSoon(expiryDate) && (
      <a className="cp-account-expiry-warning list-group-item" href="/account">
        <FormattedMessage id="label_renew_card_by" /> <ShortFormattedDate value={expiryDate} />
      </a>
    )) ||
    null
  );
}

AccountExpiryWarning.propTypes = {
  expiryDate: PropTypes.string
};
