import Immutable from 'immutable';

import { REDUX_CONSTANTS } from 'app/constants/list';

const initialState = Immutable.Map({
  availabilityByMetadataId: Immutable.Map(),
  bibsByMetadataId: Immutable.Map(),
  catalogBibsByMetadataId: Immutable.Map(),
  currentUser: null,
  items: Immutable.List(),
  isFetchingItems: false,
  list: Immutable.Map(),
  manifestationsByMetadataId: Immutable.Map(),
  numLikes: 0,
  pagination: Immutable.Map(),
  user: Immutable.Map()
});

export default function library(state = initialState, action) {
  switch (action.type) {
    case REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_REQUEST: {
      return state.merge({ isFetchingAvailabilities: true });
    }

    case REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_FAILURE: {
      return state.merge({ isFetchingAvailabilities: false });
    }

    case REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_SUCCESS: {
      return state.merge({
        availabilityByMetadataId: state.get('availabilityByMetadataId').merge(action.payload),
        isFetchingAvailabilities: false
      });
    }

    case REDUX_CONSTANTS.GET_LIST_ITEMS_REQUEST: {
      return state.merge({ isFetchingItems: true });
    }

    case REDUX_CONSTANTS.GET_LIST_ITEMS_FAILURE: {
      return state.merge({ isFetchingItems: false });
    }

    case REDUX_CONSTANTS.GET_LIST_ITEMS_SUCCESS: {
      const { bibsByMetadataId, catalogBibsByMetadataId, items, manifestationsByMetadataId, pagination } =
        action.payload ?? {};

      return state.merge({
        bibsByMetadataId: state.get('bibsByMetadataId').merge(bibsByMetadataId),
        catalogBibsByMetadataId: state.get('catalogBibsByMetadataId').merge(catalogBibsByMetadataId),
        items: state.get('items').concat(items),
        isFetchingItems: false,
        manifestationsByMetadataId: state.get('manifestationsByMetadataId').merge(manifestationsByMetadataId),
        pagination
      });
    }

    case REDUX_CONSTANTS.GET_LIST_BY_ID_REQUEST: {
      return state.merge({ isFetchingItems: true });
    }

    case REDUX_CONSTANTS.GET_LIST_BY_ID_FAILURE: {
      return state.merge({ isFetchingItems: false });
    }

    case REDUX_CONSTANTS.GET_LIST_BY_ID_SUCCESS: {
      return state.merge(action.payload, { isFetchingItems: false });
    }

    default:
      return state;
  }
}
