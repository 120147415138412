import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import BibsActions from 'app/actions/BibsActions';

const AllReviewsPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllReviewsPage/AllReviewsPageContainer')
);

export function AllReviewsPageRoute() {
  return <AllReviewsPageContainer />;
}

function getRouteData({ dispatch, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(BibsActions.getReviews({ metadataId: params.metadataId }))
  ];
}

export default connectRoute(AllReviewsPageRoute, { getRouteData });
