import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import VideosActions from 'app/actions/VideosActions';

const VideoPageContainer = loadable(() => import('app/components/bibs/subpages/VideoPage/VideoPageContainer'));

export function VideoPageRoute() {
  return <VideoPageContainer />;
}

function getRouteData({ dispatch, params }) {
  const videoId = parseInt(params.videoId, 10);
  return [dispatch(VideosActions.getVideo(videoId))];
}

export default connectRoute(VideoPageRoute, { getRouteData });
