function getFines(apiClient, params) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines`;
  return apiClient.get(route, { query: params });
}

function getFinesPaymentForm(apiClient, params) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines/getPaymentForm`;
  return apiClient.get(route, { query: params });
}

function createGlobalPaymentsOrder(apiClient, { accountId, billIds }) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines/globalPayments/orders`;
  return apiClient.post(route, { accountId, billIds });
}

function createGlobalPaymentsPayment(apiClient, { accountId, billIds, hppResponse }) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines/globalPayments/payments`;
  return apiClient.post(route, { accountId, billIds, hppResponse }, { numRetries: 2 });
}

function createOrder(apiClient, { accountId, billIds }) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines/orders`;
  return apiClient.post(route, { accountId, billIds });
}

function createPayment(apiClient, { orderId, accountId, billIds }) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/fines/payments`;
  return apiClient.post(route, { orderId, accountId, billIds });
}

export default {
  getFines,
  getFinesPaymentForm,
  createGlobalPaymentsOrder,
  createGlobalPaymentsPayment,
  createOrder,
  createPayment
};
