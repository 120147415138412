import SummariesApi from '../api/SummariesApi';
import SummariesConstants from '../constants/SummariesConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';
import composeUgcSuccessMessaging from '../helpers/messaging/composeUgcSuccessMessaging';

function getSummary(summaryId) {
  return {
    types: [
      SummariesConstants.GET_SUMMARY_REQUEST,
      SummariesConstants.GET_SUMMARY_SUCCESS,
      SummariesConstants.GET_SUMMARY_FAILURE
    ],
    apiRequest: (state, client) => SummariesApi.getSummary({ summaryId }, client)
  };
}

function getSummaries({ metadataId, page }) {
  return {
    types: [
      SummariesConstants.GET_SUMMARIES_REQUEST,
      SummariesConstants.GET_SUMMARIES_SUCCESS,
      SummariesConstants.GET_SUMMARIES_FAILURE
    ],
    apiRequest: (state, client) => SummariesApi.getSummaries({ metadataId, page, size: 25 }, client)
  };
}

function addSummary({ metadataId, summary, postSuccess }) {
  return {
    metadataId,
    postSuccess,
    types: [
      SummariesConstants.ADD_SUMMARIES_REQUEST,
      SummariesConstants.ADD_SUMMARIES_SUCCESS,
      SummariesConstants.ADD_SUMMARIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      SummariesApi.addSummary(
        {
          userId: state.getIn(['auth', 'currentUserId']),
          metadataId,
          summary
        },
        client
      ),
    apiSuccess: (response, store) => ({
      messaging: composeUgcSuccessMessaging({
        response,
        store,
        metadataId,
        type: 'summary'
      })
    })
  };
}

function updateSummary({ metadataId, summaryId, summary, postSuccess }) {
  return {
    metadataId,
    summaryId,
    postSuccess,
    types: [
      SummariesConstants.UPDATE_SUMMARIES_REQUEST,
      SummariesConstants.UPDATE_SUMMARIES_SUCCESS,
      SummariesConstants.UPDATE_SUMMARIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      SummariesApi.updateSummary(
        {
          userId: state.getIn(['auth', 'currentUserId']),
          summaryId,
          summary
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeUgcSuccessMessaging({
        type: 'summary',
        metadataId,
        updated: true
      })
    })
  };
}

function removeSummary({ metadataId, summaryId, postSuccess }) {
  return {
    metadataId,
    summaryId,
    postSuccess,
    types: [
      SummariesConstants.REMOVE_SUMMARIES_REQUEST,
      SummariesConstants.REMOVE_SUMMARIES_SUCCESS,
      SummariesConstants.REMOVE_SUMMARIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      SummariesApi.removeSummary(
        {
          userId: state.getIn(['auth', 'currentUserId']),
          summaryId
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('success_ugc_remove_message', { values: { ugcType: 'summary' } }),
      deletedEntities: [`ugc.summaries.${summaryId}`]
    })
  };
}

export default {
  getSummary,
  getSummaries,
  addSummary,
  updateSummary,
  removeSummary
};
