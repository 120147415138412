import Immutable from 'immutable';

import { actionTypes } from 'app/constants/AnalyticsGa4Constants';

const initialState = Immutable.Map({
  productId: null,
  productVersion: null,
  searchOrigin: null
});

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SDK_CONFIG: {
      const { productId, productVersion } = action.payload;
      return state.merge({ productId, productVersion });
    }

    case actionTypes.SET_SEARCH_ORIGIN: {
      const { searchOrigin } = action.payload;
      return state.merge({ searchOrigin });
    }

    default:
      return state;
  }
}
