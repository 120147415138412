import PropTypes from 'prop-types';
import React from 'react';
import './ScreenReaderShortcuts.scss';

const ScreenReaderShortcuts = ({ screenReaderNavigation }) => (
  <div className="cp-screen-reader-shortcuts">
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: screenReaderNavigation }}
    />
    <a href="#content" className="screen_reader_nav">
      Skip to Content
    </a>
  </div>
);

ScreenReaderShortcuts.displayName = 'ScreenReaderShortcuts';
ScreenReaderShortcuts.propTypes = {
  screenReaderNavigation: PropTypes.string.isRequired
};

export default ScreenReaderShortcuts;
