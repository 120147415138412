import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import LocationsActions from 'app/actions/LocationsActions';

const LocationsPage = loadable(() => import('app/components/locations/LocationsPage/LocationsPage'));

export function LocationsPageRoute() {
  return <LocationsPage />;
}

function getRouteData({ dispatch, query }) {
  return [dispatch(LocationsActions.searchLocations(query))];
}

export default connectRoute(LocationsPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100 // milliseconds
  }
});
