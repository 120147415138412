import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import SearchActions from 'app/actions/SearchActions';

const WidgetsPageContainer = loadable(() => import('app/components/widgets/WidgetsPage/WidgetsPageContainer'));

export function WidgetsPageRoute() {
  return <WidgetsPageContainer />;
}

function getRouteData({ dispatch }) {
  return [
    // Get an example bib for the BibWidget
    dispatch(
      SearchActions.performCatalogSearch({
        f_FORMAT: 'BK',
        f_STATUS: '_anywhere_',
        query: '*',
        searchType: 'bl',
        limit: 1
      })
    )
  ];
}

export default connectRoute(WidgetsPageRoute, { getRouteData });
