import { createSelector } from 'reselect';
import moment from 'moment';
import { selectHiddenNotices } from './NoticeSelector';

const systemMessages = state => state.getIn(['entities', 'systemMessages']);

export const selectVisibleMessages = createSelector([systemMessages, selectHiddenNotices], (messages, hidden) =>
  messages
    .filterNot(message => hidden.has(message.get('id').toString()))
    .toList()
    .sortBy(
      message => message.get('updatedDateTime'),
      (a, b) => moment(a).diff(moment(b))
    )
    .reverse()
);
export default {
  selectVisibleMessages
};
