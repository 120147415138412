import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { bindAll } from '@bibliocommons/utils-react';
import { PrimaryButton } from '@bibliocommons/deprecated-base-buttons';
import VisibilitySensor from 'app/components/shared/DrawerLayout/components/VisibilitySensor';
import { Toggle as DrawerToggle } from '../../..';

import '../styles/StickyToggleButton.scss';

export default class StickyToggleButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { drawerToggleVisible: true };
    bindAll(this);
  }

  isVisible(visible) {
    if (visible !== this.state.drawerToggleVisible) {
      this.setState({ drawerToggleVisible: visible });
    }
  }

  renderSticky() {
    return (
      <CSSTransition
        in={!this.state.drawerToggleVisible}
        classNames="slide"
        timeout={{ enter: 500, exit: 100 }}
        mountOnEnter
        unmountOnExit
      >
        <div className="cp-sticky-toggle">
          <DrawerToggle drawerActions={this.props.drawerActions}>
            <PrimaryButton className="handle" handleClick={this.props.drawerActions.toggle}>
              {this.props.renderCompact()}
            </PrimaryButton>
          </DrawerToggle>
        </div>
      </CSSTransition>
    );
  }

  render() {
    return (
      <span className={cn('cp-sticky-toggle-button', this.props.className)}>
        <VisibilitySensor isVisible={this.isVisible}>
          <DrawerToggle drawerActions={this.props.drawerActions}>
            <PrimaryButton handleClick={this.props.drawerActions.toggle}>{this.props.renderFull()}</PrimaryButton>
          </DrawerToggle>
        </VisibilitySensor>
        {this.renderSticky()}
      </span>
    );
  }
}

StickyToggleButton.propTypes = {
  className: PropTypes.string,
  drawerActions: PropTypes.objectOf(PropTypes.func),
  renderFull: PropTypes.func.isRequired,
  renderCompact: PropTypes.func.isRequired
};
