import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import SearchActions from 'app/actions/SearchActions';

const SearchPrintListContainer = loadable(() =>
  import('app/components/search/SearchPrintList/SearchPrintListContainer')
);

export function SearchPrintListRoute() {
  return <SearchPrintListContainer />;
}

function getRouteData({ dispatch, query }) {
  // Shelf availability search
  if (query.shelf) {
    return [dispatch(SearchActions.performShelfAvailabilitySearch({ ...query, print: true }))];
  }

  // Regular catalog search
  return [dispatch(SearchActions.performCatalogSearch({ ...query, print: true }))];
}

export default connectRoute(SearchPrintListRoute, { getRouteData });
