import Immutable from 'immutable';
import { createSelector } from 'reselect';

import { selectCurrentUser } from 'app/selectors/AuthSelector';
import { selectComments } from 'app/selectors/ugc/CommentsSelector';
import { selectFollowedUsers, selectIgnoredUsers } from 'app/selectors/EntitiesSelector';
import {
  selectCommunityCommentsForCurrentBib,
  selectStaffCommentsForCurrentBib,
  selectSummariesForCurrentBib,
  selectQuotationsForCurrentBib,
  selectVideosForCurrentBib
} from 'app/selectors/bibs/UgcSelector';
import { selectSummaries } from 'app/selectors/ugc/SummariesSelector';
import { selectQuotations } from 'app/selectors/ugc/QuotationsSelector';
import { selectVideos } from 'app/selectors/ugc/VideosSelector';

const getRelationshpsSelector = state => state.get('relationships', Immutable.Map());

const selectIsFetchingRelationships = createSelector([getRelationshpsSelector], relationships =>
  relationships.get('isFetching', false)
);

const selectSyncingFollowsByTargetUserId = createSelector([getRelationshpsSelector], relationships =>
  relationships.get('syncingFollowsByTargetUserId', Immutable.Map())
);

const selectSyncingIgnoresByTargetUserId = createSelector([getRelationshpsSelector], relationships =>
  relationships.get('syncingIgnoresByTargetUserId', Immutable.Map())
);

const selectUgcUserIdsForCurrentBib = createSelector(
  [
    selectCurrentUser,
    selectCommunityCommentsForCurrentBib,
    selectStaffCommentsForCurrentBib,
    selectSummariesForCurrentBib,
    selectQuotationsForCurrentBib,
    selectVideosForCurrentBib
  ],
  (currentUser, communityComments, staffComments, summaries, quotations, videos) => {
    const currentUserId = currentUser?.get('id') ?? null;
    return Immutable.List()
      .concat(communityComments, staffComments, summaries, quotations, videos)
      .map(ugc => ugc.get('userId'))
      .filter(userId => userId !== currentUserId)
      .toSet()
      .toList();
  }
);

const selectUserIdsForComments = createSelector([selectComments], comments => {
  return comments
    .map(comment => comment.get('userId'))
    .toSet()
    .toList();
});

const selectUserIdsForQuotations = createSelector([selectQuotations], quotations => {
  return quotations
    .map(quotation => quotation.get('userId'))
    .toSet()
    .toList();
});

const selectUserIdsForSummaries = createSelector([selectSummaries], summaries => {
  return summaries
    .map(summary => summary.get('userId'))
    .toSet()
    .toList();
});

const selectUserIdsForVideos = createSelector([selectVideos], videos => {
  return videos
    .map(video => video.get('userId'))
    .toSet()
    .toList();
});

const selectUserIdToFollowedUserId = createSelector([selectFollowedUsers], followedUsers =>
  followedUsers.reduce((userIdToFollowedUserId, followedUser) => {
    return userIdToFollowedUserId.set(followedUser.get('followedUserId'), followedUser.get('id'));
  }, Immutable.Map())
);

const selectUserIdToIgnoredUserId = createSelector([selectIgnoredUsers], ignoredUsers =>
  ignoredUsers.reduce((userIdToIgnoredUserId, ignoredUser) => {
    return userIdToIgnoredUserId.set(ignoredUser.get('ignoredUserId'), ignoredUser.get('id'));
  }, Immutable.Map())
);

export {
  selectIsFetchingRelationships,
  selectSyncingFollowsByTargetUserId,
  selectSyncingIgnoresByTargetUserId,
  selectUgcUserIdsForCurrentBib,
  selectUserIdsForComments,
  selectUserIdsForQuotations,
  selectUserIdsForSummaries,
  selectUserIdsForVideos,
  selectUserIdToFollowedUserId,
  selectUserIdToIgnoredUserId
};
