import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import VersionMessage from 'app/components/layouts/VersionMessage';
import { FeedbackBoxContainer } from '../FeedbackBox';

import './Footer.scss';

export default class Footer extends React.PureComponent {
  render() {
    const className = cn('cp-footer', { 'cms-footer': this.props.cmsEnabled });
    return (
      <div className={className}>
        <FeedbackBoxContainer />
        {/* eslint-disable react/no-danger */}
        <div className="cp-footer-content" dangerouslySetInnerHTML={{ __html: this.props.footer }} />
        {/* eslint-enable */}

        <div className="container">
          <VersionMessage version={this.props.appVersion} />
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  appVersion: PropTypes.string.isRequired,
  cmsEnabled: PropTypes.bool,
  footer: PropTypes.string.isRequired
};
