import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { EVENTS_FACET_FIELDS, FACET_FIELDS_ORDER } from 'app/constants/EventsConstants';
import {
  selectEventEntities,
  selectLocationEntities,
  selectEventAudienceEntities,
  selectEventTypesEntities,
  selectEventProgramsEntities,
  selectEventLanguagesEntities
} from 'app/selectors/EntitiesSelector';
import { selectActivePreferredBranches } from 'app/selectors/LibrarySelector';

const FIELD_ID_TO_NAME_MAPPING = {
  [EVENTS_FACET_FIELDS.BRANCH_LOCATIONS]: 'branchLocations',
  [EVENTS_FACET_FIELDS.EVENT_TYPES]: 'eventTypes',
  [EVENTS_FACET_FIELDS.EVENT_PROGRAMS]: 'eventPrograms',
  [EVENTS_FACET_FIELDS.EVENT_AUDIENCES]: 'eventAudiences',
  [EVENTS_FACET_FIELDS.EVENT_LANGUAGES]: 'eventLanguages'
};

const SUB_CATEGORIES_ORDER = ['onlineLocations', 'preferredLocations', 'otherLocations'];

const SUB_CATEGORIES_WITH_LABELS = ['preferredLocations', 'otherLocations'];

const CATEGORIES_CLOSED = [EVENTS_FACET_FIELDS.EVENT_PROGRAMS, EVENTS_FACET_FIELDS.EVENT_LANGUAGES];

const mapAppliedFilters = field => {
  const groups = field.get('groups');
  if (groups?.size) {
    return [
      field.get('id'),
      groups.map(group => group.get('filters').filter(filter => filter.get('applied') === true)).flatten(true)
    ];
  }
  return [];
};

const filterAppliedFilters = ([fieldId, appliedFilters]) => fieldId && !appliedFilters.isEmpty();
const getEventsSearch = state => state.getIn(['events', 'eventsSearch'], Immutable.Map());

export const selectCalloutFeaturedEvent = createSelector(
  [getEventsSearch, selectEventEntities],
  (event, eventEntities) => eventEntities.get(event.get('featuredEvent'))
);

export const selectSearchResults = createSelector(
  [getEventsSearch, selectEventEntities],
  (eventsSearch, eventEntities) => eventsSearch.get('results', Immutable.List()).map(id => eventEntities.get(id))
);

export const selectSearchFacets = createSelector(
  [
    getEventsSearch,
    selectLocationEntities,
    selectEventAudienceEntities,
    selectEventTypesEntities,
    selectEventProgramsEntities,
    selectEventLanguagesEntities,
    selectActivePreferredBranches
  ],
  (eventsSearch, locations, audiences, types, programs, languages, preferredBranches) => {
    function getFilterLabel(fieldId, filter) {
      switch (fieldId) {
        case EVENTS_FACET_FIELDS.BRANCH_LOCATIONS:
          return locations.getIn([filter.get('value'), 'name']);

        case EVENTS_FACET_FIELDS.EVENT_TYPES:
          return types.getIn([filter.get('value'), 'name']);

        case EVENTS_FACET_FIELDS.EVENT_PROGRAMS:
          return programs.getIn([filter.get('value'), 'name']);

        case EVENTS_FACET_FIELDS.EVENT_LANGUAGES:
          return languages.getIn([filter.get('value'), 'name']);

        case EVENTS_FACET_FIELDS.EVENT_AUDIENCES:
          return audiences.getIn([filter.get('value'), 'name']);

        default:
          return filter.get('value');
      }
    }

    function getFilterGroupId(fieldId, filter) {
      switch (fieldId) {
        case EVENTS_FACET_FIELDS.BRANCH_LOCATIONS: {
          const value = filter.get('value');
          if (preferredBranches.some(branch => branch.get('code') === value)) {
            return 'preferredLocations';
          }
          if (value === 'BC_VIRTUAL') {
            return 'onlineLocations';
          }
          return 'otherLocations';
        }

        default:
          return fieldId;
      }
    }

    function getFilterGroups(field, fieldId) {
      const filterGroups = field
        .get('filters')
        .map(filter =>
          Immutable.Map({
            label: getFilterLabel(fieldId, filter),
            applied: filter.get('selected'),
            count: filter.get('count'),
            value: filter.get('value')
          })
        )
        .sortBy(filter => filter.get('label'));

      const groups = filterGroups
        .groupBy(filter => getFilterGroupId(fieldId, filter))
        .sortBy((group, groupId) => SUB_CATEGORIES_ORDER.indexOf(groupId));

      return groups
        .map((filters, groupId) =>
          Immutable.Map({
            groupId,
            labelId: SUB_CATEGORIES_WITH_LABELS.includes(groupId) ? `EVENTS.${groupId}.field` : null,
            filters
          })
        )
        .toList();
    }

    function filterApplied(field) {
      return field?.get('filters').some(filter => filter.get('selected') === true);
    }

    const facetFields = eventsSearch.get('facetFields', Immutable.Map());
    return Immutable.List(FACET_FIELDS_ORDER).map(fieldId => {
      const field = facetFields.get(FIELD_ID_TO_NAME_MAPPING[fieldId]);
      return Immutable.Map({
        id: fieldId,
        collapsed: filterApplied(field) ? false : CATEGORIES_CLOSED.includes(fieldId),
        labelId: `EVENTS.${fieldId}.field`,
        groups: field ? getFilterGroups(field, fieldId) : null
      });
    });
  }
);

export const selectSearchQuery = createSelector([getEventsSearch], eventsSearch =>
  eventsSearch.get('query', Immutable.Map())
);

export const selectActiveFilters = createSelector([selectSearchFacets], facets =>
  Immutable.Map(facets.map(mapAppliedFilters).filter(filterAppliedFilters))
);

export const selectPagination = createSelector([getEventsSearch], eventsSearch =>
  eventsSearch.get('pagination', Immutable.Map())
);

export const selectEventsRSSLink = createSelector([getEventsSearch], eventsSearch => eventsSearch.get('eventsRSSLink'));

export default {
  selectSearchResults,
  selectCalloutFeaturedEvent,
  selectSearchFacets,
  selectSearchQuery,
  selectActiveFilters,
  selectPagination,
  selectEventsRSSLink
};
