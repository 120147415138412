import React from 'react';

import { bibShape, libraryShape } from '@bibliocommons/bc-prop-types';
import FormatIcon from '@bibliocommons/format-icon';

import { getPropsForFormatInfo } from 'app/helpers/bib/bibHelpers';
import FormatInfo from 'app/components/shared/FormatInfo';

import './ItemFormat.scss';

export default function ItemFormat({ bib, library }) {
  const format = bib.getIn(['briefInfo', 'format']);

  if (!bib) {
    return null;
  }

  return (
    <div className="cp-item-format">
      <FormatIcon aria-hidden className="icon-format" format={format} />
      <FormatInfo
        {...getPropsForFormatInfo({
          bib: bib?.toJS(),
          library: library?.toJS(),
          isIncludeCallNumberOverride: false
        })}
      />
    </div>
  );
}

ItemFormat.propTypes = {
  bib: bibShape,
  library: libraryShape.isRequired
};
