import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';

import { catalogBibShape, userIdentityShape } from '@bibliocommons/bc-prop-types';
import { useRouter } from '@bibliocommons/utils-routing';

import BibsActions from 'app/actions/BibsActions';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import RelationshipsActions from 'app/actions/RelationshipsActions';
import connectRoute from 'app/routes/connectRoute';
import { selectCurrentUser } from 'app/selectors/AuthSelector';
import { selectCatalogBib } from 'app/selectors/catalogBibs/CatalogBibSelector';
import { selectUgcUserIdsForCurrentBib } from 'app/selectors/RelationshipsSelector';

const BibPageContainer = loadable(() => import('app/components/bibs/BibPage/BibPageContainer'));

export function BibPageRoute(props) {
  const { bibsActions, catalogBib, relationshipsActions } = props;
  const router = useRouter();

  useEffect(() => {
    if (catalogBib) {
      const metadataId = catalogBib.get('metadataId');

      // Ensure the url always reflects the actual ID of the localized bib
      const pathname = `/v2/record/${metadataId}`;
      if (router.location.pathname !== pathname) {
        router.replace({ ...router.location, pathname });
      }

      // Load additional data on the client side, on page load,
      // and when the currentUser state changes (eg. log in).
      bibsActions.fetchUserTransactions([metadataId]);
      bibsActions.fetchAvailability(metadataId);
      bibsActions.fetchDiscovery(metadataId);
      bibsActions.fetchEvaluation(metadataId);
      bibsActions.fetchUGC(metadataId, state => {
        const userId = props.currentUser?.get('id');
        const userIds = selectUgcUserIdsForCurrentBib(state);

        if (userId && userIds) {
          relationshipsActions.getRelationships({ userId, userIds });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  return <BibPageContainer />;
}

BibPageRoute.propTypes = {
  bibsActions: PropTypes.objectOf(PropTypes.func).isRequired,
  catalogBib: catalogBibShape,
  currentUser: userIdentityShape,
  relationshipsActions: PropTypes.objectOf(PropTypes.func).isRequired
};

const mapStateToProps = state => ({
  catalogBib: selectCatalogBib(state),
  currentUser: selectCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  bibsActions: bindActionCreators(BibsActions, dispatch),
  relationshipsActions: bindActionCreators(RelationshipsActions, dispatch)
});

function getRouteData({ state, dispatch, params }) {
  // Load the bib once
  if (!selectCatalogBib(state)) {
    return [dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId))];
  }
  return [];
}

export default connectRoute(BibPageRoute, { mapStateToProps, mapDispatchToProps, getRouteData });
