function getVideo({ videoId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/videos/${videoId}`;
  return apiClient.get(route);
}

function getVideos({ metadataId, page, size }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/videos`;
  return apiClient.get(route, { query: { metadataId, page, size } });
}

function addVideo({ userId, metadataId, url, title, description }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/videos`;
  return apiClient.post(route, { userId, metadataId, url, title, description });
}

function updateVideo({ userId, metadataId, url, title, description, videoId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/videos/${videoId}`;
  return apiClient.patch(route, {
    userId,
    metadataId,
    url,
    title,
    description
  });
}

function removeVideo({ userId, videoId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/videos/${videoId}`;
  return apiClient.delete(route, { userId });
}

export default {
  getVideo,
  getVideos,
  addVideo,
  updateVideo,
  removeVideo
};
