import QuotationsApi from '../api/QuotationsApi';
import QuotationsConstants from '../constants/QuotationsConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';
import composeUgcSuccessMessaging from '../helpers/messaging/composeUgcSuccessMessaging';

function getQuotation(quotationId) {
  return {
    types: [
      QuotationsConstants.GET_QUOTATION_REQUEST,
      QuotationsConstants.GET_QUOTATION_SUCCESS,
      QuotationsConstants.GET_QUOTATION_FAILURE
    ],
    apiRequest: (state, client) => QuotationsApi.getQuotation({ quotationId }, client)
  };
}

function getQuotations({ metadataId, page }) {
  return {
    types: [
      QuotationsConstants.GET_QUOTATIONS_REQUEST,
      QuotationsConstants.GET_QUOTATIONS_SUCCESS,
      QuotationsConstants.GET_QUOTATIONS_FAILURE
    ],
    apiRequest: (state, client) => QuotationsApi.getQuotations({ metadataId, page, size: 25 }, client)
  };
}

function addQuotation({ metadataId, quotation, postSuccess }) {
  return {
    metadataId,
    postSuccess,
    types: [
      QuotationsConstants.ADD_QUOTATION_REQUEST,
      QuotationsConstants.ADD_QUOTATION_SUCCESS,
      QuotationsConstants.ADD_QUOTATION_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      QuotationsApi.addQuotation(
        {
          metadataId,
          quotation,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: (response, store) => ({
      messaging: composeUgcSuccessMessaging({
        response,
        store,
        metadataId,
        type: 'quotation'
      })
    })
  };
}

function updateQuotation({ metadataId, quotationId, quotation, postSuccess }) {
  return {
    metadataId,
    quotationId,
    postSuccess,
    types: [
      QuotationsConstants.UPDATE_QUOTATION_REQUEST,
      QuotationsConstants.UPDATE_QUOTATION_SUCCESS,
      QuotationsConstants.UPDATE_QUOTATION_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      QuotationsApi.updateQuotation(
        {
          quotationId,
          quotation,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeUgcSuccessMessaging({
        type: 'quotation',
        metadataId,
        updated: true
      })
    })
  };
}

function removeQuotation({ metadataId, quotationId, postSuccess }) {
  return {
    metadataId,
    quotationId,
    postSuccess,
    types: [
      QuotationsConstants.REMOVE_QUOTATION_REQUEST,
      QuotationsConstants.REMOVE_QUOTATION_SUCCESS,
      QuotationsConstants.REMOVE_QUOTATION_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      QuotationsApi.removeQuotation(
        {
          quotationId,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('success_ugc_remove_message', { values: { ugcType: 'quotation' } }),
      deletedEntities: [`ugc.quotations.${quotationId}`]
    })
  };
}

export default {
  getQuotation,
  getQuotations,
  addQuotation,
  updateQuotation,
  removeQuotation
};
