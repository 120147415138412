import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';

import { catalogBibShape } from '@bibliocommons/bc-prop-types';

import AuthorLink from 'app/components/bibs/shared/AuthorLink';
import './BibAuthors.scss';

export class BibAuthors extends React.PureComponent {
  render() {
    const { catalogBib, trackClick } = this.props;

    const authors = catalogBib.getIn(['brief', 'creators'], Immutable.List());

    return (
      <div className="cp-bib-authors authors-wrapper">
        <AuthorLink
          authors={authors}
          renderAsLink={this.props.renderAsLink}
          truncateCount={this.props.truncateCount}
          handleClick={trackClick}
        />
      </div>
    );
  }
}

BibAuthors.defaultProps = {
  trackClick: () => null
};

BibAuthors.propTypes = {
  catalogBib: catalogBibShape.isRequired,
  renderAsLink: PropTypes.bool,
  trackClick: PropTypes.func,
  truncateCount: PropTypes.number
};

export default BibAuthors;
