import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { bindAll } from '@bibliocommons/utils-react';
import { withRouter } from '@bibliocommons/utils-routing';
import { routerShape } from '@bibliocommons/bc-prop-types';

import updateSearchQuery from 'app/helpers/search/updateSearchQuery';
import { trackNavigate } from 'app/actions/AnalyticsGa4Actions';

const SEARCH_TARGETS = {
  CATALOGUE: 'catalogue',
  EVENTS: 'events'
};

const ApiMethods = {
  getOrigin: 'getOrigin',
  getTerm: 'getTerm',
  setTerm: 'setTerm',
  toggleFilterLock: 'toggleFilterLock',
  setSearchType: 'setSearchCategory',
  getSearchType: 'getSearchCategory',
  getSearchTarget: 'getSearchTarget',
  setSearchTarget: 'setSearchTarget',
  onSearch: 'onSearch'
};

const propTypes = {
  headerTemplate: PropTypes.string,
  searchTerm: PropTypes.string,
  searchCategory: PropTypes.string,
  activeFilters: ImmutablePropTypes.map,
  router: routerShape.isRequired,
  trackNavigate: PropTypes.func.isRequired
};

export class CoreHeaderContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
    this.headerApi = () => null;
  }

  componentDidMount() {
    if (this.props.headerTemplate) {
      this.initHeader();
      this.initAnalytics();
      this.updateHeaderSearchForm();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.headerTemplate) {
      return;
    }

    if (this.props.router.location.search !== prevProps.router.location.search) {
      this.headerApi(ApiMethods.toggleFilterLock, this.props.router.query.locked === 'true');
    }

    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.headerApi(ApiMethods.setTerm, this.props.searchTerm);
    }

    if (prevProps.searchCategory !== this.props.searchCategory) {
      this.headerApi(ApiMethods.setSearchType, this.props.searchCategory);
    }
  }

  updateHeaderSearchForm() {
    const searchTarget = this.isEventsPage() ? SEARCH_TARGETS.EVENTS : SEARCH_TARGETS.CATALOGUE;
    this.headerApi(ApiMethods.setSearchTarget, searchTarget);

    if (this.props.searchTerm || this.props.searchCategory) {
      this.headerApi(ApiMethods.setTerm, this.props.searchTerm);
      this.headerApi(ApiMethods.setSearchType, this.props.searchCategory);
      this.headerApi(ApiMethods.toggleFilterLock, this.props.router.query.locked === 'true');

      // This is used to let the header know it's a search results page
      document.body.classList.add('search-results');
    }
  }

  initAnalytics() {
    const { trackNavigate: dispatchTrackNavigate } = this.props;

    if (window.BCAnalyticsPublic) {
      window.BCAnalyticsPublic.registerTrackingHandler('trackNavigate', function trackNavigateHandler(payload) {
        dispatchTrackNavigate(payload);
      });
    }
  }

  initHeader() {
    if (window.BCHeaderPublic) {
      this.headerApi = window.BCHeaderPublic;
    }
    this.headerApi(ApiMethods.onSearch, SEARCH_TARGETS.CATALOGUE, this.onSearch);
    this.headerApi(ApiMethods.onSearch, SEARCH_TARGETS.EVENTS, this.onSearch);
  }

  isSearchSupported() {
    const searchTarget = this.headerApi(ApiMethods.getSearchTarget);
    const searchCategory = this.headerApi(ApiMethods.getSearchType);

    const isCatalogSearch = searchTarget === SEARCH_TARGETS.CATALOGUE;
    const isSupportedSearchCategory = !_.includes(['user', 'userlist'], searchCategory);
    return isCatalogSearch && isSupportedSearchCategory;
  }

  isOnSearchPage() {
    return this.props.router.location.pathname === '/v2/search';
  }

  isEventsPage() {
    return this.props.router.location.pathname === '/v2/events';
  }

  onSearch() {
    // Automatically hides keyboard on iOS
    if (__CLIENT__) {
      document.activeElement.blur();
    }

    if (this.isOnSearchPage() && this.isSearchSupported()) {
      return this.handleCatalogueSearch();
    }

    const searchTarget = this.headerApi(ApiMethods.getSearchTarget);
    if (this.isEventsPage() && searchTarget === SEARCH_TARGETS.EVENTS) {
      return this.handleEventsSearch();
    }

    return this.headerApi('performDefaultSearch');
  }

  handleEventsSearch() {
    const { router } = this.props;
    router.push({ query: { q: this.headerApi(ApiMethods.getTerm) || undefined } });
  }

  handleCatalogueSearch() {
    const { router, activeFilters } = this.props;

    const query = updateSearchQuery({
      currentQuery: this.props.router.query,
      activeFilters: this.props.router.query.locked === 'true' && activeFilters,
      query: this.headerApi(ApiMethods.getTerm),
      searchType: this.headerApi(ApiMethods.getSearchType),
      page: undefined,
      title: undefined,
      custom_edit: undefined,
      sort: undefined,
      suppress: undefined,
      shelf: undefined,
      origin: this.headerApi(ApiMethods.getOrigin)
    });

    router.push({ query });
  }

  render() {
    if (this.props.headerTemplate) {
      return (
        <div className="cp-core-external-header">
          {/* eslint-disable */}
          <div
            ref="headerWrapper"
            data-testid="headerWrapper"
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{ __html: this.props.headerTemplate }}
          />
          {/* eslint-enable */}
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    headerTemplate: state.getIn(['templates', 'header']),
    searchTerm: state.getIn(['search', 'searchForm', 'searchTerm']),
    searchCategory: state.getIn(['search', 'searchForm', 'searchType']),
    activeFilters: state.getIn(['search', 'catalogSearch', 'activeFilters']),
    loggedIn: !!state.getIn(['auth', 'currentUserId'])
  };
}

const mapDispatchToProps = {
  trackNavigate
};

CoreHeaderContainer.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoreHeaderContainer));
