import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { childrenShape } from '@bibliocommons/bc-prop-types';

import HelpLink from './HelpLink.component';

export class HelpLinkContainer extends React.PureComponent {
  render() {
    const { className, page, currentLanguage, showIcon, children } = this.props;

    return (
      <HelpLink className={className} page={page} currentLanguage={currentLanguage} showIcon={showIcon}>
        {children}
      </HelpLink>
    );
  }
}

HelpLinkContainer.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string,
  showIcon: PropTypes.bool,
  children: childrenShape
};

const mapStateToProps = state => ({
  currentLanguage: state.getIn(['localization', 'currentLanguage'])
});

export default connect(mapStateToProps)(HelpLinkContainer);
