import Immutable from 'immutable';
import EventsConstants from '../../constants/EventsConstants';

const initialState = Immutable.fromJS({
  isFetching: false,
  results: [],
  events: {},
  query: {}
});

export default function MyEvents(state = initialState, action) {
  switch (action.type) {
    case EventsConstants.BROCHURE_EVENTS_REQUEST: {
      return state.merge({
        isFetching: true
      });
    }

    case EventsConstants.BROCHURE_EVENTS_SUCCESS: {
      return state.merge({
        ...action.payload.events,
        ...action.payload.entities,
        query: action.query,
        isFetching: false
      });
    }

    case EventsConstants.BROCHURE_EVENTS_FAILURE: {
      return state.merge({
        isFetching: false
      });
    }

    default:
      return state;
  }
}
