import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('QUOTATIONS', {
  GET_QUOTATION_REQUEST: null,
  GET_QUOTATION_SUCCESS: null,
  GET_QUOTATION_FAILURE: null,
  GET_QUOTATIONS_REQUEST: null,
  GET_QUOTATIONS_SUCCESS: null,
  GET_QUOTATIONS_FAILURE: null,
  ADD_QUOTATION_REQUEST: null,
  ADD_QUOTATION_SUCCESS: null,
  ADD_QUOTATION_FAILURE: null,
  UPDATE_QUOTATION_REQUEST: null,
  UPDATE_QUOTATION_SUCCESS: null,
  UPDATE_QUOTATION_FAILURE: null,
  REMOVE_QUOTATION_REQUEST: null,
  REMOVE_QUOTATION_SUCCESS: null,
  REMOVE_QUOTATION_FAILURE: null
});
