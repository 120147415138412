import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import { getCountsByStatusFromSummary } from '@bibliocommons/utils-borrowing';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import SidebarLink from '../SidebarLink';

import './StatusSidebarLink.scss';

const renderCount = count => <span className="status-count">{count}</span>;

export default function StatusSidebarLink({ className, status, icon, to, statusText, selected, summary, children }) {
  const counts = getCountsByStatusFromSummary(summary || Immutable.Map());
  const moreThanOneStatus = counts.size > 1;
  const formattedStatus = status.toLowerCase();

  return (
    <SidebarLink className={cn('cp-status-sidebar-link', className, formattedStatus)} selected={selected} to={to}>
      {icon}
      <div className="contents">
        <div className="status-wrapper">
          {statusText}
          <span className="extra-content">{children}</span>
        </div>
        {moreThanOneStatus && renderCount(counts.get(status))}
      </div>
    </SidebarLink>
  );
}

StatusSidebarLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.node,
  statusText: PropTypes.node,
  children: childrenShape,
  status: PropTypes.string,
  selected: PropTypes.bool,
  summary: ImmutablePropTypes.map
};
