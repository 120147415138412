import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { childrenShape } from '@bibliocommons/bc-prop-types';

import './SidebarLink.scss';

export default function SidebarLink({ className, selected, to, children }) {
  const classNames = cn('cp-sidebar-link', { selected }, className);
  return (
    <Link to={to} className={classNames}>
      {children}
    </Link>
  );
}

SidebarLink.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  to: PropTypes.string,
  children: childrenShape
};
