import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { selectDataLayer } from 'app/selectors/AnalyticsSelector';
import DataLayer from './DataLayer';

export class DataLayerContainer extends React.PureComponent {
  render() {
    return <DataLayer {...this.props} />;
  }
}

DataLayerContainer.propTypes = {
  dataLayer: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => ({
  dataLayer: selectDataLayer(state)
});

export default connect(mapStateToProps)(DataLayerContainer);
