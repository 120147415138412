import LocationsApi from '../api/LocationsApi';
import LocationsConstants from '../constants/LocationsConstants';

function searchLocations(query = {}) {
  return {
    query,
    types: [
      LocationsConstants.SEARCH_LOCATIONS_REQUEST,
      LocationsConstants.SEARCH_LOCATIONS_SUCCESS,
      LocationsConstants.SEARCH_LOCATIONS_FAILURE
    ],
    apiRequest: (state, client) => LocationsApi.getLocations({ ...query, limit: 200 }, client)
  };
}

export default {
  searchLocations
};
