import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import FinesActions from 'app/actions/FinesActions';
import BorrowingActions from 'app/actions/BorrowingActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const FinesContainer = loadable(() => import('app/components/borrowing/fines/Fines/FinesContainer'));

export function FinesPageRoute() {
  return <FinesContainer />;
}

function getRouteData({ dispatch, state, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [
    dispatch(BorrowingActions.fetchSummaries({ accountId })),
    dispatch(FinesActions.fetchFines({ ...query, accountId }))
  ];
}

export default connectRoute(FinesPageRoute, { getRouteData });
