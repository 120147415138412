import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { selectSingularUGCByUsers, selectSingularUGCForCurrentUser } from './UGCBaseSelector';
import { selectSummaryEntities } from '../EntitiesSelector';

const getTransactions = state => state.getIn(['ugc', 'summariesTransactions'], Immutable.Map());

export const selectSummariesByUsers = selectSingularUGCByUsers('summaries');

export const selectSummariesTransactions = createSelector([getTransactions], transactions => transactions);

export const selectCurrentUserSummaries = selectSingularUGCForCurrentUser('summaries');

const getSummaryIds = state => state.getIn(['ugc', 'summaries', 'results'], Immutable.List());

// All summaries
export const selectSummaries = createSelector([getSummaryIds, selectSummaryEntities], (summaryIds, summaryEntities) =>
  summaryIds.map(summaryId => summaryEntities.get(summaryId.toString()))
);

const pagination = state => state.getIn(['ugc', 'summaries', 'pagination'], Immutable.Map());

export const selectSummariesPagination = createSelector([pagination], page => page);

export default {
  selectSummariesByUsers,
  selectSummariesTransactions,
  selectCurrentUserSummaries
};
