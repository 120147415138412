import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import QuotationsActions from 'app/actions/QuotationsActions';

const QuotationPageContainer = loadable(() =>
  import('app/components/bibs/subpages/QuotationPage/QuotationPageContainer')
);

export function QuotationPageRoute() {
  return <QuotationPageContainer />;
}

function getRouteData({ dispatch, params }) {
  const quotationId = parseInt(params.quotationId, 10);
  return [dispatch(QuotationsActions.getQuotation(quotationId))];
}

export default connectRoute(QuotationPageRoute, { getRouteData });
