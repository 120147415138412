import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import QuotationsActions from 'app/actions/QuotationsActions';

const AllQuotationsPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllQuotationsPage/AllQuotationsPageContainer')
);

export function AllQuotationsPageRoute() {
  return <AllQuotationsPageContainer />;
}

function getRouteData({ dispatch, query, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(QuotationsActions.getQuotations({ metadataId: params.metadataId, page: query.page }))
  ];
}

export default connectRoute(AllQuotationsPageRoute, { getRouteData });
