import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import HoldStatusIcon from '../HoldStatusIcon';

import StatusSidebarLink from '../../sidebar/StatusSidebarLink';

import './HoldsSidebarLink.scss';

const renderStatus = status => <FormattedMessage id={`circ_${status.toLowerCase()}`} />;

export default function HoldsSidebarLink({ status, selected, summary }) {
  return (
    <StatusSidebarLink
      className="cp-holds-sidebar-link"
      selected={selected}
      status={status}
      icon={<HoldStatusIcon status={status} />}
      summary={summary}
      statusText={renderStatus(status)}
      to={`/v2/holds/${status.toLowerCase()}`}
    />
  );
}

HoldsSidebarLink.propTypes = {
  status: PropTypes.string,
  selected: PropTypes.bool,
  summary: ImmutablePropTypes.map
};
