import { of } from 'rxjs';
import ListsApi from '../api/ListsApi';
import ListsConstants from '../constants/ListsConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';

function getLists({ metadataId, page, category }) {
  return {
    types: [ListsConstants.GET_LISTS_REQUEST, ListsConstants.GET_LISTS_SUCCESS, ListsConstants.GET_LISTS_FAILURE],
    apiRequest: (state, client) => ListsApi.getLists({ metadataId, category, page, size: 10 }, client)
  };
}

function getCurrentUserLists({ metadataId, page, category }) {
  return {
    category,
    types: [
      ListsConstants.GET_CURRENT_USER_LISTS_REQUEST,
      ListsConstants.GET_CURRENT_USER_LISTS_SUCCESS,
      ListsConstants.GET_CURRENT_USER_LISTS_FAILURE
    ],
    apiRequest: (state, client) => {
      const userId = state.getIn(['auth', 'currentUserId']);
      return userId
        ? ListsApi.getLists({ userId, metadataId, category, page, size: 10, sort: 'updatedDate' }, client)
        : of({});
    }
  };
}

function createListItem({ metadataId, listId, annotation }) {
  return {
    listId,
    types: [
      ListsConstants.CREATE_LIST_ITEM_REQUEST,
      ListsConstants.CREATE_LIST_ITEM_SUCCESS,
      ListsConstants.CREATE_LIST_ITEM_FAILURE
    ],
    apiRequest: (state, client) => {
      const userId = state.getIn(['auth', 'currentUserId']);
      return ListsApi.createListItem({ listId, userId, metadataId, annotation }, client);
    },
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('add_to_list_success')
    })
  };
}

export default {
  getLists,
  getCurrentUserLists,
  createListItem
};
