import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const DollarAmount = ({ amount }) => (
  <span className="cp-dollar-amount">
    <FormattedMessage
      id="dollar_amount"
      values={{
        amount: <FormattedNumber value={amount} minimumFractionDigits={2} maximumFractionDigits={2} />
      }}
    />
  </span>
);

DollarAmount.propTypes = {
  amount: PropTypes.number
};

export default DollarAmount;
