import Immutable from 'immutable';
import formatFiltersForQuery from '../facets/formatFiltersForQuery';
import removeFiltersFromQuery from '../facets/removeFiltersFromQuery';
import { FILTER_SEPARATOR, FILTER_PREFIX } from '../../constants/BibConstants';

function isFilter(param) {
  return param.indexOf(FILTER_PREFIX) === 0;
}

/**
 * Returns the query object for a new search
 * @param {object} currentQuery the current router.query object
 * @param {object} activeFilters currently active filters
 * @param {object} updates changes to apply to the query
 */
export default function updateSearchQuery({ currentQuery, activeFilters, ...updates }) {
  const updatedQuery = {
    ...removeFiltersFromQuery(currentQuery, FILTER_PREFIX),
    ...formatFiltersForQuery(activeFilters || Immutable.Map(), FILTER_SEPARATOR, FILTER_PREFIX),
    ...updates
  };

  // Clear locked if there are no filters applied anymore
  const params = Object.keys(updatedQuery).filter(param => !!updatedQuery[param]);
  if (!params.some(isFilter)) {
    updatedQuery.locked = undefined;
  }

  return updatedQuery;
}
