import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import ShelfActions from 'app/actions/ShelfActions';
import { selectRequestedUser } from 'app/selectors/UserSelector';

const ShelfPrintListContainer = loadable(() => import('app/components/shelves/ShelfPrintList/ShelfPrintListContainer'));

export function ShelfPrintListRoute() {
  return <ShelfPrintListContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const user = selectRequestedUser(state);
  const userId = user && user.get('id');

  if (userId && params.shelfName) {
    return [dispatch(ShelfActions.searchUserShelf(params.shelfName, userId, query))];
  }

  return [];
}

export default connectRoute(ShelfPrintListRoute, { getRouteData });
