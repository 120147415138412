import keyBy from 'lodash/keyBy';
import { selectCurrentUser } from 'app/selectors/AuthSelector';
import { selectShelvesForCurrentUser } from 'app/selectors/ShelfSelector';
import composeGlobalSuccessMessaging from './composeGlobalSuccessMessaging';

export default function composeUgcSuccessMessaging({ response, store, metadataId, type, updated }) {
  const options = {
    actionButtonType: 'PrivacySettingsButton',
    actionButtonProps: { metadataId },
    values: { ugcType: type }
  };

  let messageId = type === 'rating' ? 'success_rating_updated' : 'success_ugc_updated';
  if (!updated) {
    const userId = selectCurrentUser(store.getState()).get('id');
    const shelfItem = keyBy(response.entities.shelves[userId], d => d.metadataId);
    const existingShelfItem = !!selectShelvesForCurrentUser(store.getState()).get(metadataId);
    const shelfKey = existingShelfItem ? 'shelf_existing' : 'shelf_added';
    const privateItemKey = shelfItem[metadataId].privateItem ? 'private' : 'public';
    const action = type === 'rating' ? 'rating' : 'ugc';
    messageId = `success_${action}_${shelfKey}_${privateItemKey}`;
  }
  return composeGlobalSuccessMessaging(messageId, options);
}
