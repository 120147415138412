import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import getDisplayName from 'react-display-name';
import ImmutablePropTypes from 'react-immutable-proptypes';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRouter } from '@bibliocommons/utils-routing';
import { childrenShape, userIdentityShape } from '@bibliocommons/bc-prop-types';
import { selectCurrentUser } from 'app/selectors/AuthSelector';
import Context from '@bibliocommons/context';

export function RouteWrapper(props) {
  const { location, query, params } = useRouter();
  const applicationContext = useContext(Context);
  const { getRouteData, state, dispatch, currentUser, children } = props;
  const [prevLocation, setPrevLocation] = useState(location);

  useEffect(() => {
    setPrevLocation(location);

    if (getRouteData) {
      // This logic automatically loads/reloads the route data every time the location
      // changes, as well as when the current user changes (logs in or session expires).
      // NOTE: `getRouteData` is called on the server to preload the data during
      // server-side rendering, hence, we do not need to call it again on the
      // initial page load on the client-side. `applicationContext.hydrated` would
      // indicate if the initial page rendering has completed so that we can call
      // `getRouteData` on subsequent page renders.
      if (applicationContext.hydrated) {
        getRouteData({ state, dispatch, location, params, query, prevLocation });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, location]);

  return children;
}

RouteWrapper.propTypes = {
  // Redux props
  state: ImmutablePropTypes.map.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentUser: userIdentityShape,

  // Wrapper props
  children: childrenShape.isRequired,
  getRouteData: PropTypes.func
};

const RouteWrapperContainer = connect(
  state => ({ state, currentUser: selectCurrentUser(state) }),
  dispatch => ({ dispatch })
)(RouteWrapper);

/**
 * Creates a HOC for enabling various route behaviours.
 * @return {Component}
 */
export default function connectRoute(RouteComponent, options = {}) {
  const { getRouteData, debounceOptions, mapStateToProps, mapDispatchToProps } = options;
  const RouteContainer =
    mapStateToProps || mapDispatchToProps
      ? connect(mapStateToProps, mapDispatchToProps)(RouteComponent)
      : RouteComponent;

  const wrapperProps = {
    getRouteData: debounceOptions ? debounce(getRouteData, debounceOptions.wait, debounceOptions) : getRouteData
  };

  const ConnectedRoute = React.forwardRef((props, ref) => (
    <RouteWrapperContainer {...wrapperProps}>
      <RouteContainer {...props} ref={ref} />
    </RouteWrapperContainer>
  ));

  ConnectedRoute.getRouteData = getRouteData;
  ConnectedRoute.WrappedComponent = RouteComponent;
  ConnectedRoute.displayName = `ConnectedRoute(${getDisplayName(RouteComponent)})`;

  return hoistNonReactStatics(ConnectedRoute, RouteComponent);
}
