import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import SummariesActions from 'app/actions/SummariesActions';

const SummaryPageContainer = loadable(() => import('app/components/bibs/subpages/SummaryPage/SummaryPageContainer'));

export function SummaryPageRoute() {
  return <SummaryPageContainer />;
}

function getRouteData({ dispatch, params }) {
  const summaryId = parseInt(params.summaryId, 10);
  return [dispatch(SummariesActions.getSummary(summaryId))];
}

export default connectRoute(SummaryPageRoute, { getRouteData });
