import SystemMessagesApi from '../api/SystemMessagesApi';
import SystemMessageConstants from '../constants/SystemMessageConstants';

function fetchSystemMessages(priority, destination = 'CORE') {
  return {
    types: [
      SystemMessageConstants.SYSTEM_MESSAGES_REQUEST,
      SystemMessageConstants.SYSTEM_MESSAGES_SUCCESS,
      SystemMessageConstants.SYSTEM_MESSAGES_ERROR
    ],
    apiRequest: (state, client) => SystemMessagesApi.fetchSystemMessages(state, client, priority, destination)
  };
}

export default {
  fetchSystemMessages
};
