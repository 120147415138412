import PropTypes from 'prop-types';
import React from 'react';
import './PrintLayout.scss';
import loadable from '@loadable/component';
import GlobalEventListeners from 'app/components/layouts/GlobalEventListeners';

const DeprecatedStyles = loadable(() => import('app/components/layouts/MainLayout/DeprecatedStyles'));

class Print extends React.PureComponent {
  render() {
    return (
      <DeprecatedStyles>
        <GlobalEventListeners />
        <div className="cp-print">{this.props.children}</div>
      </DeprecatedStyles>
    );
  }
}

Print.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Print;
