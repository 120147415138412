import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('SUMMARIES', {
  GET_SUMMARY_REQUEST: null,
  GET_SUMMARY_SUCCESS: null,
  GET_SUMMARY_FAILURE: null,
  GET_SUMMARIES_REQUEST: null,
  GET_SUMMARIES_SUCCESS: null,
  GET_SUMMARIES_FAILURE: null,
  ADD_SUMMARIES_REQUEST: null,
  ADD_SUMMARIES_SUCCESS: null,
  ADD_SUMMARIES_FAILURE: null,
  UPDATE_SUMMARIES_REQUEST: null,
  UPDATE_SUMMARIES_SUCCESS: null,
  UPDATE_SUMMARIES_FAILURE: null,
  REMOVE_SUMMARIES_REQUEST: null,
  REMOVE_SUMMARIES_SUCCESS: null,
  REMOVE_SUMMARIES_FAILURE: null
});
