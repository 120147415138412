import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectCurrentUser } from 'app/selectors/AuthSelector';
import StaffTakeoverMessage from './StaffTakeoverMessage';

export class StaffTakeoverContainer extends React.PureComponent {
  render() {
    if (this.props.name && this.props.showStaffTakeover) {
      return <StaffTakeoverMessage {...this.props} />;
    }
    return null;
  }
}

StaffTakeoverContainer.propTypes = {
  name: PropTypes.string,
  showStaffTakeover: PropTypes.bool
};

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);
  return {
    name: currentUser && currentUser.get('name'),
    showStaffTakeover: currentUser && currentUser.get('staffTakeover')
  };
};

export default connect(mapStateToProps)(StaffTakeoverContainer);
