import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Context from '@bibliocommons/context';
import NoticeActions from 'app/actions/NoticeActions';
import SystemMessageActions from 'app/actions/SystemMessageActions';
import { selectVisibleMessages } from 'app/selectors/SystemMessageSelector';
import SystemMessage from './SystemMessage';

export class SystemMessagesContainer extends React.PureComponent {
  componentDidMount() {
    if (!this.context.mobileApp) {
      this.props.systemMessageActions.fetchSystemMessages('CRITICAL');
    }
  }

  render() {
    const { systemMessages, localBranch } = this.props;

    if (systemMessages && systemMessages.size > 0) {
      return (
        <div className="container">
          {systemMessages.map(message => (
            <SystemMessage
              key={message.get('id')}
              message={message}
              dismissable={!localBranch}
              noticeActions={this.props.noticeActions}
            />
          ))}
        </div>
      );
    }

    return null;
  }
}

SystemMessagesContainer.propTypes = {
  systemMessages: ImmutablePropTypes.list.isRequired,
  localBranch: PropTypes.string,
  systemMessageActions: PropTypes.objectOf(PropTypes.func).isRequired,
  noticeActions: PropTypes.objectOf(PropTypes.func).isRequired
};

SystemMessagesContainer.contextType = Context;

const mapStateToProps = state => ({
  localBranch: state.getIn(['app', 'localBranch']),
  systemMessages: selectVisibleMessages(state)
});

const mapDispatchToProps = dispatch => ({
  systemMessageActions: bindActionCreators(SystemMessageActions, dispatch),
  noticeActions: bindActionCreators(NoticeActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessagesContainer);
