import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import ShelfActions from 'app/actions/ShelfActions';
import { selectRequestedUser } from 'app/selectors/UserSelector';

const ShelfContainer = loadable(() => import('app/components/shelves/Shelf/ShelfContainer'));

export function ShelfPageRoute() {
  return <ShelfContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const user = selectRequestedUser(state);
  const userId = user && user.get('id');

  if (userId && params.shelfName) {
    return [dispatch(ShelfActions.searchUserShelf(params.shelfName, userId, query))];
  }

  return [];
}

export default connectRoute(ShelfPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100 // milliseconds
  }
});
