import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Angle } from '@bibliocommons/deprecated-base-icons';
import TitleWithCount from '../../shared/TitleWithCount';

import './SidebarCompactTitle.scss';

export default function SidebarCompactTitle({ title, count, screenReaderCount, selected }) {
  return (
    <div className={cn('cp-sidebar-compact-title', { selected })}>
      <TitleWithCount title={title} count={count} screenReaderCount={screenReaderCount}>
        <div className="icon-wrapper">
          <Angle.Right />
        </div>
      </TitleWithCount>
    </div>
  );
}

SidebarCompactTitle.propTypes = {
  title: PropTypes.node,
  screenReaderCount: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.number, PropTypes.string]),
  selected: PropTypes.bool
};
