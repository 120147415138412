import ContentAdvisoriesApi from '../api/ContentAdvisoriesApi';
import ContentAdvisoriesConstants from '../constants/ContentAdvisoriesConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';

function getContentAdvisory(contentNoticeId) {
  return {
    types: [
      ContentAdvisoriesConstants.GET_CONTENT_NOTICE_REQUEST,
      ContentAdvisoriesConstants.GET_CONTENT_NOTICE_SUCCESS,
      ContentAdvisoriesConstants.GET_CONTENT_NOTICE_FAILURE
    ],
    apiRequest: (state, client) => ContentAdvisoriesApi.getContentAdvisory({ contentNoticeId }, client)
  };
}

function getContentAdvisories({ metadataId, page }) {
  return {
    types: [
      ContentAdvisoriesConstants.GET_CONTENT_ADVISORIES_REQUEST,
      ContentAdvisoriesConstants.GET_CONTENT_ADVISORIES_SUCCESS,
      ContentAdvisoriesConstants.GET_CONTENT_ADVISORIES_FAILURE
    ],
    apiRequest: (state, client) => ContentAdvisoriesApi.getContentAdvisories({ metadataId, page, size: 25 }, client)
  };
}

function addContentAdvisories({ contentAdvisories, postSuccess }) {
  return {
    contentAdvisoryIds: contentAdvisories.map(contentAdvisory => contentAdvisory.metadataId + contentAdvisory.type),
    postSuccess,
    types: [
      ContentAdvisoriesConstants.ADD_CONTENT_ADVISORIES_REQUEST,
      ContentAdvisoriesConstants.ADD_CONTENT_ADVISORIES_SUCCESS,
      ContentAdvisoriesConstants.ADD_CONTENT_ADVISORIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      ContentAdvisoriesApi.addContentAdvisories(
        {
          contentAdvisories,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      )
  };
}

function updateContentAdvisories({ contentAdvisories, postSuccess }) {
  return {
    contentAdvisoryIds: contentAdvisories.map(contentAdvisory => contentAdvisory.id),
    postSuccess,
    types: [
      ContentAdvisoriesConstants.UPDATE_CONTENT_ADVISORIES_REQUEST,
      ContentAdvisoriesConstants.UPDATE_CONTENT_ADVISORIES_SUCCESS,
      ContentAdvisoriesConstants.UPDATE_CONTENT_ADVISORIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      ContentAdvisoriesApi.updateContentAdvisories(
        {
          contentAdvisories,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      )
  };
}

function removeContentAdvisoriesSuccess(contentAdvisoryIds) {
  const messageId =
    contentAdvisoryIds.length > 1
      ? 'message_successful_remove_content_advisories'
      : 'message_successful_remove_content_advisory';

  return {
    messaging: composeGlobalSuccessMessaging(messageId),
    deletedEntities: contentAdvisoryIds.map(id => `ugc.contentAdvisories.${id}`)
  };
}

function removeContentAdvisories({ contentAdvisoryIds, postSuccess }) {
  return {
    contentAdvisoryIds,
    postSuccess,
    types: [
      ContentAdvisoriesConstants.REMOVE_CONTENT_ADVISORIES_REQUEST,
      ContentAdvisoriesConstants.REMOVE_CONTENT_ADVISORIES_SUCCESS,
      ContentAdvisoriesConstants.REMOVE_CONTENT_ADVISORIES_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      ContentAdvisoriesApi.removeContentAdvisories(
        {
          contentAdvisoryIds,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => removeContentAdvisoriesSuccess(contentAdvisoryIds)
  };
}

export default {
  getContentAdvisory,
  getContentAdvisories,
  addContentAdvisories,
  updateContentAdvisories,
  removeContentAdvisories
};
