import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const SEARCH_VIEW_COOKIE_NAME = 'searchViewOption';

export default createReduxConstants('SEARCH', {
  ADD_TITLE_SEARCH_FAILURE: null,
  ADD_TITLE_SEARCH_REQUEST: null,
  ADD_TITLE_SEARCH_SUCCESS: null,
  EXPAND_FIELD_FAILURE: null,
  EXPAND_FIELD_REQUEST: null,
  EXPAND_FIELD_SUCCESS: null,
  SEARCH_FAILURE: null,
  SEARCH_PROBE_FAILURE: null,
  SEARCH_PROBE_REQUEST: null,
  SEARCH_PROBE_SUCCESS: null,
  SEARCH_REQUEST: null,
  SEARCH_SUCCESS: null,
  GROUP_SEARCH_SUMMARY_REQUEST: null,
  GROUP_SEARCH_SUMMARY_SUCCESS: null,
  GROUP_SEARCH_SUMMARY_FAILURE: null,
  SET_SHOULD_PREVENT_IMMEDIATE_REFETCH: null,
  TOGGLE_EXPANDED_FIELD_FAILURE: null,
  TOGGLE_SEARCH_VIEW: null,
  TOGGLE_SHOW_GROUPINGS: null,
  SHELF_AVAILABILITY_SEARCH_REQUEST: null,
  SHELF_AVAILABILITY_SEARCH_SUCCESS: null,
  SHELF_AVAILABILITY_SEARCH_FAILURE: null
});
