import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import SummariesActions from 'app/actions/SummariesActions';

const AllSummariesPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllSummariesPage/AllSummariesPageContainer')
);

export function AllSummariesPageRoute() {
  return <AllSummariesPageContainer />;
}

function getRouteData({ dispatch, query, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(SummariesActions.getSummaries({ metadataId: params.metadataId, page: query.page }))
  ];
}

export default connectRoute(AllSummariesPageRoute, { getRouteData });
