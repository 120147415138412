import { getBrowseConfig } from 'app/api/BrowseApi';
import BrowseConstants from 'app/constants/BrowseConstants';

const fetchBrowseConfig = () => {
  return {
    types: [
      BrowseConstants.FETCH_BROWSE_REQUEST,
      BrowseConstants.FETCH_BROWSE_SUCCESS,
      BrowseConstants.FETCH_BROWSE_FAILURE
    ],
    apiRequest: (_state, apiClient) => getBrowseConfig(apiClient)
  };
};

export { fetchBrowseConfig };
