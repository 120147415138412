import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import BibsActions from 'app/actions/BibsActions';

const AllRecommendationsPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllRecommendationsPage/AllRecommendationsPageContainer')
);

export function AllRecommendationsPageRoute() {
  return <AllRecommendationsPageContainer />;
}

function getRouteData({ dispatch, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(BibsActions.getRecommendations(params.metadataId))
  ];
}

export default connectRoute(AllRecommendationsPageRoute, { getRouteData });
