const createFollow = ({ ownerUserId, targetUserId }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${ownerUserId}/relationships/followedUsers`;
  return apiClient.post(route, { followedUserId: targetUserId });
};

const createIgnore = ({ ownerUserId, targetUserId }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${ownerUserId}/relationships/ignoredUsers`;
  return apiClient.post(route, { ignoredUserId: targetUserId });
};

const getRelationships = ({ userId, userIds }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${userId}/relationships`;
  return apiClient.get(route, { query: { userIds: userIds.toArray() } });
};

const removeFollow = ({ followId, ownerUserId }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${ownerUserId}/relationships/followedUsers/${followId}`;
  return apiClient.delete(route);
};

const removeIgnore = ({ ignoreId, ownerUserId }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${ownerUserId}/relationships/ignoredUsers/${ignoreId}`;
  return apiClient.delete(route);
};

export default { createFollow, createIgnore, getRelationships, removeFollow, removeIgnore };
