import { of } from 'rxjs';
import BibsApi from '../api/BibsApi';
import BibConstants from '../constants/BibConstants';

function fetchUserTransactions(metadataIds) {
  return {
    types: [
      BibConstants.FETCH_USER_TRANSACTIONS_REQUEST,
      BibConstants.FETCH_USER_TRANSACTIONS_SUCCESS,
      BibConstants.FETCH_USER_TRANSACTIONS_FAILURE
    ],
    apiRequest: (state, client) => {
      const currentUserId = state.getIn(['auth', 'currentUserId']);
      if (currentUserId) {
        return BibsApi.fetchUserTransactions(metadataIds, currentUserId, client);
      }

      return of({});
    }
  };
}

function fetchAvailability(metadataId) {
  return {
    metadataId,
    types: [
      BibConstants.FETCH_AVAILABILITY_REQUEST,
      BibConstants.FETCH_AVAILABILITY_SUCCESS,
      BibConstants.FETCH_AVAILABILITY_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.fetchAvailability(metadataId, client)
  };
}

function fetchDiscovery(metadataId) {
  return {
    types: [
      BibConstants.FETCH_DISCOVERY_REQUEST,
      BibConstants.FETCH_DISCOVERY_SUCCESS,
      BibConstants.FETCH_DISCOVERY_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.fetchDiscovery(metadataId, client)
  };
}

function fetchEvaluation(metadataId) {
  return {
    types: [
      BibConstants.FETCH_EVALUATION_REQUEST,
      BibConstants.FETCH_EVALUATION_SUCCESS,
      BibConstants.FETCH_EVALUATION_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.fetchEvaluation(metadataId, client)
  };
}

function fetchDigitalFormats({ metadataId, postSuccess }) {
  return {
    metadataId,
    postSuccess,
    types: [
      BibConstants.FETCH_DIGITAL_FORMATS_REQUEST,
      BibConstants.FETCH_DIGITAL_FORMATS_SUCCESS,
      BibConstants.FETCH_DIGITAL_FORMATS_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.fetchAvailability(metadataId, client)
  };
}

function fetchUGC(metadataId, postSuccess) {
  return {
    types: [BibConstants.FETCH_UGC_REQUEST, BibConstants.FETCH_UGC_SUCCESS, BibConstants.FETCH_UGC_FAILURE],
    apiRequest: (_state, client) => BibsApi.fetchUGC(metadataId, client),
    postSuccess
  };
}

function fetchHoldableItems(metadataId) {
  return {
    types: [
      BibConstants.FETCH_HOLDABLE_ITEMS_REQUEST,
      BibConstants.FETCH_HOLDABLE_ITEMS_SUCCESS,
      BibConstants.FETCH_HOLDABLE_ITEMS_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.fetchHoldableItems(metadataId, client)
  };
}

function fetchMarcFields(metadataId) {
  return {
    types: [BibConstants.GET_MARC_REQUEST, BibConstants.GET_MARC_SUCCESS, BibConstants.GET_MARC_FAILURE],
    apiRequest: (state, client) => BibsApi.fetchMarcFields({ metadataId }, client)
  };
}

function getReviews({ metadataId, page }) {
  return {
    types: [BibConstants.GET_REVIEWS_REQUEST, BibConstants.GET_REVIEWS_SUCCESS, BibConstants.GET_REVIEWS_FAILURE],
    apiRequest: (state, client) => BibsApi.getReviews({ metadataId, page, size: 25 }, client)
  };
}

function getRecommendations(metadataId) {
  return {
    types: [
      BibConstants.GET_RECOMMENDATIONS_REQUEST,
      BibConstants.GET_RECOMMENDATIONS_SUCCESS,
      BibConstants.GET_RECOMMENDATIONS_FAILURE
    ],
    apiRequest: (state, client) => BibsApi.getRecommendations(metadataId, client)
  };
}

export default {
  fetchAvailability,
  fetchDigitalFormats,
  fetchUserTransactions,
  fetchDiscovery,
  fetchEvaluation,
  fetchUGC,
  fetchHoldableItems,
  fetchMarcFields,
  getReviews,
  getRecommendations
};
