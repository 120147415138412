import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { trackPagePrint } from 'app/actions/AnalyticsGa4Actions';

const GlobalEventListeners = () => {
  const dispatch = useDispatch();

  const isClientSide = Boolean(__CLIENT__ && window);

  useEffect(() => {
    if (!isClientSide) {
      return () => {};
    }

    const onBeforePrint = () => dispatch(trackPagePrint());

    window.addEventListener('beforeprint', onBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
    };
  }, [dispatch, isClientSide]);

  return null;
};

export default GlobalEventListeners;
