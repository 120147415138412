import CatalogBibsApi from '../api/CatalogBibsApi';
import CatalogBibsConstants from '../constants/CatalogBibsConstants';

function fetchCatalogBib(metadataId) {
  return {
    types: [
      CatalogBibsConstants.FETCH_CATALOG_BIB_REQUEST,
      CatalogBibsConstants.FETCH_CATALOG_BIB_SUCCESS,
      CatalogBibsConstants.FETCH_CATALOG_BIB_FAILURE
    ],
    apiRequest: (state, client) => CatalogBibsApi.getLocalizedCatalogBib(metadataId, client)
  };
}

function fetchCatalogBibs(metadataIds) {
  return {
    types: [
      CatalogBibsConstants.FETCH_CATALOG_BIBS_REQUEST,
      CatalogBibsConstants.FETCH_CATALOG_BIBS_SUCCESS,
      CatalogBibsConstants.FETCH_CATALOG_BIBS_FAILURE
    ],
    apiRequest: (state, client) => CatalogBibsApi.getCatalogBibs(metadataIds, client)
  };
}

export default {
  fetchCatalogBib,
  fetchCatalogBibs
};

export { fetchCatalogBib, fetchCatalogBibs };
