import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { jacketShape } from '@bibliocommons/bc-prop-types';
import { bindAll } from '@bibliocommons/utils-react';

import { selectCatalogBib } from 'app/selectors/catalogBibs/CatalogBibSelector';
import BibJacket from './BibJacket';

export class BibJacketContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleClick() {
    const { onClick } = this.props;

    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  }

  render() {
    return <BibJacket {...this.props} handleClick={this.handleClick} />;
  }
}

BibJacketContainer.propTypes = {
  onClick: PropTypes.func,
  renderAsLink: PropTypes.bool,
  reciprocalRank: PropTypes.number,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  format: PropTypes.string,
  cover: jacketShape,
  metadataId: PropTypes.string.isRequired,
  source: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  catalogBib: ownProps.catalogBib || selectCatalogBib(state)
});

export default connect(mapStateToProps)(BibJacketContainer);
