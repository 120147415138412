import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ListIcon } from '@bibliocommons/deprecated-base-icons';
import StatusSidebarLink from '../StatusSidebarLink';

import './AllSidebarLink.scss';

export default function AllSidebarLink({ to, selected }) {
  return (
    <StatusSidebarLink
      className="cp-all-sidebar-link"
      selected={selected}
      status="all"
      icon={
        <div className="all-icon">
          <ListIcon />
        </div>
      }
      statusText={<FormattedMessage id="all_items" />}
      to={to}
    />
  );
}

AllSidebarLink.propTypes = {
  to: PropTypes.string,
  selected: PropTypes.bool
};
