import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextButton } from '@bibliocommons/deprecated-base-buttons';

import './SingleClickHoldsStatusButton.scss';

const getStatus = enabled => (enabled ? <FormattedMessage id="on" /> : <FormattedMessage id="off" />);

export default function SingleClickHoldsStatusButton({ singleClickHoldsEnabled, handleClick }) {
  const status = <span className="status">{getStatus(singleClickHoldsEnabled)}</span>;

  return (
    <TextButton className="cp-single-click-holds-status-button" handleClick={handleClick}>
      <FormattedMessage id="single_click_holds_status" values={{ status }} />
    </TextButton>
  );
}

SingleClickHoldsStatusButton.propTypes = {
  singleClickHoldsEnabled: PropTypes.bool,
  handleClick: PropTypes.func
};
