import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ScreenReaderShortcuts from './ScreenReaderShortcuts';

const mapStateToProps = state => ({
  screenReaderNavigation: state.getIn(['templates', 'screenReaderNavigation'])
});

const ScreenReaderShortcutsContainer = ({ screenReaderNavigation }) => (
  <ScreenReaderShortcuts screenReaderNavigation={screenReaderNavigation} />
);

ScreenReaderShortcutsContainer.displayName = 'ScreenReaderShortcutsContainer';

ScreenReaderShortcutsContainer.propTypes = {
  screenReaderNavigation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(ScreenReaderShortcutsContainer);
