import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { selectSingularUGCByUsers, selectSingularUGCForCurrentUser } from './UGCBaseSelector';
import { selectCommentEntities } from '../EntitiesSelector';

const getTransactions = state => state.getIn(['ugc', 'commentsTransactions'], Immutable.Map());

export const selectCommentsByUsers = selectSingularUGCByUsers('comments');

export const selectCommentsTransactions = createSelector([getTransactions], transactions => transactions);

export const selectCurrentUserComments = selectSingularUGCForCurrentUser('comments');

const getCommentIds = state => state.getIn(['ugc', 'comments', 'results'], Immutable.List());

// All comments
export const selectComments = createSelector([getCommentIds, selectCommentEntities], (commentIds, commentEntities) =>
  commentIds.map(commentId => commentEntities.get(commentId.toString()))
);

const pagination = state => state.getIn(['ugc', 'comments', 'pagination'], Immutable.Map());

export const selectCommentsPagination = createSelector([pagination], page => page);

export default {
  selectCommentsByUsers,
  selectCommentsTransactions,
  selectCurrentUserComments,
  selectComments,
  selectCommentsPagination
};
