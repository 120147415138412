import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { catalogBibShape } from '@bibliocommons/bc-prop-types';
import { selectCatalogBibEntities } from 'app/selectors/EntitiesSelector';
import BibSlide from './BibSlide';

export class BibSlideContainer extends React.PureComponent {
  render() {
    return <BibSlide {...this.props} />;
  }
}

BibSlideContainer.propTypes = {
  // ownProps
  className: PropTypes.string,
  metadataId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  handleClick: PropTypes.func,

  // Redux props
  catalogBib: catalogBibShape.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  catalogBib: selectCatalogBibEntities(state).get(ownProps.metadataId)
});

export default connect(mapStateToProps)(BibSlideContainer);
