import React from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Detects changes between 2 versions of the `dataLayer` prop,
// which can used to update the global `dataLayer`. The returned
// object will contain newly added and updated fields, as well
// as removed fields which are set to `undefined`.
function getUpdates(prevData = Immutable.Map(), nextData) {
  const removed = prevData.filter((val, key) => !nextData.has(key)).map(() => undefined);

  const updated = nextData.reduce((result, value, key) => {
    if (Immutable.Map.isMap(value)) {
      const updates = getUpdates(prevData.get(key, Immutable.Map()), value);
      if (updates) {
        return result.set(key, updates);
      }
    }

    if (prevData.get(key) !== value) {
      return result.set(key, value);
    }

    return result;
  }, Immutable.Map());

  if (removed.size || updated.size) {
    return { ...updated.toJS(), ...removed.toJS() };
  }

  return undefined;
}

export default class DataLayer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const updates = getUpdates(prevProps.dataLayer, this.props.dataLayer);

    // Push changes to the global dataLayer
    if (window.dataLayer && updates) {
      window.dataLayer.push(updates);
    }
  }

  render() {
    return null;
  }
}

DataLayer.propTypes = {
  dataLayer: ImmutablePropTypes.map.isRequired
};
