import InterlibraryLoansApi from '../api/InterlibraryLoansApi';
import InterlibraryLoansConstants from '../constants/InterlibraryLoansConstants';

export function fetchInterlibraryLoans({ accountId, size = 20, status, page = 1, sort = 'status' }) {
  return {
    types: [
      InterlibraryLoansConstants.FETCH_ILL_REQUEST,
      InterlibraryLoansConstants.FETCH_ILL_SUCCESS,
      InterlibraryLoansConstants.FETCH_ILL_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) =>
      InterlibraryLoansApi.getInterlibraryLoans(apiClient, {
        accountId,
        size,
        status: status && status.toUpperCase(),
        page,
        sort
      })
  };
}

export default {
  fetchInterlibraryLoans
};
