import PropTypes from 'prop-types';
import React from 'react';
import { Attention } from '@bibliocommons/deprecated-base-icons';

import BorrowingStatusIcon from '../../shared/BorrowingStatusIcon';

import './FinesStatusIcon.scss';

export default function FinesStatusIcon({ withBackground }) {
  return <BorrowingStatusIcon className="cp-fines-status-icon" icon={<Attention />} withBackground={withBackground} />;
}

FinesStatusIcon.propTypes = {
  withBackground: PropTypes.bool
};
