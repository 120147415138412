import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('USER', {
  DELETE_USER_ERROR: null,
  DELETE_USER_REQUEST: null,
  DELETE_USER_SUCCESS: null,
  FETCH_USER_REQUEST: null,
  FETCH_USER_SUCCESS: null,
  FETCH_USER_ERROR: null
});
