import Immutable from 'immutable';
import { createSelector } from 'reselect';

const borrowingSummaryFromState = (state, type) =>
  state.getIn(['borrowing', 'borrowing', 'summaries', type], Immutable.Map());

const borrowingHistorySummaryFromState = state =>
  state.getIn(['borrowing', 'borrowingHistory', 'summaries', 'totalElements'], -1);

export const selectBorrowingSummary = createSelector([borrowingSummaryFromState], summary => summary);

export const selectBorrowingHistorySummary = createSelector([borrowingHistorySummaryFromState], summary => summary);

export default {
  selectBorrowingSummary,
  selectBorrowingHistorySummary
};
