import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import { bindAll } from '@bibliocommons/utils-react';
import { withRouter } from '@bibliocommons/utils-routing';
import { routerShape } from '@bibliocommons/bc-prop-types';
import { getTotalCountFromSummary, getCountsByStatusFromSummary } from '@bibliocommons/utils-borrowing';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { CHECKOUT_STATUS_TYPES } from 'app/constants/CheckoutConstants';

import CheckedOutStatusIcon from '../CheckedOutStatusIcon';
import BorrowingStatus from '../../shared/BorrowingStatus';
import TitleWithCount from '../../shared/TitleWithCount';
import NextDueDate from '../NextDueDate';
import CheckedOutSidebarLink from '../CheckedOutSidebarLink';
import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';
import AllSidebarLink from '../../sidebar/AllSidebarLink';

import './CheckedOutStatus.scss';

const STATUS_ORDER = [CHECKOUT_STATUS_TYPES.OVERDUE, CHECKOUT_STATUS_TYPES.COMING_DUE, CHECKOUT_STATUS_TYPES.OUT];

export class CheckedOutStatus extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  getTotalCount() {
    return getTotalCountFromSummary(this.props.summary);
  }

  statusIsActive(status) {
    return this.props.router.location.pathname.includes(`v2/checkedout/${status.toLowerCase()}`);
  }

  noStatusIsSelected() {
    return this.props.router.location.pathname === '/v2/checkedout';
  }

  renderAllItemsButton(groupedCheckouts) {
    if (groupedCheckouts.size > 1) {
      return <AllSidebarLink to="/v2/checkedout" selected={this.noStatusIsSelected()} />;
    }

    return null;
  }

  renderGrouping(status) {
    const nextDue = this.props.summary.get('nextDue');

    return (
      <CheckedOutSidebarLink
        key={`checkedout-sidebar-link-${status}`}
        summary={this.props.summary}
        status={status}
        selected={this.statusIsActive(status)}
        nextDue={nextDue}
      />
    );
  }

  renderFullStatus() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());

    return (
      <span>
        <Link className="section-title" to="/v2/checkedout">
          <TitleWithCount title={<FormattedMessage id="section_title_checkedout" />} count={this.getTotalCount()} />
        </Link>
        {this.renderAllItemsButton(statusSummaries)}
        {statusSummaries
          .map((summary, status) => status)
          .toList()
          .sortBy(status => STATUS_ORDER.indexOf(status))
          .map(this.renderGrouping)
          .toArray()}
      </span>
    );
  }

  renderNextDue() {
    const nextDue = this.props.summary.get('nextDue');
    if (nextDue && nextDue.get('status')) {
      const date = <NextDueDate date={nextDue.get('date')} />;
      const status = nextDue.get('status');

      return (
        <span className={cn(status.toLowerCase())}>
          <div className="checkedout-status-icon">
            <CheckedOutStatusIcon status={status} />
          </div>

          <span aria-hidden="true">{date}</span>

          <ScreenReaderMessage>
            <FormattedMessage id="label_next_due_on_date" values={{ date }} />.
          </ScreenReaderMessage>
        </span>
      );
    }

    return null;
  }

  renderOverdueCount() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());
    if (statusSummaries.get(CHECKOUT_STATUS_TYPES.OVERDUE)) {
      const count = getCountsByStatusFromSummary(this.props.summary).get(CHECKOUT_STATUS_TYPES.OVERDUE, 0);
      return (
        <span className="overdue">
          <div className="checkedout-status-icon status-icon">
            <CheckedOutStatusIcon status={CHECKOUT_STATUS_TYPES.OVERDUE} />
          </div>

          <span aria-hidden="true">{count}</span>

          <ScreenReaderMessage>
            <FormattedMessage id="borrowing_item_count_overdue" values={{ count }} />.
          </ScreenReaderMessage>
        </span>
      );
    }

    return null;
  }

  renderCompactStatus() {
    const overDueCount = this.renderOverdueCount();
    const nextDue = this.renderNextDue();

    return (
      <span>
        <SidebarCompactTitle title={<FormattedMessage id="section_title_checkedout" />} count={this.getTotalCount()} />
        {(overDueCount || nextDue) && (
          <div className="small checkedout-status">
            {overDueCount}
            {nextDue}
          </div>
        )}
      </span>
    );
  }

  render() {
    return (
      <BorrowingStatus to="/v2/checkedout" className="cp-checked-out-status" currentSection={this.props.currentSection}>
        {this.props.currentSection ? this.renderFullStatus() : this.renderCompactStatus()}
      </BorrowingStatus>
    );
  }
}

CheckedOutStatus.propTypes = {
  summary: ImmutablePropTypes.mapContains({
    nextDue: ImmutablePropTypes.mapContains({
      date: PropTypes.string,
      status: PropTypes.string
    }),
    status: ImmutablePropTypes.map,
    total: PropTypes.number
  }),
  currentSection: PropTypes.bool,
  router: routerShape.isRequired
};

export default withRouter(CheckedOutStatus);
