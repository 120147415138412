import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import BibsActions from 'app/actions/BibsActions';

const MarcPageContainer = loadable(() => import('app/components/bibs/subpages/MarcPage/MarcPageContainer'));

export function MarcPageRoute() {
  return <MarcPageContainer />;
}

function getRouteData({ dispatch, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(BibsActions.fetchMarcFields(params.metadataId))
  ];
}

export default connectRoute(MarcPageRoute, { getRouteData });
