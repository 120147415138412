import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('PRIVATE_NOTES', {
  ADD_PRIVATE_NOTES_REQUEST: null,
  ADD_PRIVATE_NOTES_SUCCESS: null,
  ADD_PRIVATE_NOTES_FAILURE: null,
  UPDATE_PRIVATE_NOTES_REQUEST: null,
  UPDATE_PRIVATE_NOTES_SUCCESS: null,
  UPDATE_PRIVATE_NOTES_FAILURE: null,
  REMOVE_PRIVATE_NOTES_REQUEST: null,
  REMOVE_PRIVATE_NOTES_SUCCESS: null,
  REMOVE_PRIVATE_NOTES_FAILURE: null
});
