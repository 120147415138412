import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import VideosActions from 'app/actions/VideosActions';

const AllVideosPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllVideosPage/AllVideosPageContainer')
);

export function AllVideosPageRoute() {
  return <AllVideosPageContainer />;
}

function getRouteData({ dispatch, query, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(VideosActions.getVideos({ metadataId: params.metadataId, page: query.page }))
  ];
}

export default connectRoute(AllVideosPageRoute, { getRouteData });
