import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import { LIST_CATEGORIES } from 'app/constants/ListsConstants';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import ListsActions from 'app/actions/ListsActions';

const AllListsPageContainer = loadable(() => import('app/components/bibs/subpages/AllListsPage/AllListsPageContainer'));

export function AllListsPageRoute() {
  return <AllListsPageContainer />;
}

function getRouteData({ dispatch, query, params }) {
  const category = query.category || LIST_CATEGORIES.STAFF;
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(ListsActions.getLists({ ...query, category, metadataId: params.metadataId }))
  ];
}

export default connectRoute(AllListsPageRoute, { getRouteData });
