import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CheckoutActions from 'app/actions/CheckoutActions';
import BorrowingActions from 'app/actions/BorrowingActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const CheckedOutContainer = loadable(() => import('app/components/borrowing/checkouts/CheckedOut/CheckedOutContainer'));

export function CheckedOutPageRoute() {
  return <CheckedOutContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [
    dispatch(BorrowingActions.fetchSummaries({ accountId })),
    dispatch(CheckoutActions.fetchCheckouts({ accountId, ...params, ...query }))
  ];
}

export default connectRoute(CheckedOutPageRoute, { getRouteData });
