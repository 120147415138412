import VideosApi from '../api/VideosApi';
import VideosConstants from '../constants/VideosConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';
import composeUgcSuccessMessaging from '../helpers/messaging/composeUgcSuccessMessaging';

function getVideo(videoId) {
  return {
    types: [VideosConstants.GET_VIDEO_REQUEST, VideosConstants.GET_VIDEO_SUCCESS, VideosConstants.GET_VIDEO_FAILURE],
    apiRequest: (state, client) => VideosApi.getVideo({ videoId }, client)
  };
}

function getVideos({ metadataId, page }) {
  return {
    types: [VideosConstants.GET_VIDEOS_REQUEST, VideosConstants.GET_VIDEOS_SUCCESS, VideosConstants.GET_VIDEOS_FAILURE],
    apiRequest: (state, client) => VideosApi.getVideos({ metadataId, page, size: 25 }, client)
  };
}

function addVideo({ metadataId, url, title, description, postSuccess }) {
  return {
    metadataId,
    postSuccess,
    types: [VideosConstants.ADD_VIDEO_REQUEST, VideosConstants.ADD_VIDEO_SUCCESS, VideosConstants.ADD_VIDEO_FAILURE],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      VideosApi.addVideo(
        {
          metadataId,
          url,
          title,
          description,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: (response, store) => ({
      messaging: composeUgcSuccessMessaging({
        response,
        store,
        metadataId,
        type: 'video'
      })
    })
  };
}

function updateVideo({ metadataId, videoId, url, title, description, postSuccess }) {
  return {
    metadataId,
    videoId,
    postSuccess,
    types: [
      VideosConstants.UPDATE_VIDEO_REQUEST,
      VideosConstants.UPDATE_VIDEO_SUCCESS,
      VideosConstants.UPDATE_VIDEO_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      VideosApi.updateVideo(
        {
          metadataId,
          videoId,
          url,
          title,
          description,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeUgcSuccessMessaging({
        metadataId,
        type: 'video',
        updated: true
      })
    })
  };
}

function removeVideo({ metadataId, videoId, postSuccess }) {
  return {
    metadataId,
    videoId,
    postSuccess,
    types: [
      VideosConstants.REMOVE_VIDEO_REQUEST,
      VideosConstants.REMOVE_VIDEO_SUCCESS,
      VideosConstants.REMOVE_VIDEO_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) =>
      VideosApi.removeVideo(
        {
          videoId,
          userId: state.getIn(['auth', 'currentUserId'])
        },
        client
      ),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('success_ugc_remove_message', { values: { ugcType: 'video' } }),
      deletedEntities: [`ugc.videos.${videoId}`]
    })
  };
}

export default {
  getVideo,
  getVideos,
  addVideo,
  updateVideo,
  removeVideo
};
