import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

function MessageIfExists({ component: Component = FormattedMessage, intl, ...props }) {
  if (intl.messages[props.id]) {
    return <Component {...props} />;
  }
  return null;
}

MessageIfExists.propTypes = {
  id: PropTypes.string.isRequired,

  component: PropTypes.oneOfType([PropTypes.instanceOf(React.Component), PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func])
};

export default injectIntl(MessageIfExists);
