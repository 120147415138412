import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Link } from '@bibliocommons/base-links';
import { childrenShape } from '@bibliocommons/bc-prop-types';

export default function BibLink({ className, metadataId, children, handleClick, isDecorative }) {
  const classNames = cn('cp-bib-link', className);
  const attributes = {
    className: classNames,
    href: `/v2/record/${metadataId}`,
    dataKey: 'bib-link',
    handleClick
  };

  if (isDecorative) {
    attributes['aria-hidden'] = true;
    attributes.tabIndex = '-1';
  }

  return <Link {...attributes}>{children}</Link>;
}

BibLink.propTypes = {
  className: PropTypes.string,
  metadataId: PropTypes.string.isRequired,
  children: childrenShape,
  handleClick: PropTypes.func,
  isDecorative: PropTypes.bool
};
