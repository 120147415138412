import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindAll } from '@bibliocommons/utils-react';
import { PrimaryButton, TextButton } from '@bibliocommons/deprecated-base-buttons';
import { branchShape } from '@bibliocommons/bc-prop-types';
import HelpLinkContainer from 'app/components/shared/HelpLink/HelpLinkContainer';
import BranchDropdown from 'app/components/shared/BranchDropdown';

import './SingleClickHoldsForm.scss';

export default class SingleClickHoldsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranchCode: this.props.branchCode || props.branches.first().get('code')
    };

    bindAll(this);
  }

  getBranchName() {
    const { selectedBranchCode } = this.state;
    const branch = this.props.branches.find(b => b.get('code') === selectedBranchCode);
    return branch ? branch.get('name') : '';
  }

  handleSelect(branchCode) {
    this.setState({
      selectedBranchCode: branchCode
    });
  }

  handleSubmit() {
    this.props.handleSubmit({
      branchCode: this.state.selectedBranchCode
    });
  }

  render() {
    return (
      <form className="cp-single-click-holds-form">
        <HelpLinkContainer page="010singleclick" />
        <h3>
          <FormattedMessage id="single_click_holds_form_title" />
        </h3>

        <BranchDropdown
          branches={this.props.branches}
          handleSelect={this.handleSelect}
          selectedBranchName={this.getBranchName()}
        >
          <FormattedMessage id="single_click_holds_location_label" />
        </BranchDropdown>

        <p className="small description">
          <FormattedMessage id="single_click_holds_location_description" />
        </p>

        <PrimaryButton className="save-button" handleClick={this.handleSubmit}>
          <FormattedMessage id="button_save" />
        </PrimaryButton>

        <TextButton className="cancel-button" handleClick={this.props.handleCancel}>
          <FormattedMessage id="button_cancel" />
        </TextButton>
      </form>
    );
  }
}

SingleClickHoldsForm.propTypes = {
  branches: ImmutablePropTypes.listOf(branchShape),
  branchCode: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func
};
