function getSummary({ summaryId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/summaries/${summaryId}`;
  return apiClient.get(route);
}

function getSummaries({ metadataId, page, size }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/summaries`;
  return apiClient.get(route, { query: { metadataId, page, size } });
}

function addSummary({ userId, metadataId, summary }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/summaries`;
  return apiClient.post(route, { userId, metadataId, summary });
}

function updateSummary({ userId, summaryId, summary }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/summaries/${summaryId}`;
  return apiClient.patch(route, { userId, summary });
}

function removeSummary({ userId, summaryId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/summaries/${summaryId}`;
  return apiClient.delete(route, { userId });
}

export default {
  getSummary,
  getSummaries,
  addSummary,
  updateSummary,
  removeSummary
};
