import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { childrenShape } from '@bibliocommons/bc-prop-types';

import './Panel.scss';

export default function Panel({ className, children }) {
  return <div className={cn(className, 'cp-panel')}>{children}</div>;
}

Panel.propTypes = {
  className: PropTypes.string,
  children: childrenShape
};
