import PropTypes from 'prop-types';
import React from 'react';
import { isVisibleInViewPort } from '@bibliocommons/utils-browser';
import { bindAll } from '@bibliocommons/utils-react';

class VisibilitySensor extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    this.props.isVisible(isVisibleInViewPort(this.node));
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.props.isVisible(isVisibleInViewPort(this.node));
  }

  render() {
    return <div ref={node => (this.node = node)}>{React.Children.only(this.props.children)}</div>;
  }
}

VisibilitySensor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isVisible: PropTypes.func
};

export default VisibilitySensor;
