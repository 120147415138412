function getQuotation({ quotationId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/quotations/${quotationId}`;
  return apiClient.get(route);
}

function getQuotations({ metadataId, page, size }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/quotations`;
  return apiClient.get(route, { query: { metadataId, page, size } });
}

function addQuotation({ userId, metadataId, quotation }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/quotations`;
  return apiClient.post(route, { userId, metadataId, quotation });
}

function updateQuotation({ userId, quotationId, quotation }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/quotations/${quotationId}`;
  return apiClient.patch(route, { userId, quotation });
}

function removeQuotation({ userId, quotationId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/ugc/quotations/${quotationId}`;
  return apiClient.delete(route, { userId });
}

export default {
  getQuotation,
  getQuotations,
  addQuotation,
  updateQuotation,
  removeQuotation
};
