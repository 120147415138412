import 'raf/polyfill';
import '@bibliocommons/polyfills';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';
import Iso from 'iso';
import httpClient from 'superagent';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import ApiClient from './api/ApiClient';
import ErrorService from './services/ErrorService';
import createReduxStore from './helpers/redux/createReduxStore';
import ApplicationContainer from './ApplicationContainer';
import ApplicationRouter from './ApplicationRouter';

// Prevent safari loading from cache when back button is clicked
window.onpageshow = function handleSafariBFCache(event) {
  if (event.persisted && typeof window.parentIFrame === 'undefined') {
    window.location.href += '&reload=true';
  }
};

// Boot up the React application
Iso.bootstrap(reduxStoreData => {
  const rootElement = document.getElementById('react-root');

  const {
    apiGatewayURL,
    libraryDomain,
    airbrakeConfig,
    datadogConfig,
    enableWebpackHMR,
    enableReduxDevTools
  } = reduxStoreData.app;

  const reduxState = Immutable.fromJS(reduxStoreData);
  const currentLanguage = reduxStoreData.localization.currentLanguage;

  if (datadogConfig.applicationId && datadogConfig.clientToken) {
    datadogRum.init(datadogConfig);
  }

  ErrorService.initialize(airbrakeConfig);

  const apiClient = new ApiClient(httpClient, {
    subdomain: libraryDomain,
    locale: currentLanguage,
    apiGatewayURL
  });

  const reduxStore = createReduxStore(reduxState, apiClient, enableWebpackHMR, enableReduxDevTools);

  loadableReady(() => {
    ReactDOM.hydrate(
      <Provider store={reduxStore}>
        <ApplicationContainer>
          <ApplicationRouter />
        </ApplicationContainer>
      </Provider>,
      rootElement
    );
  });
});
