import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CheckoutActions from 'app/actions/CheckoutActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const CheckedOutPrintListContainer = loadable(() =>
  import('app/components/borrowing/checkouts/CheckedOutPrintList/CheckedOutPrintListContainer')
);

export function CheckedOutPrintListRoute() {
  return <CheckedOutPrintListContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [dispatch(CheckoutActions.fetchCheckouts({ accountId, ...params, ...query }))];
}

export default connectRoute(CheckedOutPrintListRoute, { getRouteData });
