import Immutable from 'immutable';

import AvailabilityDetailConstants from '../constants/AvailabilityDetailConstants';

const { OPEN_AVAILABILITY_DETAILS, CLOSE_AVAILABILITY_DETAILS } = AvailabilityDetailConstants;

const initialState = Immutable.Map({
  metadataId: null,
  open: false
});

export default function availabilityDetail(state = initialState, action) {
  switch (action.type) {
    case OPEN_AVAILABILITY_DETAILS: {
      return state.merge({
        metadataId: action.metadataId,
        open: true
      });
    }

    case CLOSE_AVAILABILITY_DETAILS: {
      return state.set('open', false);
    }

    default:
      return state;
  }
}
