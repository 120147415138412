import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { Info } from '@bibliocommons/deprecated-base-icons';
import { openPopUpWindow } from '@bibliocommons/utils-browser';
import './HelpLink.scss';

const HELP_LINK_WINDOW_NAME = 'helpLinkWindow';

const defaultText = (
  <ScreenReaderMessage>
    <FormattedMessage id="ada_learn_more_about_feature" />
  </ScreenReaderMessage>
);

function openHelpWindow(e, url) {
  e.preventDefault();
  openPopUpWindow({
    url,
    HELP_LINK_WINDOW_NAME,
    windowFeatures: {
      height: 600,
      width: 750
    }
  });
}

function HelpLink({
  className = '',
  page = '',
  currentLanguage = 'en-US',
  showIcon = true,
  children = defaultText,
  ...props
}) {
  const classNames = `cp-help-link ${className}`;
  const helpParts = page.split('#');
  let url = '';
  let formattedLocale = (loc => {
    switch (loc.toLowerCase()) {
      case 'fr-ca':
        return 'fr';
      case 'es-es':
        return 'es';
      case 'ja-jp':
        return 'ja';
      case 'ja-ja':
        return 'ja';
      case 'ru-ru':
        return 'ru';
      case 'zh-cn':
        return 'zh-hans';
      case 'zh-tw':
        return 'zh-hant';
      default:
        return '';
    }
  })(currentLanguage.toLowerCase());

  if (helpParts[0]) {
    url = helpParts[0].replace('_', '-');
  }

  if (url && helpParts[1]) {
    url = `${helpParts[0].replace('_', '-')}#${helpParts[1]}`;
  }

  if (formattedLocale) {
    formattedLocale = `/${formattedLocale}`;
  }

  if (url) {
    url = `/${url}`;
  }

  const href = `https://help.bibliocommons.com${formattedLocale}${url}`;

  return (
    <a
      onClick={e => openHelpWindow(e, href)}
      className={classNames}
      data-key="help-link"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {showIcon ? <Info /> : null}
      {children}
    </a>
  );
}

HelpLink.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  currentLanguage: PropTypes.string
};

export default HelpLink;
