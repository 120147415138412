import Immutable from 'immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  itemMatchesStatusFilter,
  getMaterialTypeCountsForStatus,
  getPresentStatusesFromSummary,
  getCountsByStatusFromSummary
} from '@bibliocommons/utils-borrowing';

import { HOLD_STATUS_TYPES } from 'app/constants/HoldsConstants';

import { selectBorrowingSummary } from './BorrowingSelector';
import { selectShelvesForCurrentUser } from './ShelfSelector';

const statusFilter = (state, props) => _.get(props, 'router.params.status');

const holdsById = state => state.getIn(['entities', 'holds']);
const currentHoldIds = state => state.getIn(['borrowing', 'holds', 'items'], Immutable.List());

const bibsById = state => state.getIn(['entities', 'bibs'], Immutable.Map());
const holdsSummary = state => selectBorrowingSummary(state, 'holds');
const inactiveHoldsSummary = state => selectBorrowingSummary(state, 'inactiveHolds');
const holdsTransactions = state => state.getIn(['borrowing', 'holds', 'holdsTransactions'], Immutable.Map());
const holdsStatus = state => state.getIn(['borrowing', 'holds'], Immutable.Map());
const pagination = state => state.getIn(['borrowing', 'holds', 'pagination'], Immutable.Map());
const sortOptionsState = state => state.getIn(['borrowing', 'holds', 'sortOptions'], Immutable.List());
const getQuotas = state => selectBorrowingSummary(state, 'holds').get('quotas', Immutable.Map());
const holdError = state => state.getIn(['borrowing', 'holds', 'holdError'], Immutable.Map());

const holdIsActive = hold => ![HOLD_STATUS_TYPES.CANCELLED, HOLD_STATUS_TYPES.EXPIRED].includes(hold.get('status'));

export const selectActiveHoldsByMetadataId = createSelector([holdsById], allHoldsById =>
  allHoldsById.filter(holdIsActive).groupBy(hold => hold.get('metadataId'))
);

export const selectHoldsWithBibsById = createSelector([holdsById, bibsById], (holds, bibs) =>
  holds.map(hold => hold && hold.set('bib', bibs.get(hold.get('metadataId'))))
);

export const selectActiveHolds = createSelector([holdsById], holds => holds.filter(hold => holdIsActive(hold)));

export const selectILSQuotas = createSelector(
  [getQuotas],
  quotas =>
    quotas
      .valueSeq()
      .toArray()
      .filter(x => x.get('type') === 'ILS')[0]
);

export const selectCurrentHolds = createSelector(
  [currentHoldIds, selectHoldsWithBibsById, bibsById, selectShelvesForCurrentUser],
  (holdIds, holdsWithBibs, bibs, shelfItems) =>
    holdIds
      .map(holdId => holdsWithBibs.get(holdId))
      .filter(hold => !!hold)
      .map(hold => {
        const isAlreadyOnShelf = shelfItems.some(item => item.get('metadataId') === hold.get('metadataId'));

        const canAddToShelf = !isAlreadyOnShelf && hold.get('bib');

        const actions = canAddToShelf ? hold.get('actions', Immutable.List()).push('addToShelf') : hold.get('actions');

        return hold.set('actions', actions);
      })
      .toList()
);

export const selectHoldsUnfilteredByMaterialType = createSelector([holdsById, statusFilter], (holds, selectedStatus) =>
  holds.filter(hold => itemMatchesStatusFilter(hold, selectedStatus)).toList()
);

export const selectSelectedStatus = createSelector([statusFilter], status => (status ? status.toUpperCase() : status));

export const selectMaterialTypeCounts = createSelector([holdsSummary, statusFilter], (summary, currentStatus) =>
  getMaterialTypeCountsForStatus(summary, currentStatus)
);

export const selectPresentStatuses = createSelector([holdsSummary], summary => getPresentStatusesFromSummary(summary));

export const selectHoldsQuotas = createSelector([holdsSummary], summary => summary.get('quotas', Immutable.List()));

export const selectCountsByStatus = createSelector([holdsSummary], summary => getCountsByStatusFromSummary(summary));

export const selectSortOptions = createSelector([sortOptionsState], sortOptions => sortOptions);

export const selectInactiveHoldCountsByStatus = createSelector([inactiveHoldsSummary], summary =>
  getCountsByStatusFromSummary(summary)
);

export const selectDataForStatus = (state, status) => state.getIn(['borrowing', 'holds', status], Immutable.Map());

export const selectHoldsForStatus = createSelector(
  [holdsById, bibsById, selectDataForStatus],
  (holds, bibs, holdsData) =>
    holdsData
      .get('holds', Immutable.List())
      .map(id => holds.get(id))
      .filter(hold => hold)
      .map(hold => hold.set('bib', bibs.get(hold.get('metadataId'))))
);

export const selectHoldsTransactions = createSelector([holdsTransactions], transactions => transactions);

export const selectHoldsStatus = createSelector([holdsStatus], status => status);

const inactiveHoldData = state => state.getIn(['borrowing', 'inactiveHolds'], Immutable.Map());

const getInactiveHoldsByStatus = status =>
  createSelector([holdsById, inactiveHoldData], (holds, inactiveHolds) =>
    inactiveHolds.getIn([status, 'holdIds'], Immutable.List()).map(holdId => holds.get(holdId))
  );

export const selectInactiveHoldData = createSelector([inactiveHoldData], data => data);

export const selectPagination = createSelector([pagination], page => page);

export const selectCancelledHolds = getInactiveHoldsByStatus('cancelled');
export const selectExpiredHolds = getInactiveHoldsByStatus('expired');

export const selectHoldError = createSelector([holdError], error => error);

export default {
  selectActiveHoldsByMetadataId,
  selectHoldsWithBibsById,
  selectCurrentHolds,
  selectHoldsUnfilteredByMaterialType,
  selectSelectedStatus,
  selectMaterialTypeCounts,
  selectPresentStatuses,
  selectHoldsQuotas,
  selectCountsByStatus,
  selectDataForStatus,
  selectHoldsForStatus,
  selectActiveHolds,
  selectPagination,
  selectSortOptions,
  selectHoldsTransactions,
  selectHoldsStatus,
  selectInactiveHoldData,
  selectCancelledHolds,
  selectExpiredHolds,
  selectHoldError
};
