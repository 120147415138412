import Immutable from 'immutable';
import { combineReducers } from 'redux-immutablejs';

import eventsSearch from './EventsSearchReducer';
import myEvents from './MyEventsReducer';
import eventsBrochure from './EventsBrochureReducer';

export default combineReducers(
  Immutable.Map({
    eventsSearch,
    myEvents,
    eventsBrochure
  })
);
