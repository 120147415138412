import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { animationFrameScheduler, of, timer } from 'rxjs';
import { map, concat, takeWhile } from 'rxjs/operators';
import { bindAll } from '@bibliocommons/utils-react';
import { SecondaryButton } from '@bibliocommons/deprecated-base-buttons';
import { Arrows } from '@bibliocommons/deprecated-base-icons';

import '../styles/BackToTop.scss';

export default class BackToTop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };

    this.lastScrollPosition = 0;
    this.animationFrame = null;

    bindAll(this);
  }

  componentDidMount() {
    this.intervalScrollChecker = setInterval(() => {
      this.animationFrame = window.requestAnimationFrame(this.handleScroll);
    }, this.props.scrollWaitTime);
  }

  componentWillUnmount() {
    if (this.intervalScrollChecker) {
      window.clearInterval(this.intervalScrollChecker);
    }

    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  handleClick() {
    const initialScrollY = window.pageYOffset || document.documentElement.scrollTop;
    const frameRate = 1000 / 60; // 60 FPS
    const { animationDuration } = this.props;

    const easingFnc = (
      time,
      begin,
      change,
      duration // Cubic out
    ) => change * ((time = time / duration - 1) * time * time + 1) + begin; // eslint-disable-line

    timer(0, frameRate, animationFrameScheduler)
      .pipe(
        map(i => i * frameRate),
        map(time => easingFnc(time, initialScrollY, -initialScrollY, animationDuration)),
        concat(of(0)),
        takeWhile(val => val > 0)
      )
      .subscribe(val => window.scroll(0, val));
  }

  handleScroll() {
    const startPos = this.lastScrollPosition;
    const endPos = window.pageYOffset;
    const scrollDifference = Math.abs(endPos - startPos);
    const scrolledFarEnough = Math.min(endPos, scrollDifference) > this.props.scrollUpwardsThreshold;

    if (scrolledFarEnough) {
      const amountNeededToScroll = window.innerHeight * (this.props.percentViewportScrolled / 100.0);
      const visible = endPos > amountNeededToScroll && endPos < startPos;
      this.setState({
        visible
      });
    }

    this.lastScrollPosition = endPos;
  }

  render() {
    const classnames = cn('cp-back-to-top', {
      'scrolled-visible': this.state.visible
    });
    return (
      <SecondaryButton className={classnames} handleClick={this.handleClick}>
        <Arrows.Medium.Up color="#fff" /> <FormattedMessage id="back_to_top" />
      </SecondaryButton>
    );
  }
}

BackToTop.propTypes = {
  // How long to wait for scrolling before calculating if the button should show
  scrollWaitTime: PropTypes.number,

  // How far down the page the user has to have already scrolled before the button is allowed to be shown
  // This is relative to the viewport, so if the value is 50%,
  // the user must have scrolled 234px on a 468px height device
  percentViewportScrolled: PropTypes.number,

  // How many pixels the user must scroll upwards before the button is shown
  scrollUpwardsThreshold: PropTypes.number,

  // Duration of the scroll to top animation
  animationDuration: PropTypes.number
};

BackToTop.defaultProps = {
  scrollWaitTime: 50,
  percentViewportScrolled: 40,
  scrollUpwardsThreshold: 20,
  animationDuration: 700
};
