function fetchAvailability(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/availability`;
  return apiClient.get(route);
}

function fetchUserTransactions(metadataIds, userId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/transactions`;
  return apiClient.get(route, { query: { metadataIds, userId } });
}

function fetchDiscovery(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/discovery`;
  return apiClient.get(route);
}

function fetchEvaluation(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/evaluation`;
  return apiClient.get(route);
}

function fetchUGC(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/ugc`;
  return apiClient.get(route);
}

function fetchHoldableItems(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/holdableitems`;
  return apiClient.get(route);
}

function fetchMarcFields({ metadataId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/marcfields`;
  return apiClient.get(route);
}

function getReviews({ metadataId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/reviews`;
  return apiClient.get(route);
}

function getRecommendations(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/${metadataId}/recommendations`;
  return apiClient.get(route);
}

export default {
  fetchAvailability,
  fetchUserTransactions,
  fetchDiscovery,
  fetchEvaluation,
  fetchUGC,
  fetchHoldableItems,
  fetchMarcFields,
  getReviews,
  getRecommendations
};
