import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';
import CommentsActions from 'app/actions/CommentsActions';

const AllCommentsPageContainer = loadable(() =>
  import('app/components/bibs/subpages/AllCommentsPage/AllCommentsPageContainer')
);

export function AllCommentsPageRoute() {
  return <AllCommentsPageContainer />;
}

function getRouteData({ dispatch, query, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(CommentsActions.getComments({ metadataId: params.metadataId, page: query.page }))
  ];
}

export default connectRoute(AllCommentsPageRoute, { getRouteData });
