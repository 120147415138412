function fetchDigitalFormats({ metadataId, libraryId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/digital_formats/${libraryId}/${metadataId}`;
  return apiClient.get(route);
}

function digitalCheckout({ metadataId, accountId, format = null, duration = null }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts`;
  return apiClient.post(route, {
    metadataId,
    accountId,
    format,
    duration
  });
}

function lockInDigitalFormat({ metadataId, accountId, checkoutId, format }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts/${checkoutId}`;
  return apiClient.put(route, {
    metadataId,
    accountId,
    format
  });
}

function checkInDigitalBib({ metadataId, checkoutId, accountId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts/${checkoutId}`;
  return apiClient.delete(route, {
    metadataId,
    accountId
  });
}

function getCheckouts(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts`;
  return apiClient.get(route, { query });
}

function renewCheckouts({ checkoutIds, messagingId, accountId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts`;
  return apiClient.patch(route, {
    messagingId,
    accountId,
    checkoutIds,
    renew: true
  });
}

function quickRenewCheckouts({ accountId, status, materialType, sort }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts/renewal`;
  return apiClient.patch(route, { accountId, status, materialType, sort });
}

function getFulfilment({ accountId, checkoutId }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/checkouts/${checkoutId}/fulfilment`;
  return apiClient.get(route, { query: { accountId } });
}

export default {
  fetchDigitalFormats,
  digitalCheckout,
  lockInDigitalFormat,
  checkInDigitalBib,
  getCheckouts,
  renewCheckouts,
  quickRenewCheckouts,
  getFulfilment
};
