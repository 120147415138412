import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';

import { branchShape, childrenShape } from '@bibliocommons/bc-prop-types';
import { Dropdown } from '@bibliocommons/deprecated-base-dropdown';
import './BranchDropdown.scss';

export default class BranchDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.id = uniqueId('branch-dropdown-');
  }

  getOptionsForDropdown() {
    return this.props.branches
      .map(branch => ({
        label: branch.get('name', ''),
        value: branch.get('code', '')
      }))
      .toIndexedSeq()
      .toJS();
  }

  render() {
    return (
      <div
        className={cn('cp-branch-dropdown', {
          'is-loading': this.props.isLoading
        })}
      >
        <label id={`${this.id}-label`} htmlFor={this.id}>
          {this.props.children}
        </label>
        <Dropdown
          dataKey="branch-dropdown"
          selected={this.props.selectedBranchName}
          disabled={this.props.disabled}
          dropdownMenuId={this.id}
          options={this.getOptionsForDropdown()}
          isLoading={this.props.isLoading}
          className={this.props.buttonClassName}
          ButtonTag={this.props.ButtonTag}
          handleSelect={this.props.handleSelect}
          testId="single-click-holds-location"
        />
      </div>
    );
  }
}

BranchDropdown.propTypes = {
  children: childrenShape,
  branches: ImmutablePropTypes.listOf(branchShape),
  handleSelect: PropTypes.func,
  selectedBranchName: PropTypes.string.isRequired,
  ButtonTag: PropTypes.func,
  buttonClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

BranchDropdown.defaultProps = {
  buttonClassName: 'btn-block'
};
