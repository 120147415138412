import DrawerConstants from '../constants/DrawerConstants';

function toggle() {
  return {
    type: DrawerConstants.TOGGLE
  };
}

export default {
  toggle
};
