import AccountApi from '../api/AccountApi';
import AccountConstants from '../constants/AccountConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';

export function updateSingleClickHoldsSettings(accountId, enabled, branchId) {
  return {
    types: [
      AccountConstants.UPDATE_SINGLE_CLICK_HOLDS_REQUEST,
      AccountConstants.UPDATE_SINGLE_CLICK_HOLDS_SUCCESS,
      AccountConstants.UPDATE_SINGLE_CLICK_HOLDS_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => AccountApi.updateSingleClickHoldsSettings(accountId, enabled, branchId, apiClient)
  };
}

export function updateBorrowingHistorySetting(accountId, enabled) {
  return {
    types: [
      AccountConstants.UPDATE_BORROWING_HISTORY_REQUEST,
      AccountConstants.UPDATE_BORROWING_HISTORY_SUCCESS,
      AccountConstants.UPDATE_BORROWING_HISTORY_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => AccountApi.updateBorrowingHistorySetting(accountId, enabled, apiClient),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('message_successful_enable_borrowing_history')
    })
  };
}
export default {
  updateSingleClickHoldsSettings,
  updateBorrowingHistorySetting
};
