import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('ACCOUNT', {
  UPDATE_SINGLE_CLICK_HOLDS_REQUEST: null,
  UPDATE_SINGLE_CLICK_HOLDS_SUCCESS: null,
  UPDATE_SINGLE_CLICK_HOLDS_FAILURE: null,
  UPDATE_BORROWING_HISTORY_REQUEST: null,
  UPDATE_BORROWING_HISTORY_SUCCESS: null,
  UPDATE_BORROWING_HISTORY_FAILURE: null
});
