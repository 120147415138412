import loadable from '@loadable/component';
import React from 'react';

import { getListById } from 'app/actions/ListActions';
import { ITEMS_PER_PAGE_LIMIT } from 'app/constants/list';
import connectRoute from 'app/routes/connectRoute';

const ListPageContainer = loadable(() => import('app/components/list/ListPageContainer'));

const ListPageRoute = () => {
  return <ListPageContainer />;
};

function getRouteData({ dispatch, params, state }) {
  const { listId } = params;
  const subdomain = state.getIn(['app', 'libraryDomain']);

  return [dispatch(getListById({ itemsPerPageLimit: ITEMS_PER_PAGE_LIMIT, listId, subdomain }))];
}

export default connectRoute(ListPageRoute, { getRouteData });
