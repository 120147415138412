import keyMirror from 'keymirror';
import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const HOLD_DATE_FORMAT = 'YYYY-MM-DD';

export const HOLD_STATUS_TYPES = {
  NOT_YET_AVAILABLE: 'NOT_YET_AVAILABLE',
  NOT_READY: 'NOT_READY',
  IN_TRANSIT: 'IN_TRANSIT',
  READY_SOON: 'READY_SOON',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  SUSPENDED: 'SUSPENDED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED'
};

export const WORKFLOW_VIEWS = keyMirror({
  PHYSICAL_HOLD: null,
  DIGITAL_HOLD: null,
  DIGITAL_CHECKOUT: null,
  DIGITAL_MISSING_FORMAT: null,
  CANCEL_HOLD: null,
  ITEM_LEVEL_HOLD: null,
  PAUSE_HOLD: null
});

export default createReduxConstants('HOLDS', {
  PLACE_HOLD_REQUEST: null,
  PLACE_HOLD_SUCCESS: null,
  PLACE_HOLD_FAILURE: null,
  CANCEL_HOLD_REQUEST: null,
  CANCEL_HOLD_SUCCESS: null,
  CANCEL_HOLD_FAILURE: null,
  FETCH_ITEM_GROUPS_REQUEST: null,
  FETCH_ITEM_GROUPS_SUCCESS: null,
  FETCH_ITEM_GROUPS_FAILURE: null,
  UPDATE_PICKUP_LOCATION: null,
  FETCH_HOLDS_REQUEST: null,
  FETCH_HOLDS_SUCCESS: null,
  FETCH_HOLDS_FAILURE: null,
  FETCH_CANCELLED_HOLDS_REQUEST: null,
  FETCH_CANCELLED_HOLDS_SUCCESS: null,
  FETCH_CANCELLED_HOLDS_FAILURE: null,
  FETCH_EXPIRED_HOLDS_REQUEST: null,
  FETCH_EXPIRED_HOLDS_SUCCESS: null,
  FETCH_EXPIRED_HOLDS_FAILURE: null,
  UPDATE_SINGLE_CLICK_HOLDS_REQUEST: null,
  UPDATE_SINGLE_CLICK_HOLDS_SUCCESS: null,
  UPDATE_SINGLE_CLICK_HOLDS_FAILURE: null,
  UPDATE_PAUSE_HOLDS_DATE_REQUEST: null,
  UPDATE_PAUSE_HOLDS_DATE_SUCCESS: null,
  UPDATE_PAUSE_HOLDS_DATE_FAILURE: null,
  UPDATE_PAUSE_HOLDS_REQUEST: null,
  UPDATE_PAUSE_HOLDS_SUCCESS: null,
  UPDATE_PAUSE_HOLDS_FAILURE: null,
  UPDATE_RESUME_HOLDS_REQUEST: null,
  UPDATE_RESUME_HOLDS_SUCCESS: null,
  UPDATE_RESUME_HOLDS_FAILURE: null,
  UPDATE_PICKUP_LOCATION_REQUEST: null,
  UPDATE_PICKUP_LOCATION_SUCCESS: null,
  UPDATE_PICKUP_LOCATION_FAILURE: null,
  UPDATE_HOLDS_EXPIRY_REQUEST: null,
  UPDATE_HOLDS_EXPIRY_SUCCESS: null,
  UPDATE_HOLDS_EXPIRY_FAILURE: null,
  OPEN_ITEM_LEVEL_HOLDS: null,
  CLOSE_ITEM_LEVEL_HOLDS: null
});
