import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import Footer from './Footer';

function mapStateToProps(state) {
  const library = selectCurrentLibrary(state);
  return {
    footer: state.getIn(['templates', 'footer']),
    appVersion: state.getIn(['app', 'appVersion']),
    cmsEnabled: library.get('cmsEnabled')
  };
}

export class FooterContainer extends React.PureComponent {
  render() {
    if (this.props.footer) {
      return <Footer {...this.props} />;
    }

    return null;
  }
}

FooterContainer.propTypes = {
  appVersion: PropTypes.string.isRequired,
  cmsEnabled: PropTypes.bool,
  footer: PropTypes.string
};

export default connect(mapStateToProps)(FooterContainer);
