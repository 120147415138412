import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import './BorrowingStatusIcon.scss';

export default function BorrowingStatusIcon({ className, status, icon, withBackground, renderLabel }) {
  const normalizedStatus = status && status.toLowerCase();
  const classNames = cn(className, 'cp-borrowing-status-icon', normalizedStatus, { 'with-background': withBackground });

  return (
    <div className={classNames}>
      {icon}
      {renderLabel && <span className="status-name">{renderLabel()}</span>}
    </div>
  );
}

BorrowingStatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  icon: PropTypes.node,
  withBackground: PropTypes.bool,
  renderLabel: PropTypes.func
};
