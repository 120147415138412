import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { childrenShape } from '@bibliocommons/bc-prop-types';

import './TitleWithCount.scss';

function renderScreenReaderCount(count, screenReaderCount) {
  if (count || count === 0 || screenReaderCount) {
    const content = screenReaderCount || <FormattedMessage id="label_number_of_items" values={{ count }} />;
    return <ScreenReaderMessage>, {content}.</ScreenReaderMessage>;
  }

  return null;
}

export default function TitleWithCount({ title, className, count, children, screenReaderCount }) {
  return (
    <div className={cn('cp-title-with-count', className)}>
      {title}
      <div className="count-wrapper">
        <div className="count" aria-hidden="true">
          {count}
        </div>
        {renderScreenReaderCount(count, screenReaderCount)}
        {children}
      </div>
    </div>
  );
}

TitleWithCount.propTypes = {
  title: PropTypes.node,
  children: childrenShape,
  className: PropTypes.string,
  screenReaderCount: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.number, PropTypes.string])
};
