function updateSingleClickHoldsSettings(accountId, enabled, branchId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/accounts/${accountId}/settings/singleClickHolds`;
  return apiClient.put(route, { enabled, branchId });
}

function updateBorrowingHistorySetting(accountId, enabled, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/accounts/${accountId}/settings/borrowingHistorySetting`;
  return apiClient.put(route, { enabled });
}

export default {
  updateSingleClickHoldsSettings,
  updateBorrowingHistorySetting
};
