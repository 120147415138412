import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getBrowse = state => state.get('browse', Immutable.Map());

export const selectBrowseConfig = createSelector([getBrowse], browse => browse.get('config', Immutable.Map()));
export const selectIsFetching = createSelector([getBrowse], browse => browse.get('isFetching', false));

export default {
  selectBrowseConfig,
  selectIsFetching
};
