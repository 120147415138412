import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getLocalBranch = state => state.getIn(['app', 'localBranch']);
const hiddenNotices = state => state.getIn(['ui', 'notices', 'hidden'], Immutable.Set());

export const selectHiddenNotices = createSelector([hiddenNotices, getLocalBranch], (hidden, localBranch) =>
  localBranch ? Immutable.Set() : Immutable.Set(hidden)
);

export default {
  selectHiddenNotices
};
