import Immutable from 'immutable';
import CheckoutApi from '../api/CheckoutApi';
import CheckoutConstants from '../constants/CheckoutConstants';
import { WORKFLOW_VIEWS } from '../constants/HoldsConstants';
import { ITEM_TRANSACTION_WORKFLOW } from '../constants/WorkflowConstants';
import WorkflowActions from './WorkflowActions';
import composeSuccessMessaging from '../helpers/messaging/composeSuccessMessaging';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';
import composeGlobalFailureMessaging from '../helpers/messaging/composeGlobalFailureMessaging';
import composeMessagingForBatchAction from '../helpers/borrowing/composeMessagingForBatchAction';
import addFieldToResponseAnalytics from '../helpers/analytics/addFieldToResponseAnalytics';
import { selectBibEntities } from '../selectors/EntitiesSelector';

export function digitalCheckout({
  accountId,
  metadataId,
  messagingId,
  workflowId,
  title,
  format = null,
  duration = null,
  holdId,
  postSuccess,
  reciprocalRank
}) {
  return {
    workflowId: workflowId || metadataId,
    postSuccess,
    messagingId,
    payload: {
      id: metadataId
    },
    types: [
      CheckoutConstants.DIGITAL_CHECKOUT_REQUEST,
      CheckoutConstants.DIGITAL_CHECKOUT_SUCCESS,
      CheckoutConstants.DIGITAL_CHECKOUT_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, client) =>
      CheckoutApi.digitalCheckout(
        {
          metadataId,
          accountId,
          format: format && format.get('name'),
          duration
        },
        client
      ),
    apiSuccess: response => {
      const augmentedResponse = reciprocalRank
        ? {
            payload: addFieldToResponseAnalytics(response, 'reciprocalRank', reciprocalRank)
          }
        : {};

      return {
        ...augmentedResponse,
        deletedEntities: holdId && [`holds.${holdId}`],
        messaging: messagingId
          ? composeSuccessMessaging(messagingId, 'message_digital_item_checkout', { values: { title } })
          : composeGlobalSuccessMessaging('message_digital_item_checkout', {
              values: { title }
            })
      };
    },
    apiFailure: response => ({
      messaging: composeGlobalFailureMessaging(response.error)
    })
  };
}

export function fetchDigitalFormats(metadataId) {
  return {
    payload: {
      id: metadataId
    },
    types: [
      CheckoutConstants.DIGITAL_FORMATS_REQUEST,
      CheckoutConstants.DIGITAL_FORMATS_SUCCESS,
      CheckoutConstants.DIGITAL_FORMATS_FAILURE
    ],
    apiRequest: (state, client) =>
      CheckoutApi.fetchDigitalFormats({ libraryId: state.getIn(['library', 'id']), metadataId }, client)
  };
}

export function digitalCheckIn({ accountId, metadataId, messagingId, checkoutId, postSuccess }) {
  return {
    checkoutId,
    messagingId,
    postSuccess,
    payload: {
      id: metadataId
    },
    types: [
      CheckoutConstants.DIGITAL_CHECK_IN_REQUEST,
      CheckoutConstants.DIGITAL_CHECK_IN_SUCCESS,
      CheckoutConstants.DIGITAL_CHECK_IN_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiFailure: response => ({
      messaging: composeGlobalFailureMessaging(response.error)
    }),
    apiRequest: (_state, client) => CheckoutApi.checkInDigitalBib({ metadataId, accountId, checkoutId }, client),
    apiSuccess: () => ({
      deletedEntities: [`checkouts.${checkoutId}`],
      messaging: messagingId
        ? composeSuccessMessaging(messagingId, 'message_digital_early_return_successful')
        : composeGlobalSuccessMessaging('message_digital_early_return_successful')
    })
  };
}

export function fetchCheckouts({ accountId, size = 25, status, page = 1, sort = 'status', materialType }) {
  return {
    types: [
      CheckoutConstants.FETCH_CHECKOUTS_REQUEST,
      CheckoutConstants.FETCH_CHECKOUTS_SUCCESS,
      CheckoutConstants.FETCH_CHECKOUTS_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) =>
      CheckoutApi.getCheckouts(
        {
          accountId,
          size,
          status: status && status.toUpperCase(),
          page,
          sort,
          materialType
        },
        apiClient
      )
  };
}

export function startDigitalMissingFormatSelection(workflowId, metadataId, messagingId) {
  return WorkflowActions.updateWorkflow(
    workflowId,
    ITEM_TRANSACTION_WORKFLOW,
    Immutable.Map({
      metadataId,
      messagingId,
      step: WORKFLOW_VIEWS.DIGITAL_MISSING_FORMAT
    }),
    true,
    true
  );
}

export function startDigitalCheckout(workflowId, metadataId, messagingId) {
  return WorkflowActions.updateWorkflow(
    workflowId,
    ITEM_TRANSACTION_WORKFLOW,
    Immutable.Map({
      metadataId,
      messagingId,
      step: WORKFLOW_VIEWS.DIGITAL_CHECKOUT
    }),
    true,
    true
  );
}

export function lockInFormat({ accountId, checkoutId, format, messagingId, metadataId, workflowId, postSuccess }) {
  return {
    postSuccess,
    workflowId,
    messagingId,
    payload: {
      id: metadataId
    },
    types: [
      CheckoutConstants.DIGITAL_FORMAT_LOCK_REQUEST,
      CheckoutConstants.DIGITAL_FORMAT_LOCK_SUCCESS,
      CheckoutConstants.DIGITAL_FORMAT_LOCK_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, client) =>
      CheckoutApi.lockInDigitalFormat({ metadataId, accountId, checkoutId, format }, client),
    apiSuccess: (response, store) => {
      const bibs = selectBibEntities(store.getState());
      const values = {
        title: bibs.getIn([metadataId, 'briefInfo', 'title'], '')
      };

      return {
        messaging: messagingId
          ? composeSuccessMessaging(messagingId, 'download_ready', { values })
          : composeGlobalSuccessMessaging('download_ready', { values })
      };
    }
  };
}

const getRenewMessaging = ({ checkoutIds, response, moreDetails }) => {
  const { failures } = response;
  return composeMessagingForBatchAction({
    total: checkoutIds.length,
    failures,
    successId: 'message_successful_renew',
    mixedId: 'message_mixed_renew',
    failureId: 'message_failure_renew',
    values: { moreDetails },
    useFormattedMessage: true
  });
};

export function renew({ accountId, checkoutIds, moreDetails }) {
  return {
    types: [CheckoutConstants.RENEW_REQUEST, CheckoutConstants.RENEW_SUCCESS, CheckoutConstants.RENEW_FAILURE],
    checkoutIds,
    apiRequest: (state, client) =>
      CheckoutApi.renewCheckouts(
        {
          accountId,
          checkoutIds
        },
        client
      ),
    apiSuccess: response => ({
      messaging: getRenewMessaging({ checkoutIds, response, moreDetails })
    })
  };
}

export function quickRenew({ accountId, size = 50, status, materialType, sort, moreDetails }) {
  return {
    types: [
      CheckoutConstants.QUICK_RENEW_REQUEST,
      CheckoutConstants.QUICK_RENEW_SUCCESS,
      CheckoutConstants.QUICK_RENEW_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) =>
      CheckoutApi.quickRenewCheckouts(
        {
          accountId,
          size,
          status,
          materialType,
          sort
        },
        apiClient
      ),
    apiSuccess: response => ({
      messaging: getRenewMessaging({
        checkoutIds: response.borrowing.checkouts.items,
        response,
        moreDetails
      })
    })
  };
}

export function getFulfilment({ accountId, checkoutId }) {
  return {
    types: [
      CheckoutConstants.GET_FULFILMENT_REQUEST,
      CheckoutConstants.GET_FULFILMENT_SUCCESS,
      CheckoutConstants.GET_FULFILMENT_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => CheckoutApi.getFulfilment({ accountId, checkoutId }, apiClient)
  };
}

export default {
  digitalCheckout,
  digitalCheckIn,
  fetchDigitalFormats,
  fetchCheckouts,
  lockInFormat,
  startDigitalMissingFormatSelection,
  startDigitalCheckout,
  renew,
  quickRenew,
  getFulfilment
};
