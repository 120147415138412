import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const SEARCH_ORIGIN_QUERY_PARAM = 'origin';

export default createReduxConstants('ANALYTICS', {
  FETCH_DATALAYER_REQUEST: null,
  FETCH_DATALAYER_SUCCESS: null,
  FETCH_DATALAYER_FAILURE: null,
  SET_CONTEXT: null
});
