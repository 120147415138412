import MessagesApi from '../api/MessagesApi';
import MessagesConstants from '../constants/MessagesConstants';

export function fetchMessages({ accountId }) {
  return {
    types: [
      MessagesConstants.FETCH_MESSAGES_REQUEST,
      MessagesConstants.FETCH_MESSAGES_SUCCESS,
      MessagesConstants.FETCH_MESSAGES_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => MessagesApi.getMessages({ accountId }, apiClient)
  };
}

export default {
  fetchMessages
};
