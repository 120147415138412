import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import './BorrowingStatus.scss';

/**
 * BorrowingStatus is a component that is used for
 * encapsulating common styles for the statuses
 */

export default function BorrowingStatus({ children, className = '', currentSection, to }) {
  const classes = cn('cp-borrowing-status', 'list-group-item', className, currentSection ? 'current' : 'stale-state');
  if (currentSection) {
    return <div className={classes}>{children}</div>;
  }

  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  );
}

BorrowingStatus.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  currentSection: PropTypes.bool
};
