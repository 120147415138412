import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Immutable from 'immutable';
import { catalogBibShape } from '@bibliocommons/bc-prop-types';
import JacketCover from '@bibliocommons/shared-jacket-cover';
import ScreenReaderItemInfo from '@bibliocommons/shared-screen-reader-item-info';
import BibTitle from 'app/components/bibs/shared/BibTitle';
import BibAuthors from 'app/components/bibs/shared/BibAuthors';
import BibLink from 'app/components/shared/BibLink';

import './BibSlide.scss';

export default function BibSlide({ className, catalogBib, size, renderAttribution, handleClick }) {
  function renderScreenReaderInfo() {
    const authors = catalogBib
      .getIn(['brief', 'creators'], Immutable.List())
      .map(author => author.get('otherScriptFullName') || author.get('fullName'));
    const title = catalogBib.getIn(['brief', 'otherScriptTitle']) || catalogBib.getIn(['brief', 'title']);
    return <ScreenReaderItemInfo title={title} authors={authors} />;
  }

  function renderBibInfo() {
    return (
      <div className="bib-info">
        <BibTitle mainTagName="div" catalogBib={catalogBib} truncateCount={20} showSubTitle={false} />
        <BibAuthors catalogBib={catalogBib} renderAsLink={false} truncateCount={20} />
      </div>
    );
  }

  function renderTextCover() {
    return (
      <div className="text-cover" aria-hidden>
        <div className="text-cover-content">{renderBibInfo()}</div>
      </div>
    );
  }

  return (
    <div className={cn('cp-bib-slide', className)}>
      <BibLink
        metadataId={catalogBib.get('metadataId')}
        handleClick={handleClick}
        className="bib-slide-link"
        dataKey="bib-slide-link"
      >
        {renderScreenReaderInfo()}
        <JacketCover size={size} renderDefault={renderTextCover} src={catalogBib.getIn(['brief', 'coverImage'])} />
        <div className="bib-slide-hover" aria-hidden>
          {renderBibInfo()}
        </div>
      </BibLink>
      {renderAttribution?.()}
    </div>
  );
}

BibSlide.propTypes = {
  className: PropTypes.string,
  catalogBib: catalogBibShape.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  renderAttribution: PropTypes.func,
  handleClick: PropTypes.func
};

BibSlide.defaultProps = {
  size: 'medium'
};
