import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';

import BorrowingStatus from '../../shared/BorrowingStatus';
import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';

export default class AccountMessagesStatus extends React.PureComponent {
  render() {
    const { currentSection } = this.props;

    return (
      <BorrowingStatus currentSection={currentSection} to="/v2/accountmessages">
        <SidebarCompactTitle
          title={<FormattedMessage id="section_title_messages" />}
          count={this.props.messages.size}
          selected={currentSection}
        />
      </BorrowingStatus>
    );
  }
}

AccountMessagesStatus.propTypes = {
  currentSection: PropTypes.bool,
  messages: ImmutablePropTypes.map.isRequired
};
