import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import RecentlyReturnedActions from 'app/actions/RecentlyReturnedActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const RecentlyReturnedPrintListContainer = loadable(() =>
  import('app/components/borrowing/recentlyreturned/RecentlyReturnedPrintList/RecentlyReturnedPrintListContainer')
);

export function RecentlyReturnedPrintListRoute() {
  return <RecentlyReturnedPrintListContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [dispatch(RecentlyReturnedActions.fetchReturnHistory({ accountId, ...params, ...query }))];
}

export default connectRoute(RecentlyReturnedPrintListRoute, { getRouteData });
