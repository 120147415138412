import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindAll } from '@bibliocommons/utils-react';
import { branchShape, singleClickHoldsSettingsShape } from '@bibliocommons/bc-prop-types';
import HelpLinkContainer from 'app/components/shared/HelpLink/HelpLinkContainer';
import SingleClickHoldsStatusButton from '../SingleClickHoldsStatusButton';

import Panel from '../../../shared/Panel';
import SingleClickHoldsForm from '../SingleClickHoldsForm';

import './SingleClickHoldsPanel.scss';

export default class SingleClickHoldsPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false
    };
    bindAll(this);
  }

  getCurrentBranchName() {
    const currentBranchCode = this.props.singleClickHoldsSettings.get('branchId');
    const branch = this.props.branches.find(b => b.get('code') === currentBranchCode);
    return branch ? branch.get('name') : '';
  }

  handleStatusButtonClick() {
    this.setState({ showForm: true });
  }

  handleCancel() {
    this.setState({ showForm: false });
  }

  handleSubmit({ branchCode }) {
    this.props.accountActions.updateSingleClickHoldsSettings(this.props.accountId, true, branchCode);

    this.setState({ showForm: false });
  }

  renderButton() {
    return (
      <div className="button-wrapper">
        <SingleClickHoldsStatusButton
          singleClickHoldsEnabled={this.props.singleClickHoldsSettings.get('enabled', false)}
          handleClick={this.handleStatusButtonClick}
        />
        <HelpLinkContainer page="010singleclick" />
      </div>
    );
  }

  renderForm() {
    return (
      <SingleClickHoldsForm
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
        branchCode={this.props.singleClickHoldsSettings.get('branchId')}
        branches={this.props.branches}
      />
    );
  }

  renderCurrentLocation() {
    const branch = <div className="branch-name">{this.getCurrentBranchName()}</div>;
    return (
      <div className="current-branch">
        <FormattedMessage id="single_click_holds_current_location" values={{ branch }} />
      </div>
    );
  }

  renderButtonAndLocation() {
    return (
      <div>
        {this.renderButton()}
        {this.props.singleClickHoldsSettings.get('enabled', false) && this.renderCurrentLocation()}
      </div>
    );
  }

  render() {
    if (this.props.branches.size > 0) {
      return (
        <Panel className="cp-single-click-holds-panel">
          {this.state.showForm ? this.renderForm() : this.renderButtonAndLocation()}
        </Panel>
      );
    }

    return null;
  }
}

SingleClickHoldsPanel.propTypes = {
  accountId: PropTypes.number,
  branches: ImmutablePropTypes.listOf(branchShape),
  singleClickHoldsSettings: singleClickHoldsSettingsShape,
  accountActions: PropTypes.objectOf(PropTypes.func)
};
