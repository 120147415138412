import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import { selectAppConfig } from 'app/selectors/AppSelector';

import './IEDiscontinuedMessage.scss';

export default function IEDiscontinuedMessage() {
  const appConfig = useSelector(selectAppConfig);
  const browser = appConfig.get('browser');
  const localBranch = appConfig.get('localBranch');

  if (browser === 'Internet Explorer' && !localBranch) {
    return (
      <div className="cp-ie-discontinued-message">
        <FormattedHTMLMessage id="IE_discontinued_message" />
      </div>
    );
  }
  return null;
}
