import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

/**
 * Creates constants to be used for Redux Actions
 *
 * @param {string} namespace The name of the reducer / action namespace
 * @param {object} obj The constants to be namespaced
 * @returns {object}
 */

export default function createReduxConstants(namespace, obj) {
  if (typeof namespace !== 'string') {
    throw new Error('createReduxConstants must pass the namespace as the first argument');
  }
  if (!isPlainObject(obj)) {
    throw new Error('createReduxConstants must be passed an Object as the second argument.');
  }

  return mapValues(obj, (value, key) => `${namespace}/${key}`);
}
