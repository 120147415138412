import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { selectSingularUGCByUsers, selectSingularUGCForCurrentUser } from './UGCBaseSelector';
import { selectVideoEntities } from '../EntitiesSelector';

const getTransactions = state => state.getIn(['ugc', 'videosTransactions'], Immutable.Map());

export const selectVideosByUsers = selectSingularUGCByUsers('videos');

export const selectVideosTransactions = createSelector([getTransactions], transactions => transactions);

export const selectCurrentUserVideos = selectSingularUGCForCurrentUser('videos');

const getVideoIds = state => state.getIn(['ugc', 'videos', 'results'], Immutable.List());

// All videos
export const selectVideos = createSelector([getVideoIds, selectVideoEntities], (videoIds, videoEntities) =>
  videoIds.map(videoId => videoEntities.get(videoId.toString()))
);

const pagination = state => state.getIn(['ugc', 'videos', 'pagination'], Immutable.Map());

export const selectVideosPagination = createSelector([pagination], page => page);

export default {
  selectVideosByUsers,
  selectVideosTransactions,
  selectCurrentUserVideos,
  selectVideos,
  selectVideosPagination
};
