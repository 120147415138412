import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import '../styles/StaffTakeoverMessage.scss';

const StaffTakeoverMessage = ({ intl, name }) => (
  <div className="cp-staff-takeover-message">
    <h2>
      {intl.formatMessage(
        { id: 'staffTakeoverMessage' },
        {
          name
        }
      )}
      &nbsp;
      <a href="/user/reset_admin_session">{intl.formatMessage({ id: 'resetStaffSessionLink' })}</a>
    </h2>
  </div>
);

StaffTakeoverMessage.propTypes = {
  name: PropTypes.string.isRequired
};

export default injectIntl(StaffTakeoverMessage);
