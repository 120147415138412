import loadable from '@loadable/component';
import React from 'react';

import { createGlobalPaymentsOrder } from 'app/actions/FinesActions';
import connectRoute from 'app/routes/connectRoute';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const GlobalPaymentsPage = loadable(() => import('app/components/borrowing/fines/GlobalPaymentsPage'));

const FinesPayPageRoute = () => <GlobalPaymentsPage />;

const getRouteData = ({ dispatch, state, query }) => {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) {
    return [];
  }

  const billIds = query?.billIds?.split(',');

  return [dispatch(createGlobalPaymentsOrder({ accountId, billIds }))];
};

export default connectRoute(FinesPayPageRoute, { getRouteData });
