import Immutable from 'immutable';

import RelationshipsConstants from 'app/constants/RelationshipsConstants';

const initialState = Immutable.fromJS({
  isFetching: false,
  syncingFollowsByTargetUserId: {},
  syncingIgnoresByTargetUserId: {}
});

export default function relationships(state = initialState, action) {
  switch (action.type) {
    case RelationshipsConstants.CREATE_FOLLOW_FAILURE: {
      return state.deleteIn(['syncingFollowsByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.CREATE_FOLLOW_REQUEST: {
      return state.setIn(['syncingFollowsByTargetUserId', action.payload.targetUserId], true);
    }

    case RelationshipsConstants.CREATE_FOLLOW_SUCCESS: {
      return state.deleteIn(['syncingFollowsByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.CREATE_IGNORE_FAILURE: {
      return state.deleteIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.CREATE_IGNORE_REQUEST: {
      return state.setIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId], true);
    }

    case RelationshipsConstants.CREATE_IGNORE_SUCCESS: {
      return state.deleteIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.FETCH_RELATIONSHIPS_FAILURE: {
      return state.merge({
        isFetching: false
      });
    }

    case RelationshipsConstants.FETCH_RELATIONSHIPS_REQUEST: {
      return state.merge({
        isFetching: true
      });
    }

    case RelationshipsConstants.FETCH_RELATIONSHIPS_SUCCESS: {
      return state.merge({
        isFetching: false
      });
    }

    case RelationshipsConstants.REMOVE_FOLLOW_FAILURE: {
      return state.deleteIn(['syncingFollowsByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.REMOVE_FOLLOW_REQUEST: {
      return state.setIn(['syncingFollowsByTargetUserId', action.payload.targetUserId], true);
    }

    case RelationshipsConstants.REMOVE_FOLLOW_SUCCESS: {
      return state.deleteIn(['syncingFollowsByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.REMOVE_IGNORE_FAILURE: {
      return state.deleteIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId]);
    }

    case RelationshipsConstants.REMOVE_IGNORE_REQUEST: {
      return state.setIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId], true);
    }

    case RelationshipsConstants.REMOVE_IGNORE_SUCCESS: {
      return state.deleteIn(['syncingIgnoresByTargetUserId', action.payload.targetUserId]);
    }

    default:
      return state;
  }
}
