import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { selectUGCListByUsers, selectUGCByBibs } from './UGCBaseSelector';
import { selectQuotationEntities } from '../EntitiesSelector';

const getTransactions = state => state.getIn(['ugc', 'quotationsTransactions'], Immutable.Map());

export const selectQuotationsByUsers = selectUGCListByUsers('quotations');
export const selectQuotationsByBibs = selectUGCByBibs('quotations');

export const selectQuotationsTransactions = createSelector([getTransactions], transactions => transactions);

const getQuotationIds = state => state.getIn(['ugc', 'quotations', 'results'], Immutable.List());

// All quotations
export const selectQuotations = createSelector(
  [getQuotationIds, selectQuotationEntities],
  (quotationIds, quotationEntities) => quotationIds.map(quotationId => quotationEntities.get(quotationId.toString()))
);

const pagination = state => state.getIn(['ugc', 'quotations', 'pagination'], Immutable.Map());

export const selectQuotationsPagination = createSelector([pagination], page => page);

export default {
  selectQuotationsByUsers,
  selectQuotationsByBibs,
  selectQuotationsTransactions,
  selectQuotations,
  selectQuotationsPagination
};
