import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { TertiarySolidLink } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import './NavContentRow.scss';

export default function NavContentRow({ row, trackClick }) {
  const title = row.getIn(['config', 'title']);
  const links = row.getIn(['config', 'links']);

  function renderNavLinks(link, index) {
    const itemPosition = index + 1; // 1-based count for analytics
    const linkText = link.get('title');
    const linkUrl = link.get('url');

    return (
      <li className="nav-link" key={link.get('title')}>
        <TertiarySolidLink
          href={linkUrl}
          handleClick={() => trackClick({ itemPosition, linkText, linkUrl })}
          dataKey="nav-row-link"
          className="nav-row-link"
          size="large"
        >
          {linkText}
        </TertiarySolidLink>
      </li>
    );
  }

  return (
    <>
      <div className="cp-nav-row">
        <ScreenReaderMessage>
          <h2>{title}</h2>
        </ScreenReaderMessage>
        <ul className="nav-row-contents">{links.map(renderNavLinks)}</ul>
      </div>
    </>
  );
}

NavContentRow.propTypes = {
  row: ImmutablePropTypes.map.isRequired,
  trackClick: PropTypes.func.isRequired
};
