import React from 'react';
import loadable from '@loadable/component';
import { SEARCH_ORIGIN_QUERY_PARAM } from 'app/constants/AnalyticsConstants';
import connectRoute from 'app/routes/connectRoute';
import RedirectActions from 'app/actions/RedirectActions';
import SearchActions from 'app/actions/SearchActions';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';

const SearchContainer = loadable(() => import('app/components/search/Search/SearchContainer'));

export function SearchPageRoute() {
  return <SearchContainer />;
}

function getRouteData({ state, dispatch, location, prevLocation, query }) {
  const library = selectCurrentLibrary(state);
  const catalogSearch = state.getIn(['search', 'catalogSearch']);
  const view = catalogSearch.get('view');
  const searchType = catalogSearch.get('searchType');
  const searchQuery = catalogSearch.get('query');
  const isInitialSearch = !searchType || !searchQuery;

  // If `defaultSearchToOnlineBibs` is enabled, filter new searches (excluding
  // advanced searches), to display only online bibs by default.
  if (library.get('defaultSearchToOnlineBibs')) {
    const isNewSearch = isInitialSearch || searchQuery !== query.query;
    if (isNewSearch && query.query && !query.f_CIRC && query.searchType !== 'bl') {
      return [dispatch(RedirectActions.redirect(`${location.pathname}${location.search}&f_CIRC=ONLINE`))];
    }
  }

  // Shelf availability search
  // NOTE: The shelf availability endpoint is considered a little expensive.
  // For this reason, we only use the shelf availability endpoint only on the
  // initial search, when we don't know the searchType or search query.
  if (isInitialSearch && query.shelf) {
    return [dispatch(SearchActions.performShelfAvailabilitySearch({ ...query, view }))];
  }

  // Regular catalog search

  // We want to prevent an immediate re-fetch of the search results if
  // they were hydrated server-side AND this method was triggered because the origin param
  // was removed from the query.
  // In order to know if the data was hydrated server-side,
  // there is a flag set in the application state at `search.catalogSearch.shouldPreventImmediateRefetch`.
  // Once we have prevented a re-fetch, we must be sure to dispatch the action to set this value to false
  // so that no subsequent fetches will be prevented.
  const wasOriginRemoved = Boolean(new URLSearchParams(prevLocation?.search ?? '').get(SEARCH_ORIGIN_QUERY_PARAM));
  const shouldPreventImmediateRefetch =
    wasOriginRemoved && (state.getIn(['search', 'catalogSearch', 'shouldPreventImmediateRefetch']) ?? false);

  return shouldPreventImmediateRefetch
    ? [dispatch(SearchActions.setShouldPreventImmediateRefetch(false))]
    : [dispatch(SearchActions.performCatalogSearch({ query: searchQuery, searchType, ...query, view }))];
}

export default connectRoute(SearchPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100 // milliseconds
  }
});
