import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from 'moment';

export default function NextDueDate({ date }) {
  // MMM D
  return (
    <FormattedDate value={moment.utc(date).toDate()} month="short" day="2-digit">
      {formattedDate => <span className="cp-next-due-date">{formattedDate}</span>}
    </FormattedDate>
  );
}

NextDueDate.propTypes = {
  date: PropTypes.string
};
