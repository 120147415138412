import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import AvailabilityDetailActions from 'app/actions/AvailabilityDetailActions';
import CatalogBibsActions from 'app/actions/CatalogBibsActions';

const AvailabilityDetailOverlayContainer = loadable(() =>
  import('app/components/shared/AvailabilityDetailOverlay/AvailabilityDetailOverlayContainer')
);

export function AvailabilityDetailsPageRoute() {
  return <AvailabilityDetailOverlayContainer isHideCloseButton />;
}

function getRouteData({ dispatch, params }) {
  return [
    dispatch(CatalogBibsActions.fetchCatalogBib(params.metadataId)),
    dispatch(AvailabilityDetailActions.openAvailability(params.metadataId))
  ];
}

export default connectRoute(AvailabilityDetailsPageRoute, { getRouteData });
