function getLocalizedCatalogBib(metadataId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/catalogbibs/${metadataId}`;
  return apiClient.get(route);
}

function getCatalogBibs(metadataIds, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/catalogbibs`;
  return apiClient.get(route, { query: { metadataIds } });
}

export default {
  getLocalizedCatalogBib,
  getCatalogBibs
};
