import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export default createReduxConstants('SYSTEM_MESSAGES', {
  SYSTEM_MESSAGES_REQUEST: null,
  SYSTEM_MESSAGES_SUCCESS: null,
  SYSTEM_MESSAGES_ERROR: null,

  // UI toggle expanded state.
  TOGGLE_SYSTEM_MESSAGE: null
});
