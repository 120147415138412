import moment from 'moment';
import { setCookie } from '@bibliocommons/utils-browser';

import BorrowingConstants, { BORROWING_VIEW_COOKIE_NAME } from '../constants/BorrowingConstants';
import { getBorrowingSummaries } from '../api/BorrowingApi';

export function fetchSummaries({ accountId }) {
  return {
    types: [
      BorrowingConstants.FETCH_SUMMARIES_REQUEST,
      BorrowingConstants.FETCH_SUMMARIES_SUCCESS,
      BorrowingConstants.FETCH_SUMMARIES_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => getBorrowingSummaries(apiClient, { accountId })
  };
}

export function toggleBorrowingView(view) {
  const expires = moment()
    .add(10, 'years')
    .toDate();
  setCookie(BORROWING_VIEW_COOKIE_NAME, view, { expires });
  return {
    type: BorrowingConstants.TOGGLE_BORROWING_VIEW,
    view
  };
}

export default {
  fetchSummaries,
  toggleBorrowingView
};
