import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withRouter } from '@bibliocommons/utils-routing';
import { siteShape, routerShape } from '@bibliocommons/bc-prop-types';
import LibraryLogo from 'app/components/shared/LibraryLogo';
import Context from '@bibliocommons/context';

import './LinkableLibrary.scss';

export class LinkableLibrary extends React.PureComponent {
  getUrlForLink() {
    const { router, library, linked } = this.props;
    return linked
      ? `${library.get('baseUrl')}${router.location.pathname}${router.location.search}`
      : '/library_accounts';
  }

  getImageBaseUrl() {
    const { coreCdnHost } = this.context;
    return coreCdnHost || '';
  }

  renderStatusText() {
    return this.props.linked ? (
      <FormattedMessage id="account_linking_status_linked" />
    ) : (
      <FormattedMessage id="account_linking_status_unlinked" />
    );
  }

  renderLinkText() {
    return this.props.linked ? <FormattedMessage id="view_account" /> : <FormattedMessage id="connect_card" />;
  }

  renderLink() {
    return !this.props.withoutLinks && <a href={this.getUrlForLink()}>{this.renderLinkText()}</a>;
  }

  render() {
    return (
      <li className="cp-linkable-library">
        <LibraryLogo
          siteId={this.props.library.get('siteId')}
          imageName="account_linking_image"
          small
          circled
          baseUrl={this.getImageBaseUrl()}
        />
        <div className="library-info">
          <div className="library-name">{this.props.library.get('longName')}</div>
          <div className="status">{this.renderStatusText()}</div>
          <div>{this.renderLink()}</div>
        </div>
      </li>
    );
  }
}

LinkableLibrary.propTypes = {
  library: siteShape,
  linked: PropTypes.bool,
  router: routerShape.isRequired,
  withoutLinks: PropTypes.bool
};

LinkableLibrary.contextType = Context;

export default withRouter(LinkableLibrary);
