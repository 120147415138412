import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import './FormatInfo.scss';

const FormatInfo = ({ callNumber, edition, formatMessageId, languageMessageId, publicationDate }) => {
  const intl = useIntl();

  const formattedFormat = (formatMessageId && intl.formatMessage({ id: formatMessageId })) || '';
  const formattedLanguage = (languageMessageId && intl.formatMessage({ id: languageMessageId })) || '';
  const screenReaderMessage = [
    publicationDate ? `${formattedFormat}, ${publicationDate}` : formattedFormat,
    edition,
    formattedLanguage && `${intl.formatMessage({ id: 'LANGUAGE.field' })}: ${formattedLanguage}`,
    callNumber && `${intl.formatMessage({ id: 'CALL_NUMBER' })}: ${callNumber}`
  ]
    .filter(Boolean)
    .join('. ');

  return (
    <div className="cp-format-info">
      <span aria-hidden className="display-info">
        <span className="display-info-primary">
          {formattedFormat && formattedFormat}
          {publicationDate && ` - ${publicationDate}`}
          {edition && ` | ${edition}`}
          {formattedLanguage && ` |  ${formattedLanguage}`}
        </span>
        <span className="call-number">{callNumber}</span>
      </span>
      <ScreenReaderMessage>{screenReaderMessage}</ScreenReaderMessage>
    </div>
  );
};

FormatInfo.propTypes = {
  callNumber: PropTypes.string,
  edition: PropTypes.string,
  formatMessageId: PropTypes.string,
  languageMessageId: PropTypes.string,
  publicationDate: PropTypes.string
};

export default FormatInfo;
