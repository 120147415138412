import RecentlyReturnedApi from '../api/RecentlyReturnedApi';
import RecentlyReturnedConstants from '../constants/RecentlyReturnedConstants';

export function fetchReturnHistory(query) {
  return {
    types: [
      RecentlyReturnedConstants.FETCH_RETURN_HISTORY_REQUEST,
      RecentlyReturnedConstants.FETCH_RETURN_HISTORY_SUCCESS,
      RecentlyReturnedConstants.FETCH_RETURN_HISTORY_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => RecentlyReturnedApi.getRecentlyReturned(apiClient, query)
  };
}

export default {
  fetchReturnHistory
};
