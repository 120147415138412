import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { metadataIdFixer } from '@bibliocommons/utils-link';
import { bindAll } from '@bibliocommons/utils-react';
import { threeLetterToTwoLetterLangCodes } from '@bibliocommons/constants-languages';
import { truncateOptionsShape, bibBriefShape } from '@bibliocommons/bc-prop-types';
import { truncate } from '@bibliocommons/utils-string';

import './BibTitle.scss';

export class BibTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  focusOnTitle() {
    if (this._title) {
      this._title.focus();
    }
  }

  formatText(text) {
    const { truncateOptions } = this.props;
    return truncateOptions ? truncate(text, truncateOptions) : text;
  }

  handleClick() {
    if (typeof this.props.onBibTitleClick === 'function') {
      this.props.onBibTitleClick();
    }
  }

  renderScreenReaderMessage() {
    const { renderScreenReaderMessage, bib, intl } = this.props;
    const title = bib.get('multiscriptTitle') || bib.get('title');
    const format = intl.formatMessage({
      id: `FORMAT.${bib.get('format').toLowerCase()}`
    });
    const values = { title, format };

    if (renderScreenReaderMessage) {
      return renderScreenReaderMessage(values);
    }

    return <FormattedMessage id="ada_link_to_item_page" values={values} />;
  }

  renderTitle() {
    const { renderAsLink, target, bib } = this.props;
    const titleContent = bib.get('multiscriptTitle') || bib.get('title');
    const formattedTitleContent = this.formatText(titleContent);

    const lang = threeLetterToTwoLetterLangCodes[bib.get('primaryLanguage')] || 'en';
    const id = bib.get('id');

    if (renderAsLink) {
      return (
        <a
          href={`/item/show/${metadataIdFixer(id)}`}
          target={target}
          lang={lang}
          rel="noopener"
          ref={el => (this._title = el)}
          data-test-id={`bib-title-${id}`}
          data-key="bib-title"
          onAuxClick={this.handleClick}
          onClick={this.handleClick}
        >
          <span aria-hidden="true" className="title-content">
            {formattedTitleContent}
          </span>
          <ScreenReaderMessage>{this.renderScreenReaderMessage()}</ScreenReaderMessage>
        </a>
      );
    }

    return (
      <div lang={lang} data-test-id={`bib-title-${id}`} ref={el => (this._title = el)} tabIndex="-1">
        <span aria-hidden="true" className="title-content">
          {formattedTitleContent}
        </span>
        <ScreenReaderMessage>{titleContent}</ScreenReaderMessage>
      </div>
    );
  }

  renderSecondaryTitle() {
    const { bib } = this.props;
    const title = bib.get('title');
    const multiscriptTitle = bib.get('multiscriptTitle');

    if (title && multiscriptTitle) {
      return (
        <div className="secondary-title">
          <span aria-hidden="true">{this.formatText(title)}</span>
          <ScreenReaderMessage>{title}</ScreenReaderMessage>
        </div>
      );
    }

    return null;
  }

  render() {
    const { titleTag, renderIcon, bib, renderMultiscriptOnly } = this.props;
    const TitleTag = titleTag; // This needs to start with an uppercase letter since its a component

    return (
      <TitleTag className="cp-title">
        {this.renderTitle()}
        {renderIcon()}
        {!renderMultiscriptOnly && this.renderSecondaryTitle()}
        {bib.get('subtitle') && <span className="cp-subtitle">{bib.get('subtitle')}</span>}
      </TitleTag>
    );
  }
}

const injectIntlPropTypes = {};

BibTitle.defaultProps = {
  titleTag: 'h2',
  target: '_parent',
  renderAsLink: true,
  renderIcon: () => null
};

BibTitle.propTypes = {
  bib: bibBriefShape,
  titleTag: PropTypes.string,
  target: PropTypes.string,
  renderAsLink: PropTypes.bool,
  renderIcon: PropTypes.func,
  truncateOptions: truncateOptionsShape,
  renderScreenReaderMessage: PropTypes.func,
  onBibTitleClick: PropTypes.func,
  ...injectIntlPropTypes
};

export default injectIntl(BibTitle, { forwardRef: true });
