import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from 'app/components/layouts/MainLayout';
import AvailabilityDetailsPageRoute from './AvailabilityDetailsPageRoute';

const basePath = '/v2/availability/:metadataId';

export default (
  <Route path={basePath}>
    <MainLayout deprecated>
      <AvailabilityDetailsPageRoute />
    </MainLayout>
  </Route>
);
