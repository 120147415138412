import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { siteShape } from '@bibliocommons/bc-prop-types';
import { selectLinkedLibrariesById } from 'app/selectors/LibrarySelector';
import AccountSwitcher from './AccountSwitcher';

export class AccountSwitcherContainer extends React.PureComponent {
  render() {
    return <AccountSwitcher linkedLibrariesById={this.props.linkedLibrariesById} />;
  }
}

const mapStateToProps = state => ({
  linkedLibrariesById: selectLinkedLibrariesById(state)
});

AccountSwitcherContainer.propTypes = {
  linkedLibrariesById: ImmutablePropTypes.mapOf(siteShape)
};

export default connect(mapStateToProps)(AccountSwitcherContainer);
