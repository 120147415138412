import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import AccountMessagesActions from 'app/actions/MessagesActions';
import BorrowingActions from 'app/actions/BorrowingActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';

const AccountMessagesContainer = loadable(() =>
  import('app/components/borrowing/accountmessages/AccountMessages/AccountMessagesContainer')
);

export function AccountMessagesPageRoute() {
  return <AccountMessagesContainer />;
}

function getRouteData({ dispatch, state }) {
  const account = selectCurrentAccount(state);
  const library = selectCurrentLibrary(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  if (!library.get('accountMessages')) {
    return [dispatch(BorrowingActions.fetchSummaries({ accountId }))];
  }

  return [
    dispatch(BorrowingActions.fetchSummaries({ accountId })),
    dispatch(AccountMessagesActions.fetchMessages({ accountId }))
  ];
}

export default connectRoute(AccountMessagesPageRoute, { getRouteData });
