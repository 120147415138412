function getLists({ userId = null, metadataId = null, category, page, size, sort = null }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/lists`;
  return apiClient.get(route, { query: { userId, metadataId, category, page, size, sort } });
}

function createListItem({ listId, ...listItemData }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/lists/${listId}/items`;
  return apiClient.post(route, listItemData);
}

export default {
  getLists,
  createListItem
};
