import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import MessageIfExists from './MessageIfExists';

import './VersionMessage.scss';

export default function VersionMessage({ version }) {
  return (
    <div className="cp-version-message">
      <p className="powered-by">
        <MessageIfExists id="static_powered_by" component={FormattedHTMLMessage} />
      </p>
      <p className="product-version">
        NERF:
        {version}
      </p>
    </div>
  );
}

VersionMessage.displayName = 'VersionMessage';
VersionMessage.propTypes = {
  version: PropTypes.string.isRequired
};
