const deleteUser = ({ userId }, apiClient) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${userId}`;
  return apiClient.delete(route);
};

function fetchUser({ userId, ...query }, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/users/${userId}`;
  return apiClient.get(route, { query });
}

export default {
  deleteUser,
  fetchUser
};
