import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckmarkCircle, MinusCircled, PauseCircled, Truck } from '@bibliocommons/deprecated-base-icons';

import { HOLD_STATUS_TYPES } from 'app/constants/HoldsConstants';

import BorrowingStatusIcon from '../../shared/BorrowingStatusIcon';

import './HoldStatusIcon.scss';

const getIcon = status => {
  switch (status) {
    case HOLD_STATUS_TYPES.READY_FOR_PICKUP:
    case HOLD_STATUS_TYPES.READY_SOON:
      return <CheckmarkCircle />;
    case HOLD_STATUS_TYPES.IN_TRANSIT:
      return <Truck />;
    case HOLD_STATUS_TYPES.SUSPENDED:
      return <PauseCircled />;
    default:
      return <MinusCircled />;
  }
};

export default function HoldStatusIcon({ status, withBackground, withLabel }) {
  const label = status && <FormattedMessage id={`circ_${status.toLowerCase()}`} />;

  return (
    <BorrowingStatusIcon
      className="cp-hold-status-icon"
      icon={getIcon(status)}
      status={status}
      withBackground={withBackground}
      renderLabel={withLabel && (() => label)}
    />
  );
}

HoldStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  withBackground: PropTypes.bool,
  withLabel: PropTypes.bool
};
