import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import CommentsActions from 'app/actions/CommentsActions';

const CommentPageContainer = loadable(() => import('app/components/bibs/subpages/CommentPage/CommentPageContainer'));

export function CommentPageRoute() {
  return <CommentPageContainer />;
}

function getRouteData({ dispatch, params }) {
  const commentId = parseInt(params.commentId, 10);
  return [dispatch(CommentsActions.getComment(commentId))];
}

export default connectRoute(CommentPageRoute, { getRouteData });
