import PropTypes from 'prop-types';
import React from 'react';
import { bindAll } from '@bibliocommons/utils-react';
import Swipeable from '@bibliocommons/base-gestures-swipeable';
import { childrenShape } from '@bibliocommons/bc-prop-types';

class Toggle extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  toggleDrawer() {
    this.props.drawerActions.toggle();
  }

  render() {
    return (
      <Swipeable onSwipedRight={this.toggleDrawer} className="cp-drawer-toggle hidden-lg">
        <span>{this.props.children}</span>
      </Swipeable>
    );
  }
}

Toggle.propTypes = {
  drawerActions: PropTypes.shape({
    toggle: PropTypes.func.isRequired
  }),
  children: childrenShape
};

export default Toggle;
