import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from 'app/components/layouts/MainLayout';
import BorrowingLayout from 'app/components/borrowing/shared/BorrowingLayout';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import AccountMessagesPageRoute from './AccountMessagesPageRoute';

const basePath = '/v2/accountmessages';

export default (
  <Route path={basePath}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout deprecated>
          <BorrowingLayout>
            <AccountMessagesPageRoute />
          </BorrowingLayout>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);
