import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CheckedOutStatusIcon from '../CheckedOutStatusIcon';

import StatusSidebarLink from '../../sidebar/StatusSidebarLink';
import NextDueDate from '../NextDueDate';

import './CheckedOutSidebarLink.scss';

const renderStatus = status => <FormattedMessage id={`checkedout_status_${status.toLowerCase()}`} />;

const renderNextDue = nextDue => (
  <FormattedMessage
    tagName="div"
    id="label_next_due_on_date"
    values={{ date: <NextDueDate date={nextDue.get('date')} /> }}
  />
);

export default function CheckedOutSidebarLink({ status, selected, summary, nextDue }) {
  return (
    <StatusSidebarLink
      className="cp-checked-out-sidebar-link"
      selected={selected}
      status={status}
      icon={<CheckedOutStatusIcon status={status} />}
      summary={summary}
      statusText={renderStatus(status)}
      to={`/v2/checkedout/${status.toLowerCase()}`}
    >
      {(nextDue && nextDue.get('status') === status && renderNextDue(nextDue)) || null}
    </StatusSidebarLink>
  );
}

CheckedOutSidebarLink.propTypes = {
  status: PropTypes.string,
  selected: PropTypes.bool,
  summary: ImmutablePropTypes.map,
  nextDue: ImmutablePropTypes.map
};
