import React from 'react';
import { useSelector } from 'react-redux';

import { bibShape } from '@bibliocommons/bc-prop-types';

import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import ItemFormat from './ItemFormat';

const ItemFormatContainer = ({ bib }) => {
  const library = useSelector(selectCurrentLibrary);
  return <ItemFormat bib={bib} library={library} />;
};

ItemFormatContainer.propTypes = {
  bib: bibShape.isRequired
};

export default ItemFormatContainer;
