import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InterlibraryLoansStatusIcon from '../InterlibraryLoansStatusIcon';

import SidebarLink from '../../sidebar/SidebarLink';

const renderCount = count => <span className="status-count">{count}</span>;

export default function InterlibraryLoansSidebarLink({ status, selected, summary }) {
  const formattedStatus = status.toLowerCase();
  const count = summary.get('status').get(status);
  return (
    <SidebarLink
      className={cn('cp-status-sidebar-link', 'cp-interlibraryloans-sidebar-link', formattedStatus)}
      selected={selected}
      to={`/v2/interlibraryloans/${status.toLowerCase()}`}
    >
      <InterlibraryLoansStatusIcon status={status} />
      <div className="contents">
        <div className="status-wrapper">
          <FormattedMessage id={`ill_status_${status.toLowerCase()}`} />
        </div>
        {count > 0 && renderCount(count)}
      </div>
    </SidebarLink>
  );
}

InterlibraryLoansSidebarLink.propTypes = {
  status: PropTypes.string,
  selected: PropTypes.bool,
  summary: ImmutablePropTypes.map
};
