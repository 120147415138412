import Immutable from 'immutable';
import { createSelector } from 'reselect';
import {
  selectCommentEntities,
  selectQuotationEntities,
  selectSummaryEntities,
  selectVideoEntities
} from '../EntitiesSelector';

const pagination = ugcType => state => state.getIn(['bibs', 'ugc', ugcType, 'pagination'], Immutable.Map());

// Summary
export const selectUgcSummary = createSelector(
  [state => state.getIn(['bibs', 'ugc', 'summary'], Immutable.Map())],
  summary => summary
);

const getUgcIdsForCurrentBib = ugcType => state => state.getIn(['bibs', 'ugc', ugcType, 'results'], Immutable.List());

// Comments
export const selectCommentsForCurrentBib = category =>
  createSelector([getUgcIdsForCurrentBib(category), selectCommentEntities], (commentIds, commentEntities) =>
    commentIds.map(commentId => commentEntities.get(commentId.toString()))
  );

export const selectCommunityCommentsForCurrentBib = selectCommentsForCurrentBib('communityComments');
export const selectStaffCommentsForCurrentBib = selectCommentsForCurrentBib('staffComments');

// Tag Summaries
const getTagSummaries = state => state.getIn(['bibs', 'ugc', 'tags', 'summaries'], Immutable.List());
export const selectTagSummariesForCurrentBib = createSelector([getTagSummaries], tags => tags);

export const selectTagsPaginationForCurrentBib = createSelector([pagination('tags')], page => page);

// Quotations
export const selectQuotationsForCurrentBib = createSelector(
  [getUgcIdsForCurrentBib('quotations'), selectQuotationEntities],
  (quotationIds, quotationEntities) => quotationIds.map(quotationId => quotationEntities.get(quotationId.toString()))
);

// Summaries
export const selectSummariesForCurrentBib = createSelector(
  [getUgcIdsForCurrentBib('summaries'), selectSummaryEntities],
  (summaryIds, summaryEntities) => summaryIds.map(summariesId => summaryEntities.get(summariesId.toString()))
);

// Videos
export const selectVideosForCurrentBib = createSelector(
  [getUgcIdsForCurrentBib('videos'), selectVideoEntities],
  (videoIds, videoEntities) => videoIds.map(videoId => videoEntities.get(videoId.toString()))
);
