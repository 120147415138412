import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import BorrowingStatus from '../../shared/BorrowingStatus';
import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';
import DollarAmount from '../../shared/DollarAmount';
import FinesStatusIcon from '../FinesStatusIcon';

import './FinesStatus.scss';

export default class FinesStatus extends React.PureComponent {
  renderFineAmount() {
    const { totalFines } = this.props;
    return (
      <span className="fine-amount">
        <DollarAmount amount={totalFines} />
      </span>
    );
  }

  renderAccruingFines() {
    const { accruingFines } = this.props;
    if (accruingFines > 0) {
      return (
        <div className="fines-status">
          <div className="fines-status-accruing">
            <FinesStatusIcon />
            <span>
              <DollarAmount amount={accruingFines} />
            </span>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { currentSection } = this.props;
    return (
      <BorrowingStatus currentSection={currentSection} to="/v2/fines">
        <SidebarCompactTitle
          title={<FormattedMessage id="section_title_fines" />}
          count={this.renderFineAmount()}
          screenReaderCount={this.renderFineAmount()}
          selected={currentSection}
        />
        {this.renderAccruingFines()}
      </BorrowingStatus>
    );
  }
}

FinesStatus.propTypes = {
  currentSection: PropTypes.bool,
  totalFines: PropTypes.number,
  accruingFines: PropTypes.number
};
