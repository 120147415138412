import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import loadable from '@loadable/component';

import BackToTop from 'app/components/shared/BackToTop';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import Context from '@bibliocommons/context';

import { DataLayerContainer } from 'app/components/analytics/DataLayer';
import { CoreHeaderContainer } from 'app/components/layouts/CoreHeader';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import { ErrorBoundaryContainer } from 'app/components/errors/ErrorBoundary';
import { FooterContainer } from 'app/components/layouts/Footer';
import GlobalEventListeners from 'app/components/layouts/GlobalEventListeners';
import { GlobalProgressBarContainer } from 'app/components/layouts/GlobalProgressBar';
import { GlobalMessagingContainer } from 'app/components/GlobalMessaging';
import StaffTakeoverMessageContainer from 'app/components/shared/StaffTakeoverMessage/StaffTakeoverMessageContainer';
import ScreenReaderShortcutsContainer from 'app/components/shared/ScreenReaderShortcuts/ScreenReaderShortcutsContainer';
import { SystemMessagesContainer } from 'app/components/shared/SystemMessages';
import { LoginModalContainer } from 'app/components/login/LoginModal';
import PrivacySettingsModalContainer from 'app/components/bibs/shared/ugc/PrivacySettingsModal';
import IEDiscontinuedMessage from 'app/components/shared/IEDiscontinuedMessage';

const EnhancedStyles = loadable(() => import('./EnhancedStyles'));
const DeprecatedStyles = loadable(() => import('./DeprecatedStyles'));

export class MainLayout extends React.PureComponent {
  render() {
    const { deprecated } = this.props;
    const GlobalStyles = deprecated ? DeprecatedStyles : EnhancedStyles;

    return (
      <GlobalStyles>
        <GlobalEventListeners />
        <IEDiscontinuedMessage />
        <DataLayerContainer />
        <DocumentHeadContainer />
        {!this.context.mobileApp && <ScreenReaderShortcutsContainer />}
        <GlobalProgressBarContainer />
        <StaffTakeoverMessageContainer />
        <CoreHeaderContainer />
        <div id="preview-bar" />
        <SystemMessagesContainer />
        <main id="content" className={cn({ 'content--mobile-app': this.context.mobileApp })} tabIndex="-1">
          <ErrorBoundaryContainer>{this.props.children}</ErrorBoundaryContainer>
        </main>
        <GlobalMessagingContainer />
        <LoginModalContainer />
        <FooterContainer />
        {!this.context.mobileApp && <BackToTop />}
        <PrivacySettingsModalContainer />
      </GlobalStyles>
    );
  }
}

MainLayout.propTypes = {
  children: childrenShape.isRequired,
  deprecated: PropTypes.bool
};

MainLayout.contextType = Context;

export default MainLayout;
