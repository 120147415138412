import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from 'app/components/layouts/MainLayout';
import BorrowingLayout from 'app/components/borrowing/shared/BorrowingLayout';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import HoldsPageRoute from './HoldsPageRoute';

const basePath = '/v2/holds';

export default (
  <Route path={`${basePath}/:status?`}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout deprecated>
          <BorrowingLayout>
            <HoldsPageRoute />
          </BorrowingLayout>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);
