import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { accountShape, childrenShape } from '@bibliocommons/bc-prop-types';
import connectRoute from 'app/routes/connectRoute';
import RedirectActions from 'app/actions/RedirectActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

export function AccountRequiredRoute(props) {
  if (!props.currentAccount) {
    props.redirectActions.redirect('/user_dashboard');
    return null;
  }

  return props.children;
}

AccountRequiredRoute.propTypes = {
  currentAccount: accountShape,
  children: childrenShape.isRequired,
  redirectActions: PropTypes.objectOf(PropTypes.func).isRequired
};

const mapStateToProps = state => ({
  currentAccount: selectCurrentAccount(state)
});

const mapDispatchToProps = dispatch => ({
  redirectActions: bindActionCreators(RedirectActions, dispatch)
});

export default connectRoute(AccountRequiredRoute, { mapStateToProps, mapDispatchToProps });
