import { map } from 'rxjs/operators';

import { REDUX_CONSTANTS } from 'app/constants/list';
import { mapFetchBibItemAvailabilitiesResponse, mapFetchItemsResponse, mapFetchListResponse } from 'app/helpers/list';

const getBibItemAvailabilites = ({ localBranch, metadataIds = [], preferredLocations, subdomain }) => {
  if ((metadataIds?.length ?? 0) === 0) {
    throw new Error('getBibItemAvailabilites requires an array of metadataIds of non-0 length');
  }

  return {
    apiRequest: (_state, apiClient) => {
      const route = apiClient.route`/lists/bib-availabilities`;

      return apiClient.get(route, { query: { localBranch, metadataIds, preferredLocations, subdomain } }).pipe(
        map(res => {
          return {
            body: mapFetchBibItemAvailabilitiesResponse(res.body)
          };
        })
      );
    },
    types: [
      REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_REQUEST,
      REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_SUCCESS,
      REDUX_CONSTANTS.GET_BIB_ITEM_AVAILABILITIES_FAILURE
    ]
  };
};

const getListById = ({ itemsPerPageLimit, listId, subdomain }) => {
  return {
    apiRequest: (_state, apiClient) => {
      const route = apiClient.route`/lists/${listId}`;

      return apiClient.get(route, { query: { limit: itemsPerPageLimit, subdomain } }).pipe(
        map(res => {
          return {
            body: mapFetchListResponse(res.body)
          };
        })
      );
    },
    types: [
      REDUX_CONSTANTS.GET_LIST_BY_ID_REQUEST,
      REDUX_CONSTANTS.GET_LIST_BY_ID_SUCCESS,
      REDUX_CONSTANTS.GET_LIST_BY_ID_FAILURE
    ]
  };
};

const getListItems = ({ itemsPerPageLimit, listId, page, subdomain }) => {
  return {
    apiRequest: (_state, apiClient) => {
      const route = apiClient.route`/lists/${listId}/items`;

      return apiClient.get(route, { query: { limit: itemsPerPageLimit, page, subdomain } }).pipe(
        map(res => {
          return {
            body: mapFetchItemsResponse(res.body)
          };
        })
      );
    },
    types: [
      REDUX_CONSTANTS.GET_LIST_ITEMS_REQUEST,
      REDUX_CONSTANTS.GET_LIST_ITEMS_SUCCESS,
      REDUX_CONSTANTS.GET_LIST_ITEMS_FAILURE
    ]
  };
};

export { getBibItemAvailabilites, getListItems, getListById };
