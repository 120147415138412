import Immutable from 'immutable';
import { createSelector } from 'reselect';

const usersSelector = state => state.getIn(['entities', 'users'], Immutable.Map());
const getRequestedUserFromState = state => state.get('user');

export const selectRequestedUser = createSelector([getRequestedUserFromState, usersSelector], (requestedUser, users) =>
  requestedUser && requestedUser.get('id') ? users.find(user => user.get('id') === requestedUser.get('id')) : undefined
);

export const selectIsDeletingUser = createSelector([getRequestedUserFromState], requestedUser => {
  return Boolean(requestedUser) && requestedUser.get('isDeletingUser');
});

export default {
  selectIsDeletingUser,
  selectRequestedUser
};
