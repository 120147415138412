import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import InterlibraryLoansActions from 'app/actions/InterlibraryLoansActions';
import BorrowingActions from 'app/actions/BorrowingActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const InterlibraryLoansContainer = loadable(() =>
  import('app/components/borrowing/interlibraryLoans/InterlibraryLoans/InterlibraryLoansContainer')
);

export function InterlibraryLoansPageRoute() {
  return <InterlibraryLoansContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [
    dispatch(BorrowingActions.fetchSummaries({ accountId })),
    dispatch(
      InterlibraryLoansActions.fetchInterlibraryLoans({
        accountId,
        ...params,
        ...query
      })
    )
  ];
}

export default connectRoute(InterlibraryLoansPageRoute, { getRouteData });
