function fetchCatalogResults(data, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/search`;
  return apiClient.post(route, data);
}

function fetchShelfAvailabilityResults(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/search/shelves/${query.shelf}/availability`;
  return apiClient.get(route, { query });
}

function fetchAdditionalFilters(query, fieldId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/search/fields/${fieldId}/filters`;
  return apiClient.get(route, { query });
}

function fetchSearchProbes(query, searchType, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/search/probes`;
  return apiClient.get(route, { query: { query, searchType } });
}

function fetchGroupSearchSummaries(metadataIds, libraryId, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/bibs/search/groupSearchSummaries`;
  return apiClient.get(route, { query: { metadataIds, libraryId } });
}

export default {
  fetchAdditionalFilters,
  fetchCatalogResults,
  fetchSearchProbes,
  fetchGroupSearchSummaries,
  fetchShelfAvailabilityResults
};
