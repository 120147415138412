import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { siteShape, accountShape } from '@bibliocommons/bc-prop-types';
import Context from '@bibliocommons/context';

import Panel from 'app/components/shared/Panel';
import LinkableLibrary from '../LinkableLibrary';

import './LinkableLibrariesPanel.scss';

export default class LinkableLibrariesPanel extends React.PureComponent {
  hasLinkedAccount(library) {
    return this.props.accountsByLibraryId.has(library.get('libraryId'));
  }

  renderLinkableLibraries() {
    return this.props.linkableLibraries.map(library => (
      <LinkableLibrary
        key={`linkable-library-${library.get('libraryId')}`}
        withoutLinks={this.context.mobileApp}
        library={library}
        linked={this.hasLinkedAccount(library)}
      />
    ));
  }

  render() {
    return (
      !this.context.mobileApp && (
        <Panel className="cp-linkable-libraries-panel">
          <h3>
            <FormattedMessage id="connected_accounts" />
          </h3>

          <ul className="libraries">{this.renderLinkableLibraries()}</ul>
        </Panel>
      )
    );
  }
}

LinkableLibrariesPanel.propTypes = {
  accountsByLibraryId: ImmutablePropTypes.mapOf(accountShape),
  linkableLibraries: ImmutablePropTypes.listOf(siteShape)
};

LinkableLibrariesPanel.contextType = Context;
