import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';
import BorrowingStatus from '../../shared/BorrowingStatus';

export default class RecentlyReturnedStatus extends React.PureComponent {
  render() {
    return (
      <BorrowingStatus to="/v2/recentlyreturned" currentSection={this.props.currentSection}>
        <SidebarCompactTitle
          title={<FormattedMessage id="section_title_recently_returned" />}
          count={this.props.totalBorrowingHistory === -1 ? '' : this.props.totalBorrowingHistory}
          selected={this.props.currentSection}
        />
      </BorrowingStatus>
    );
  }
}

RecentlyReturnedStatus.propTypes = {
  currentSection: PropTypes.bool,
  totalBorrowingHistory: PropTypes.number.isRequired
};
