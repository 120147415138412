import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { withRouter } from '@bibliocommons/utils-routing';
import { siteShape, routerShape } from '@bibliocommons/bc-prop-types';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { Dropdown } from '@bibliocommons/deprecated-base-dropdown';
import { TextButton } from '@bibliocommons/deprecated-base-buttons';
import LibraryLogo from 'app/components/shared/LibraryLogo';
import Context from '@bibliocommons/context';

import './AccountSwitcher.scss';

export class AccountSwitcher extends React.PureComponent {
  getLinkForLibrary(library) {
    const { router } = this.props;
    return `${library.get('baseUrl')}${router.location.pathname}${router.location.search}`;
  }

  renderLibraryLogo(library) {
    return (
      <LibraryLogo
        key={`logo-${library.get('siteId')}`}
        siteId={library.get('siteId')}
        imageName="account_linking_image"
        small
        circled
        baseUrl={this.context.coreCdnHost || ''}
      />
    );
  }

  renderLibraryLogos() {
    return this.props.linkedLibrariesById.map(library => this.renderLibraryLogo(library)).toArray();
  }

  renderLibraryLink(library) {
    return (
      <a className="library-link" href={this.getLinkForLibrary(library)}>
        {this.renderLibraryLogo(library)}
        {library.get('longName')}
      </a>
    );
  }

  renderLibraryLinks() {
    return this.props.linkedLibrariesById
      .map(library => <li key={`library-link-${library.get('siteId')}`}>{this.renderLibraryLink(library)}</li>)
      .toArray();
  }

  renderTriggerButtonContents() {
    return (
      <span>
        <span className="trigger-text" aria-hidden="true">
          <FormattedMessage id="switch_account" />
        </span>

        <ScreenReaderMessage>
          <FormattedMessage id="switch_account" />
        </ScreenReaderMessage>

        <div className="library-logos">{this.renderLibraryLogos()}</div>
      </span>
    );
  }

  render() {
    if (!this.props.linkedLibrariesById.isEmpty() && !this.context.mobileApp) {
      return (
        <div className="cp-account-switcher">
          <Dropdown
            dataKey="account-switcher-dropdown"
            className=""
            disableMobile
            ButtonTag={TextButton}
            selected={this.renderTriggerButtonContents()}
          >
            {this.renderLibraryLinks()}
          </Dropdown>
        </div>
      );
    }

    return null;
  }
}

AccountSwitcher.propTypes = {
  linkedLibrariesById: ImmutablePropTypes.mapOf(siteShape),
  router: routerShape.isRequired
};

AccountSwitcher.contextType = Context;

export default withRouter(AccountSwitcher);
