import loadable from '@loadable/component';
import React from 'react';

import connectRoute from 'app/routes/connectRoute';

const UnregisterPage = loadable(() => import('app/components/info/apps/UnregisterPage'));

const UnregisterPageRoute = () => <UnregisterPage />;

export default connectRoute(UnregisterPageRoute);
