import React from 'react';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import HoldActions from 'app/actions/HoldActions';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';

const HoldsPrintListContainer = loadable(() =>
  import('app/components/borrowing/holds/HoldsPrintList/HoldsPrintListContainer')
);

export function HoldsPrintListRoute() {
  return <HoldsPrintListContainer />;
}

function getRouteData({ dispatch, state, params, query }) {
  const account = selectCurrentAccount(state);
  const accountId = account && account.get('id');
  if (!accountId) return [];

  return [dispatch(HoldActions.fetchHolds({ accountId, ...params, ...query }))];
}

export default connectRoute(HoldsPrintListRoute, { getRouteData });
