import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { withRouter } from '@bibliocommons/utils-routing';
import { routerShape } from '@bibliocommons/bc-prop-types';

import { INTER_LIBRARY_STATUS_TYPES } from 'app/constants/InterlibraryLoansConstants';

import BorrowingStatus from '../../shared/BorrowingStatus';
import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';
import InterlibraryLoansSidebarLink from '../InterlibraryLoansSidebarLink';
import TitleWithCount from '../../shared/TitleWithCount';
import AllSidebarLink from '../../sidebar/AllSidebarLink';

import './InterlibraryLoansStatus.scss';

const STATUS_ORDER = [
  INTER_LIBRARY_STATUS_TYPES.READY,
  INTER_LIBRARY_STATUS_TYPES.IN_TRANSIT,
  INTER_LIBRARY_STATUS_TYPES.SHIPPED,
  INTER_LIBRARY_STATUS_TYPES.NOT_READY,
  INTER_LIBRARY_STATUS_TYPES.INACTIVE
];

export class InterlibraryLoansStatus extends React.PureComponent {
  statusIsActive(status) {
    return this.props.router.location.pathname.includes(`v2/interlibraryloans/${status.toLowerCase()}`);
  }

  noStatusIsSelected() {
    return this.props.router.location.pathname === '/v2/interlibraryloans';
  }

  renderGrouping(status) {
    return (
      <InterlibraryLoansSidebarLink
        key={status}
        status={status}
        selected={this.statusIsActive(status)}
        summary={this.props.summary}
      />
    );
  }

  renderAllItemsButton(statusSummaries) {
    if (statusSummaries.size > 1) {
      return <AllSidebarLink to="/v2/interlibraryloans" selected={this.noStatusIsSelected()} />;
    }

    return null;
  }

  renderFullStatus() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());

    return (
      <span>
        <Link className="section-title" to="/v2/interlibraryloans">
          <TitleWithCount title={<FormattedMessage id="sidebar_title_ill" />} count={this.props.summary.get('total')} />
        </Link>
        {this.renderAllItemsButton(statusSummaries)}
        {statusSummaries
          .map((summary, status) => status)
          .toList()
          .sortBy(status => -1 * STATUS_ORDER.indexOf(status))
          .map(status => this.renderGrouping(status))
          .toArray()}
      </span>
    );
  }

  renderCompactStatus() {
    return (
      <SidebarCompactTitle
        title={<FormattedMessage id="sidebar_title_ill" />}
        count={this.props.summary.get('total')}
      />
    );
  }

  render() {
    return (
      <BorrowingStatus
        className="cp-interlibrary-loans-sidebar-status"
        currentSection={this.props.currentSection}
        to="/v2/interlibraryloans"
      >
        {this.props.currentSection ? this.renderFullStatus() : this.renderCompactStatus()}
      </BorrowingStatus>
    );
  }
}

InterlibraryLoansStatus.propTypes = {
  router: routerShape.isRequired,
  summary: ImmutablePropTypes.mapContains({
    total: PropTypes.number,
    status: ImmutablePropTypes.map
  }),
  currentSection: PropTypes.bool
};

export default withRouter(InterlibraryLoansStatus);
