import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import { withRouter } from '@bibliocommons/utils-routing';
import { borrowingQuotaShape, routerShape } from '@bibliocommons/bc-prop-types';
import { getTotalCountFromSummary, getTotalFromMaterialTypeCounts } from '@bibliocommons/utils-borrowing';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { HOLD_STATUS_TYPES } from 'app/constants/HoldsConstants';

import HoldStatusIcon from '../HoldStatusIcon';
import BorrowingStatus from '../../shared/BorrowingStatus';
import SidebarCompactTitle from '../../sidebar/SidebarCompactTitle';
import HoldsSidebarLink from '../HoldsSidebarLink';
import AllSidebarLink from '../../sidebar/AllSidebarLink';
import TitleWithCount from '../../shared/TitleWithCount';

import './HoldsStatus.scss';

const STATUS_ORDER = [
  HOLD_STATUS_TYPES.SUSPENDED,
  HOLD_STATUS_TYPES.NOT_YET_AVAILABLE,
  HOLD_STATUS_TYPES.IN_TRANSIT,
  HOLD_STATUS_TYPES.READY_FOR_PICKUP
];

export class HoldsStatus extends React.PureComponent {
  getTotalCount() {
    return getTotalCountFromSummary(this.props.summary);
  }

  statusIsActive(status) {
    return this.props.router.location.pathname.includes(`v2/holds/${status.toLowerCase()}`);
  }

  noStatusIsSelected() {
    return this.props.router.location.pathname === '/v2/holds';
  }

  renderAllItemsButton() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());
    if (statusSummaries.size > 1) {
      return <AllSidebarLink to="/v2/holds" selected={this.noStatusIsSelected()} />;
    }

    return null;
  }

  renderGrouping(status) {
    return (
      <HoldsSidebarLink
        key={status}
        status={status}
        selected={this.statusIsActive(status)}
        summary={this.props.summary}
      />
    );
  }

  renderRemainingHolds() {
    if (this.props.summary.get('quotas')) {
      if (this.props.ILSQuotas) {
        return (
          <div className="free-holds-remaining">
            <span className="free-holds-text">
              <FormattedMessage id="free_holds_remaining" />
            </span>
            <span className="pull-right free-holds-count">
              {this.props.ILSQuotas.get('remaining')} / {this.props.ILSQuotas.get('total')}
            </span>
          </div>
        );
      }
    }
    return null;
  }

  renderReadyForPickupSummary() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());
    if (statusSummaries.get(HOLD_STATUS_TYPES.READY_FOR_PICKUP)) {
      const count = getTotalFromMaterialTypeCounts(statusSummaries.get(HOLD_STATUS_TYPES.READY_FOR_PICKUP));
      return (
        <span className="ready_for_pickup status-label">
          <div className="hold-status-icon status-icon">
            <HoldStatusIcon status="READY_FOR_PICKUP" />
          </div>
          <span aria-hidden="true">{count}</span>
          <ScreenReaderMessage>
            <FormattedMessage id="borrowing_item_count_ready_for_pickup" values={{ count }} />.
          </ScreenReaderMessage>
        </span>
      );
    }

    return null;
  }

  renderInTransitSummary() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());
    if (statusSummaries.get(HOLD_STATUS_TYPES.IN_TRANSIT)) {
      const count = getTotalFromMaterialTypeCounts(statusSummaries.get(HOLD_STATUS_TYPES.IN_TRANSIT));
      return (
        <span className="in_transit status-label">
          <div className="hold-status-icon status-icon">
            <HoldStatusIcon status={HOLD_STATUS_TYPES.IN_TRANSIT} />
          </div>
          <span aria-hidden="true">{count}</span>
          <ScreenReaderMessage>
            <FormattedMessage id="borrowing_item_count_in_transit" values={{ count }} />.
          </ScreenReaderMessage>
        </span>
      );
    }

    return null;
  }

  renderCompactStatus() {
    const readyForPickupSummary = this.renderReadyForPickupSummary();
    const inTransitSummary = this.renderInTransitSummary();

    return (
      <span>
        <SidebarCompactTitle title={<FormattedMessage id="section_title_holds" />} count={this.getTotalCount()} />

        {(readyForPickupSummary || inTransitSummary) && (
          <div className="small hold-status">
            {readyForPickupSummary}
            {inTransitSummary}
          </div>
        )}
      </span>
    );
  }

  renderFullStatus() {
    const statusSummaries = this.props.summary.get('status', Immutable.Map());

    return (
      <span>
        <Link className="section-title" to="/v2/holds">
          <TitleWithCount title={<FormattedMessage id="section_title_holds" />} count={this.getTotalCount()} />
        </Link>
        {this.renderAllItemsButton()}
        {statusSummaries
          .map((summary, status) => status)
          .toList()
          .filter(status => ![HOLD_STATUS_TYPES.EXPIRED, HOLD_STATUS_TYPES.CANCELLED].includes(status))
          .sortBy(status => -1 * STATUS_ORDER.indexOf(status))
          .map(status => this.renderGrouping(status))
          .toArray()}
        {this.props.isRemainingHoldsEnabled && this.renderRemainingHolds()}
      </span>
    );
  }

  render() {
    return (
      <BorrowingStatus to="/v2/holds" className="cp-holds-status" currentSection={this.props.currentSection}>
        {this.props.currentSection ? this.renderFullStatus() : this.renderCompactStatus()}
      </BorrowingStatus>
    );
  }
}

const withRouterPropTypes = {
  router: routerShape
};

HoldsStatus.propTypes = {
  summary: ImmutablePropTypes.mapContains({
    status: ImmutablePropTypes.map,
    quotas: ImmutablePropTypes.listOf(borrowingQuotaShape)
  }),
  currentSection: PropTypes.bool,
  ILSQuotas: ImmutablePropTypes.map,
  isRemainingHoldsEnabled: PropTypes.bool,
  ...withRouterPropTypes
};

export default withRouter(HoldsStatus);
