import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { selectCurrentUser } from './AuthSelector';
import { selectAppConfig } from './AppSelector';

function getProductData(appConfig) {
  return {
    'bc.product': 'core',
    'bc.version': appConfig.get('appVersion')
  };
}

function getUserData(user) {
  if (user) {
    return {
      'bc.encodedUserId': user.get('encodedId')
    };
  }

  return {};
}

function getSiteData(state) {
  return state.getIn(['analytics', 'siteDataLayer'], Immutable.Map());
}

function getContextData(state) {
  return {
    context: state.getIn(['analytics', 'context'], Immutable.Map())
  };
}

function getExternalGAId(siteData, appConfig) {
  const gaCrossDomainEnabled = siteData.get('bc.gaCrossDomainEnabled');
  const externalEventsGAId = siteData.get('bc.externalEventsGAId');
  const externalCoreGAId = siteData.get('bc.externalCoreGAId');
  const currentURL = appConfig.get('currentURL');
  const isEvents = currentURL?.includes('/v2/events') || currentURL?.includes('/v2/locations');

  return { 'bc.externalGaId': isEvents && !gaCrossDomainEnabled ? externalEventsGAId : externalCoreGAId };
}

export const selectSiteDataLayer = createSelector([getSiteData], siteData => siteData);

export const selectDataLayer = createSelector(
  [selectSiteDataLayer, getContextData, selectCurrentUser, selectAppConfig],
  (siteData, context, user, appConfig) => {
    const userData = getUserData(user);
    const productData = getProductData(appConfig);
    const externalGAId = getExternalGAId(siteData, appConfig);
    return siteData.merge(context, userData, productData, externalGAId);
  }
);

export default {
  selectSiteDataLayer,
  selectDataLayer
};
